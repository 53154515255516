import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { SharedUtilsService } from '@app/services/shared-utils.service';

@Component({
  selector: 'record-amount-selector-dialog',
  templateUrl: './record-amount-selector-dialog.component.html',
  styleUrls: ['./record-amount-selector-dialog.component.scss']
})
export class RecordAmountSelectorDialogComponent implements OnInit {
  maxNumberValue: number = 100;
  amountToSelect: number = 1;

  constructor(
    public dialogRef: MatDialogRef<RecordAmountSelectorDialogComponent>,
    private _sharedUtilsService: SharedUtilsService
  ) { }

  ngOnInit(): void {
    // console.log('Max Number Value: ', this.maxNumberValue);
  }


  checkForValidAmount() {
    if (this.maxNumberValue && this.amountToSelect > this.maxNumberValue) this.amountToSelect = this.maxNumberValue;
  }


  async setInput() {
    if (this.amountToSelect && this.amountToSelect > 0) return this.dialogRef.close(this.amountToSelect);
    else this._sharedUtilsService.showErrorDialog('Please set the number of records you want to select!');
  }


  cancel() {
    return this.dialogRef.close(null);
  }
}