import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';

@Injectable({ providedIn: 'root' })
export class TabControlService {
  private tabToggledObs$ = new Subject<any>();

  private currentModuleAndRecord = {
    module: '',
    recordId: '',
    currentIndex: 0,
    detailViewTabCount: 0,
    editViewTabCount: 0
  };

  listenForTabChange() {
    return this.tabToggledObs$;
  }

  announceTabChange(_index: number) {
    this.currentModuleAndRecord.currentIndex = _index;
    this.tabToggledObs$.next(_index);
  }


  setTabIndex(_index: number): void {
    this.currentModuleAndRecord.currentIndex = _index;
  }

  getTabIndex(): number {
    return this.currentModuleAndRecord.currentIndex;
  }


  setModuleAndRecordIndex(_recordAndIndex) {
    return this.currentModuleAndRecord = _recordAndIndex;
  }
  
  getModuleAndRecordIndex() {
    return this.currentModuleAndRecord;
  }
}
