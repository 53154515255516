<div class="record-amount-selector-dialog">
    <h1 mat-dialog-title class="text-center">Set Number Of Records To Select</h1>

    <div class="amount-selector-dialog-body">
        <form class="full-width">
            <div class="row amount-selector-row">
                <div class="amount-selector">
                    <mat-form-field appearance="outline">
                        <mat-label>Amount To Select</mat-label>
                        <input matInput type="number" placeholder="Amount To Select"
                            [(ngModel)]="amountToSelect" [ngModelOptions]="{standalone: true}"
                            (keyup)="checkForValidAmount()"
                            [min]="1" [max]="maxNumberValue || null">
                    </mat-form-field>
                </div>
            </div>
        </form>

        <div class="amount-selector-dialog-buttons">
            <button mat-button mat-dialog-close (click)="cancel()">Cancel</button>
            <button mat-raised-button color="primary" (click)="setInput()" [disabled]="!amountToSelect || amountToSelect < 1">Apply</button>
        </div>
    </div>
</div>