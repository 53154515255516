import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { UserIdleModule } from 'angular-user-idle';

import { PublicModule } from './modules/public/public.module';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { RouteGuardModule } from './modules/route-guard/route-guard.module';

import { environment } from '@environments/environment';

import { CookieService } from 'ngx-cookie-service';
import { JwtInterceptor, ErrorInterceptor } from './_helpers';
import { DialogsModule } from './modules/dialogs/dialogs.module';
import { CompareValidatorDirective } from './directives/compare-validator.directive';

import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { TimeagoModule } from 'ngx-timeago';

// const config: SocketIoConfig = { url: 'http://localhost:4444', options: {} };
const config: SocketIoConfig = { url: '/', options: {} };

@NgModule({
  declarations: [
    AppComponent,
    CompareValidatorDirective
  ],
  imports: [
    BrowserModule,
    RouteGuardModule,
    DialogsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SocketIoModule.forRoot(config),
    PublicModule,
    TimeagoModule.forRoot(),
    NgxMapboxGLModule.withConfig({
      accessToken: environment.mapbox.accessToken, // Optional, can also be set per map (accessToken input of mgl-map)
      // geocoderAccessToken: 'TOKEN' // Optionnal, specify if different from the map access token, can also be set per mgl-geocoder (accessToken input of mgl-geocoder)
    }),    
    UserIdleModule.forRoot({idle: 1800, timeout: 30, ping: 120}) // 15 minutes used in production
    // UserIdleModule.forRoot({idle: 30, timeout: 30, ping: 120}) // 30 seconds used for testing
  ],
  providers: [
    CookieService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
