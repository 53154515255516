<div class="container confirm-action-dialog">
  <h1 mat-dialog-title class="text-center">Duplicate Accounts Detected</h1>

  <p>The following appear to match the account you are creating.</p>

  <div class="row" *ngIf="items && items.length">
    <table class="full-width" mat-table [dataSource]="items">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Name </th>
        <td mat-cell *matCellDef="let _account"> {{ _account.name }} </td>
      </ng-container>

      <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef> Created At </th>
        <td mat-cell *matCellDef="let _account"> {{ _account.createdAt | date: 'MM/dd/yyyy h:mm a' }}  </td>
      </ng-container>
    
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>

<!--   <div class="row">
    <mat-dialog-actions class="two-button-full-width-flex">
      <button mat-stroked-button mat-dialog-close (click)="cancel()">Do Not Create</button>
      <button mat-raised-button color="primary" (click)="confirmCreate(false)">Create</button>
    </mat-dialog-actions>
  </div> -->

  <div class="row">
    <mat-dialog-actions class="two-button-full-width-flex">
      <!-- <button mat-stroked-button mat-dialog-close (click)="cancel()">Do Not Create</button> -->
      <button mat-raised-button color="primary" (click)="confirmCreate(false)">Save</button>
      <button mat-raised-button color="primary" (click)="confirmCreate(true)">Review and Merge</button>
    </mat-dialog-actions>
  </div>
</div>
