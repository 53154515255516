import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'address-verification-dialog',
  templateUrl: './address-verification-dialog.component.html',
  styleUrls: ['./address-verification-dialog.component.scss']
})
export class AddressVerificationDialogComponent implements OnInit {
  currentAddress = null; // Should be passed in.
  addressesSuggested: any[] = null; // Should be passed in.

  selectedAddress = null;

  constructor(
    public dialogRef: MatDialogRef<AddressVerificationDialogComponent>
  ) { }

  ngOnInit(): void {
    // console.log('currentAddress: ', this.currentAddress);
    // console.log('addressesSuggested: ', this.addressesSuggested);
  }


  setActiveAddress(_address) {
    // console.log('_address: ', _address);
    this.selectedAddress = _address;
  }


  confirmSelection() {
    // console.log('selectedAddress: ', this.selectedAddress);
    this.closeDialog();
  }


  closeDialog() {
    return this.dialogRef.close(this.selectedAddress);
  }
}