export class AuditLog {
    _id: string;
    changed_by: any;
    client: any;
    module: string;
    field: string;
    fullField: any;
    fullModule: any;
    action: string;
    changeSource: string;
    record_id: string;
    record_name: string;
    previous_value;
    new_value;
    date_created: Date;  
    newRecordFields;
    oldRecordFields;
    audit_itemDateRange;
    user;
    previous_model_snapshot;
    hasSearchTerms;
    audit_itemDate;
    audit_itemDateEnd;
    activityDateRange;
}
