export class CalendarEvent {
    _id: string;
    user: any;
    client: any;
    calendarGroup: any;
    title: string;
    details: string;
    color: any;
    start: Date;
    end: Date;
    startTime: string;
    endTime: string;
    date_created: Date;
    company_holiday: boolean;
    disabled_campaigns_on_day: boolean;
    public: boolean;
    is_all_day: boolean;
    deleted: boolean;
    startRange;

    related_record: any; // Used for mock events in calendar functionality. 
}