import { Injectable } from '@angular/core';

import { Activity, Message, Task } from '@app/models';
import { CalendarEventService } from './calendar-event.service';

import { AchievementDetectorService, GameDetectorService, GeoCodingService, MessageService, SsModuleService, TaskService } from '.';

import { ActivityService } from './activity.service';
import { AuditLogService } from './audit-log.service';
import { GenericModuleService } from './generic-module.service';
import { LeadService } from './lead.service';
import { SharedUtilsService } from './shared-utils.service';
import { UserService } from './user.service';
import { lastValueFrom } from 'rxjs/internal/lastValueFrom';

@Injectable({ providedIn: 'root' })
export class ModuleHooksService {
  constructor(
    private _activityService: ActivityService,
    private _auditService: AuditLogService,
    private _achievementDetectorService: AchievementDetectorService,
    private _calendarEventService: CalendarEventService,
    private _gameDetectorService: GameDetectorService,
    private _geoService: GeoCodingService,
    private _messageService: MessageService,
    private _moduleService: SsModuleService,
    private _genericModuleService: GenericModuleService,
    private _leadsService: LeadService,
    private _sharedService: SharedUtilsService,
    private _taskService: TaskService,
    private _userService: UserService
  ) { }

  // Happens before the database gets the records, so any changes here are saved to record
  preModuleSaveHook(_module, _uneditedRecord, _updatedRecord): Promise<Object> {
    return new Promise(async(resolve, reject) => {
      // this should probably live elsewhere once the workflow logic is built out

      const _moduleName = _module.name;
      let errorMessage: string = null; // If we want to stop functionality due to issue set an error message with reason and break the case

      // console.log('Module Name: ', _moduleName);
      // console.log('_uneditedRecord: ', _uneditedRecord);
      // console.log('_updatedRecord: ', _updatedRecord);

      switch(_moduleName) {
        case 'calls': {
          if (_uneditedRecord) {
            // if editing a record ***** THIS CAN NOW BE MOVED TO A WORKFLOW
            // console.log('Call at submission saved: ', _updatedRecord);

            if (!_uneditedRecord._id) {
              // console.log('NEW Call HERE 1.');

              if (_updatedRecord['leads_activities']) {
                _updatedRecord['leads_calls'] = (_updatedRecord['leads_activities']._id) ? _updatedRecord['leads_activities']._id : _updatedRecord['leads_activities'];
              }
            } else {
              // console.log('CALL UPDATE!!!');
            }
          }

          // console.log('_updatedRecord: ', _updatedRecord);

          break; 
        } 
        case 'accounts': {
          if (!_uneditedRecord._id) {
            // console.log('This is a create for accounts.')
            // console.log(_updatedRecord);

            // _updatedRecord['automation_last_ran_date'] = null;
          }

          break; 
        }
        case 'tasks': {
          if (!_uneditedRecord || !_uneditedRecord._id) {
            // console.log('_module: ', _module);

            if (!_module.relationships) _module = await this._moduleService.getFullModuleByNameWithRefresh(_module.name);
  
            console.log('taskModule: ', _module);
            console.log('This is a create for tasks.')
            console.log(_updatedRecord);

            const appropriateRel = _module.relationships.find(_rel => _updatedRecord[_rel.name]);
            console.log('Appropriate Relationship: ', appropriateRel);

            // why is relationship not in task from workflow? Why would it be. Try prod to check for field there too...
            if (appropriateRel) {
              _updatedRecord['related_record'] = {
                moduleName: appropriateRel.primary_module.name,
                recordId: (_updatedRecord[appropriateRel.name] && _updatedRecord[appropriateRel.name]._id) ? _updatedRecord[appropriateRel.name]._id : _updatedRecord[appropriateRel.name]
              };
  
              if (_updatedRecord[appropriateRel.name] && !_updatedRecord[appropriateRel.name]._id) {
                // need to get this full record in order to get assigned_to from it.
                const fullRecord = await this.getFullModuleRecord(_updatedRecord[appropriateRel.name], appropriateRel.primary_module.name);
                // console.log('Full Record returned: ', fullRecord);

                _updatedRecord['assigned_user_from_record'] = fullRecord['assigned_to'];
              } else {
                _updatedRecord['assigned_user_from_record'] = _updatedRecord[appropriateRel.name]['assigned_to'];
              }
            }

            // console.log('Updated Record now: ', _updatedRecord);
          }

          break; 
        }
        case 'leads': { 
          // console.log('Leads Case...');

          if (_uneditedRecord['lead_status'] !== _updatedRecord['lead_status']) {
            // lead status has been updated
            _updatedRecord['previous_status'] = _uneditedRecord['lead_status'];
          }

          break;
        }
        default: { 
          break;
        }
      }

      // If error just resolve the error, else complete hook
      if (errorMessage && errorMessage !== undefined) {
        console.log('There was an error with preHook: ', errorMessage)
        resolve({ error: errorMessage});
      } else {
        if (_uneditedRecord && _uneditedRecord._id) {
          // only do audit log checks if there was a previous record, i.e. doing an update
          this._auditService.createChangeEntry(_uneditedRecord, _updatedRecord, _module);
        }

        if (!_updatedRecord.teams || !_updatedRecord.teams.length) {
          const globalTeam = await this._userService.getGlobalTeam();
          // console.log('Adding global team to this record by default: ', globalTeam);

          const globalTeamId = (globalTeam && globalTeam['_id']) ? globalTeam['_id'] : null;
          _updatedRecord.teams = [globalTeamId];
        }

        // console.log('Record after pre hook complete: ', _updatedRecord);
        resolve(_updatedRecord);
      }
    });
  }


  // happens after database saves or updates
  postSaveHook(_module, _moduleRecord, relateDetails, _uneditedRecord = null, _multiRelateDetails = null) {
    return new Promise(async(resolve, reject) => {
      // this should probably live elsewhere once the workflow logic is built out

      const _moduleName = _module.name;
      // console.log('Module Name in postSaveHook: ', _moduleName);

      // console.log('relateDetails: ', relateDetails);
      // console.log('_uneditedRecord: ', _uneditedRecord);
      // console.log('_moduleRecord: ', _moduleRecord);
      // console.log('_multiRelateDetails: ', _multiRelateDetails);

      switch(_moduleName) { 
        case 'calls': {
          const newActivity = new Activity();
          newActivity.activityType = 'Call';
          newActivity.created_by = _moduleRecord.created_by;
          newActivity.assigned_to = _moduleRecord.assigned_to;
          newActivity.modified_by = _moduleRecord.modified_by;
          newActivity.relatedModule = {moduleType: 'calls', moduleRecordId: _moduleRecord._id};
          newActivity.relatedRecord = relateDetails;
          newActivity.deleted = false;

          // if contact was passed up and account, and a contact was chosen, add both
          if (_multiRelateDetails) {
            newActivity.relatedRecords = _multiRelateDetails;

            // relate details is other side
            const otherRecord = _multiRelateDetails.find(_rd => _rd.recordType !== relateDetails.recordType);
            // console.log('Other side is primary record for this: ', otherRecord);
            newActivity.relatedRecord = otherRecord;
          }

          // console.log('Creating activity associated to a call: ', newActivity);

          const savedActivity = await lastValueFrom(this._activityService.create(newActivity));
          this._activityService.announceNewActivity(savedActivity);

          break; 
        }
        case 'notes': {
          const newActivity = new Activity();
          newActivity.activityType = 'Note';
          newActivity.created_by = _moduleRecord.created_by;
          newActivity.assigned_to = _moduleRecord.assigned_to;
          newActivity.modified_by = _moduleRecord.modified_by;
          newActivity.relatedModule = {moduleType: 'notes', moduleRecordId: _moduleRecord._id};
          newActivity.relatedRecord = relateDetails;
          newActivity.deleted = false;

          // if contact was passed up and account, and a contact was chosen, add both
          if (_multiRelateDetails) newActivity.relatedRecords = _multiRelateDetails;

          const savedActivity = await lastValueFrom(this._activityService.create(newActivity));
          this._activityService.announceNewActivity(savedActivity);

          break; 
        }
        case 'tasks': {
          // console.log('Task Saved. Announcing: ', _moduleRecord);
          this._taskService.announceEventServerYeehroTasks(_moduleRecord);
          this._taskService.announceClearTasks();
          break;
        }
        default: { 
          break; 
        }
      }

      // we can trigger achievement checks here...
      await this._achievementDetectorService.triggerAchievementDetector(_module, _uneditedRecord, _moduleRecord);
      // console.log('Achievement detector has finished running...');

      // we can trigger game checks here...
      await this._gameDetectorService.triggerGameDetector(_module, _uneditedRecord, _moduleRecord);
      // console.log('Game detector has finished running...');

      resolve(null);
    });
  }


  getFullModuleRecord(_recordId, _moduleName) {
    return new Promise(async(resolve) => {
      const neededModule = await this._moduleService.waitForModule(_moduleName);

      const moduleOptions = {
        name: _moduleName,
        schema: neededModule.customSchema,
        searchTerms: {
          _id: _recordId,
        }
      };

      const _record = await this._genericModuleService.get(moduleOptions).toPromise();
      resolve(_record);
    });
  }
}