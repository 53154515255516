import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularMaterialModule } from '@app/modules/angular-material/angular-material.module';

import { TemplatePickerDialogComponent } from './template-picker-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule, 
    AngularMaterialModule
  ],
  declarations: [TemplatePickerDialogComponent],
  exports: [TemplatePickerDialogComponent],
})
export class TemplatePickerDialogModule { }
