<div class="leaderboard-viewer-dialog-wrapper">
    <div class="header-wrapper">
        <div class="search-title">
            <h2>Leaderboard</h2>
        </div>

        <div class="cancel-wrapper">
            <button mat-flat-button color="accent" (click)="closeDialog()">Close</button>
        </div>
    </div>

    <div *ngIf="leaderboardItemsPassedIn" class="leaderboard-viewer-dialog-content scrollable-dialog-content">
        <leaderboard
            [leaderboardItemsPassedIn]="leaderboardItemsPassedIn"
            [inDialog]="true"
            [useScoreWithoutPipe]="useScoreWithoutPipe"
            [leaderboardMode]="leaderboardMode">
        </leaderboard>
    </div>
</div>