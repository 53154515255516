import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import * as confetti from 'canvas-confetti';

@Component({
  selector: 'confetti-animation',
  templateUrl: './confetti-animation.component.html',
  styleUrls: ['./confetti-animation.component.scss']
})
export class ConfettiAnimationComponent implements OnInit, OnDestroy {
  @Input() confettiDuration: number;

  confettiContainer; // Canvas element used for setting confetti object on
  defaultDuration = 12 * 1000; // Back up incase confettiDuration was not passed
  animationEnd; // Used in interval to determine time left for interval to run before clearing
  interval; // Interval to be used for animation and is cleared in ngOnDestroy method

  // Options for canvas-confetti, if we want to set colors add: colors: ['#bb0000', '#ffffff']. Options found on https://www.npmjs.com/package/canvas-confetti
  confettiOptions = {
    shapes: ['circle', 'square'],
    startVelocity: 30,
    spread: 360,
    ticks: 60,
    zIndex: 100
  };

  constructor() {}

  ngOnInit(): void {
    if (this.confettiDuration == undefined) this.confettiDuration = this.defaultDuration;
    this.animationEnd = Date.now() + this.confettiDuration;
    this.confettiContainer = document.getElementById('confetti');

    // Setting up confetti object on canvas element. useWorker: true causes freezing when resize happens, so leaving it out.
    this.confettiContainer.confetti = this.confettiContainer.confetti || confetti.create(this.confettiContainer, { resize: true });
    this.initConfetti();
  }


  ngOnDestroy(): void {
    this.stopConfettiAnimation(); // Stop animation
  }


  // So we can stop animation from ngOnDestroy, or from parent somehow.
  stopConfettiAnimation() {
    // console.log('Destroying Confetti')
    clearInterval(this.interval); // Just incase animation is still happening when snackbar is closing
    this.confettiContainer.confetti.reset(); // Stops and resets canvas-confetti
  }


  randomInRange(min, max) {
    return Math.random() * (max - min) + min;
  }


  async initConfetti() {
    const numberOfExplosions = 60; // Used in below equation
    let intervalDuration = this.confettiDuration / numberOfExplosions; // Time until next explosion in milliseconds. {eg... 200}
    // console.log("~ intervalDuration", intervalDuration);

    this.interval = setInterval(() => {
      const timeLeft = this.animationEnd - Date.now();

      if (timeLeft <= 0) return clearInterval(this.interval); // If time has run out, then clear interval

      // const particleCount = 50 * (timeLeft / this.confettiDuration); // Number of particles per explosion, this one is random
      const particleCount = 100; // Number of particles per explosion

      // since particles fall down, start a bit higher than random, Object.assign allows appending to confettiOptions for the dynamic option we want to set
      this.confettiContainer.confetti(Object.assign({}, this.confettiOptions, { particleCount, origin: { x: this.randomInRange(0.1, 0.6), y: Math.random() - 0.2 } }));
      this.confettiContainer.confetti(Object.assign({}, this.confettiOptions, { particleCount, origin: { x: this.randomInRange(0.7, 0.9), y: Math.random() - 0.2 } }));
    }, intervalDuration);
  }
}