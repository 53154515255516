import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AngularMaterialModule } from '@app/modules/angular-material/angular-material.module';

import { FilePickerComponent } from './file-picker.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule, 
    AngularMaterialModule
  ],
  declarations: [FilePickerComponent],
  exports: [FilePickerComponent],
})
export class FilePickerModule { }
