import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/internal/Observable';
import { Subject } from 'rxjs/internal/Subject';

import { Socket } from 'ngx-socket-io';

import { EventServerSocialCommentAnnouncement, SocialComment } from '@app/models';

export interface SocialCommentApi {
    items: any[];
    total_count: number;
}

@Injectable({ providedIn: 'root' })
export class SocialCommentService {
    private currentSocialComment;
    private eventServerSocialCommentAnnouncments$ = new Subject<EventServerSocialCommentAnnouncement>();
    private commentObs$ = new Subject<SocialComment>();
    private clearSocialNotificationsObs$ = new Subject<any>();

    private API_URL = '/api/social-comments/';
    private EVENT_URL = '/events/';

    socialCommentsEvents = this.socket.fromEvent<any>('new-social-comment-event');

    private headerOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    public currentSearchId;
    public isViewingSocialSection: boolean = false;  // Tell profile menu to suppress or show new added comments notification. If in section suppress them.

    constructor(
        private httpClient: HttpClient,
        private socket: Socket
    ) { }

    setCurrent(_comment: SocialComment): void {
        this.currentSocialComment = _comment;
    }


    getCurrent() {
        return this.currentSocialComment;
    }


    // ------- Used to listen for and announce social comments from the event server -----
    announceEventServerSocialComments(_comment: EventServerSocialCommentAnnouncement): void {
        this.eventServerSocialCommentAnnouncments$.next(_comment);
    }

    listenForSocialCommentsFromEventServer() {
        return this.eventServerSocialCommentAnnouncments$;
    }

    getSocialCommentsFromEventServer() {  
        this.socialCommentsEvents.subscribe(_newCommentReceived => {
            // console.log('A new comment has been announced. Is it yours? Message: ', _newCommentReceived);
            this.announceEventServerSocialComments(_newCommentReceived);
        });
    }
    // -----------------------------------------------------------------------------


    // -- Used for to clear social comment notifications ----
    listenForClearSocialNotifications() {
        return this.clearSocialNotificationsObs$;
    }

    announceClearSocialNotifications() {
        // console.log('Change detected in YeehroMessage!');
        this.clearSocialNotificationsObs$.next(null);
    }
    // -----------------------------------------------------------------------------


    // -- Used for to add new comments to feed ----
    socialCommentChange() {
        return this.commentObs$;
    }

    announceSocialComment(com: SocialComment) {
        // console.log('Change detected in Comments!');
        this.commentObs$.next(com);
    }
    // -----------------------------------------------------------------------------


    getAll(): Observable<SocialComment[]> {
        return this.httpClient.get<SocialComment[]>(this.API_URL);
    }


    get(_id: string): Observable<SocialComment> {
        return this.httpClient.get<SocialComment>(this.API_URL + _id);
    }


    sendNotification(_comment) {
        return this.httpClient.post<SocialComment>(this.EVENT_URL + 'announce-new-social-comment', JSON.stringify(_comment), this.headerOptions);
    }


    createCommentWithNotification(_comment: SocialComment): Promise<SocialComment> {
        return new Promise(async (resolve, reject) => {
            const _savedComment = await this.create(_comment).toPromise();

            this.sendNotification(_savedComment).toPromise();

            resolve(_savedComment);
        }); 
    }


    create(_socialComment): Observable<SocialComment> {
        return this.httpClient.post<SocialComment>(this.API_URL, JSON.stringify(_socialComment), this.headerOptions);
    }


    search(searchTerms): Observable<SocialComment[]> {
        return this.httpClient.post<SocialComment[]>(this.API_URL + 'search', JSON.stringify(searchTerms), this.headerOptions);
    }


    searchForOne(searchTerms): Observable<SocialComment> {
        return this.httpClient.post<SocialComment>(this.API_URL + 'search-for-one', JSON.stringify(searchTerms), this.headerOptions);
    }


    selectAllSearch(searchParams): Observable<SocialCommentApi> {
        searchParams['selectAll'] = true;
        return this.httpClient.post<SocialCommentApi>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchParams), this.headerOptions);
    }


    getCustomDatasource(searchParams, sortField = 'name', sortDirection = 'desc', pageNumber: number = 0, pageSize: number = 10): Observable<SocialCommentApi> {
        searchParams['selectAll'] = false;

        searchParams.sortField = sortField;
        searchParams.sortOrder = sortDirection;
        searchParams.pageNumber = pageNumber;
        searchParams.pageSize = pageSize;

        return this.httpClient.post<SocialCommentApi>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchParams), this.headerOptions);
    }


    update(_socialComment: SocialComment): Observable<SocialComment> {
        return this.httpClient.put<SocialComment>(`${this.API_URL}${_socialComment._id}`, JSON.stringify(_socialComment), this.headerOptions);
    }


    markOneAsViewedByUser(_comment, _userToAdd): Observable<any[]> {
        const commentId = (_comment != undefined && _comment._id != undefined) ? _comment._id : _comment;
        const updateRequest = { _id: commentId, userToAdd: _userToAdd};
        return this.httpClient.put<any>(this.API_URL + 'mark-one-as-viewed-by/by-user', JSON.stringify(updateRequest), this.headerOptions);
    }


    markAsViewedByUser(_userToAdd): Observable<any[]> {
        const updateRequest = { userToAdd: _userToAdd};
        return this.httpClient.put<any>(this.API_URL + 'mark-as-viewed-by/by-user', JSON.stringify(updateRequest), this.headerOptions);
    }


    markAsViewedByConversationArrayAndNotViewedByUser(_conversationIds, _userToAdd): Observable<any[]> {
        const updateRequest = { conversationIds: _conversationIds, userToAdd: _userToAdd};
        return this.httpClient.put<any>(this.API_URL + 'mark-as-viewed-by/by-conversations-and-not-viewed-by', JSON.stringify(updateRequest), this.headerOptions);
    }


    markDeleted(id: string): Observable<any> {
        return this.httpClient.put(this.API_URL + 'mark-deleted/' + id, this.headerOptions);
    }


    markDeletedByIdArray(arrayOfIds): Observable<any[]> {
        const deleteRequest = { ids: arrayOfIds };
        return this.httpClient.put<any>(this.API_URL + 'mark-as-deleted/by-array', JSON.stringify(deleteRequest), this.headerOptions);
    }


    markDeletedByConversationGroupKey(_conversation): Observable<any[]> {
        const deleteRequest = { conversation: _conversation };
        return this.httpClient.put<any>(this.API_URL + 'mark-as-deleted/by-conversationGroupKey', JSON.stringify(deleteRequest), this.headerOptions);
    }


    delete(_id: string): Observable<SocialComment> {
        return this.httpClient.delete<SocialComment>(this.API_URL + _id);
    }


    deleteByIdArray(arrayOfIds): Observable<any[]> {
        const deleteRequest = {ids: arrayOfIds};
        return this.httpClient.put<any>(this.API_URL + 'delete-forever/by-array', JSON.stringify(deleteRequest), this.headerOptions);
    }
}