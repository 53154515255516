import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { MatSnackBar } from '@angular/material/snack-bar';

import { User } from '@app/models/user';
import { UserService } from '@app/services/user.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  user: User;
  errorMessage: String;
  showRegisterForm = false;
  isLoading: boolean = false;

  constructor(
    private router: Router,
    public snackBar: MatSnackBar,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.user = new User();
  }


  backToLogin() {
    this.router.navigate(['/login']);
  }


  async sendEmail() {
    this.isLoading = true;

    const emailObject = {email: this.user.email};
    // send a password reset email to this user. If they did not give a correct email, do nothing
    const _reqAttempt = await this.userService.passwordResetRequest(emailObject).toPromise();

    let passwordRequestResponse = 'Password reset request was successful.';
    let messageType = 'Success';

    if (_reqAttempt.error) {
      passwordRequestResponse = _reqAttempt.error;
      messageType = 'Warning';
    }

    this.snackBar.open(passwordRequestResponse, messageType, {
      duration: 1000
    });

    this.router.navigate(['/new-password']);
  }
}