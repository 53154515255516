import { Component, Inject } from '@angular/core';
import { MatSnackBar, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-mint-notification',
  templateUrl: './mint-notification.component.html',
  styleUrls: ['./mint-notification.component.scss']
})
export class MintNotificationComponent {
  /*
    Data is passed up in object:
    data: {
      message: 'Text passed in',
      class: 'message class passed up'
    }
  */
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    private _snackBar: MatSnackBar
  ) { }


  dismissMessage() {
    this._snackBar.dismiss();
  }
}
