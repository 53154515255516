<div class="container confirm-action-dialog">
  <h1 mat-dialog-title>{{ title }}</h1>
  
  <mat-dialog-content>
    Status: {{ timingStatus?.currentPerc }} of {{ timingStatus?.fullAmount }}

    <div class="determinate-progess" *ngIf="timingStatus">
      <mat-progress-bar mode="determinate" [value]="timingStatus.percentComplete"></mat-progress-bar>
    </div>

    <div class="indeterminate-progess" *ngIf="!timingStatus">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
  </mat-dialog-content>

<!--   <mat-dialog-actions class="pin-to-bottom">
    <button mat-button mat-dialog-close (click)="cancel()">Cancel</button>
    <button mat-raised-button color="primary" (click)="closeDialog()">Okay</button>
  </mat-dialog-actions> -->
</div>