import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/internal/Observable';
import { Subject } from 'rxjs/internal/Subject';
import { AdminSettingsService } from './admin-settings.service';

export interface OpportunityApi {
  items: any[];
  total_count: number;
}

@Injectable({ providedIn: 'root' })
export class OpportunityService {
  private oppObs$ = new Subject<any>();
  private currentOpportunity;

  public currentSearch = {
    params: null,
    results: null
  };

  private API_URL = '/api/opportunities/';
  private CAMPAIGNS_URL = '/api/campaigns/';

  private headerOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

  constructor(
    private httpClient: HttpClient,
    private _adminSettingsService: AdminSettingsService
  ) { }

  setCurrent(_opportunity): void {
    // console.log('**************** SETTING CURRENT Opportunity: ', _opportunity);
    this.currentOpportunity = _opportunity;
  }


  getCurrent() {
    return this.currentOpportunity;
  }


  create(_opportunity): Observable<any> {
    return this.httpClient.post<any>(this.API_URL, JSON.stringify(_opportunity), this.headerOptions);
  }


  updateFieldsByIdArray(arrayOfIds, fieldsToUpdate): Observable<any[]> {
    const updateRequest = {ids: arrayOfIds, fields: fieldsToUpdate};
    return this.httpClient.put<any[]>(this.API_URL + 'update-certain-fields-array/', JSON.stringify(updateRequest), this.headerOptions);
  }


  updateFieldsById(_id, fieldsToUpdate): Observable<OpportunityApi> {
    return this.httpClient.put<OpportunityApi>(this.API_URL + 'update-certain-fields/' + _id, JSON.stringify(fieldsToUpdate), this.headerOptions);
  }


  update(_opportunity): Observable<any> {
    return this.httpClient.put<any>(this.API_URL + _opportunity._id, JSON.stringify(_opportunity), this.headerOptions);
  }


  updateFavorites(_opportunity): Observable<any> {
    return this.httpClient.put<any>(this.API_URL + 'favorite/' + _opportunity._id, JSON.stringify(_opportunity), this.headerOptions);
  }


  search(searchTerms): Observable<any[]> {
    if (!searchTerms['selectAll'] && searchTerms['selectAll'] != false) searchTerms['selectAll'] = true;
    return this.httpClient.post<any[]>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchTerms), this.headerOptions);
  }


  getOpportunity(_id: string): Observable<any> {
    return this.httpClient.get<any>(this.API_URL + _id);
  }


  basicSearch(searchTerms): Observable<any[]> {
    return this.httpClient.post<any[]>(this.API_URL + 'basic-search', JSON.stringify(searchTerms), this.headerOptions);
  }


  selectAllSearch(searchTerms): Observable<OpportunityApi> {
    searchTerms['selectAll'] = true;
    searchTerms['selectNumberOfRecords'] = false;
    return this.httpClient.post<OpportunityApi>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchTerms), this.headerOptions);
  }


  delete(id: string): Observable<any> {
    return this.httpClient.put(this.API_URL + 'mark-deleted/' + id, this.headerOptions);
  }


  selectNumberOfRecordsSearch(searchParams): Observable<any> {
    searchParams['selectAll'] = false;
    searchParams['selectNumberOfRecords'] = true;
    return this.httpClient.post<any>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchParams), this.headerOptions);
  }


  getCustomDatasource(searchParams, sortField = 'name', sortDirection = 'asc', pageNumber: number = 0, pageSize: number = 10): Observable<OpportunityApi> {
    searchParams['selectAll'] = false;
    searchParams['selectNumberOfRecords'] = false;

    searchParams.sortField = sortField;
    searchParams.sortOrder = sortDirection;
    searchParams.pageNumber = pageNumber;
    searchParams.pageSize = pageSize;

    return this.httpClient.post<OpportunityApi>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchParams), this.headerOptions); 
  }
}