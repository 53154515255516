import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/internal/Observable';
import { StoreCoin } from '@app/models';

export interface StoreCoinApi {
    items: any[];
    total_count: number;
}

@Injectable({ providedIn: 'root' })
export class StoreCoinService {
    private currentStoreCoin;

    private API_URL = '/api/store-coins/';
    private headerOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    constructor(
        private httpClient: HttpClient
    ) { }

    setCurrent(_storeCoin): void {
        this.currentStoreCoin = _storeCoin;
    }


    getCurrent() {
        return this.currentStoreCoin;
    }


    getAll(): Observable<StoreCoin[]> {
        return this.httpClient.get<StoreCoin[]>(this.API_URL);
    }


    get(_id: string): Observable<StoreCoin> {
        return this.httpClient.get<StoreCoin>(this.API_URL + _id);
    }


    getByClient(_clientId: string): Observable<StoreCoin> {
        return this.httpClient.get<StoreCoin>(this.API_URL + 'byClient/' + _clientId);
    }


    getEnabledByClient(_clientId: string): Observable<StoreCoin> {
        return this.httpClient.get<StoreCoin>(this.API_URL + 'isEnabled/byClient/' + _clientId);
    }


    create(_storeCoin): Observable<StoreCoin> {
        return this.httpClient.post<StoreCoin>(this.API_URL, JSON.stringify(_storeCoin), this.headerOptions);
    }


    selectAllSearch(searchParams): Observable<StoreCoinApi> {
        searchParams['selectAll'] = true;
        return this.httpClient.post<StoreCoinApi>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchParams), this.headerOptions);
    }


    getCustomDatasource(searchParams, sortField = 'createdAt', sortDirection = 'desc', pageNumber: number = 0, pageSize: number = 10): Observable<StoreCoinApi> {
        searchParams['selectAll'] = false;

        searchParams.sortField = sortField;
        searchParams.sortOrder = sortDirection;
        searchParams.pageNumber = pageNumber;
        searchParams.pageSize = pageSize;

        return this.httpClient.post<StoreCoinApi>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchParams), this.headerOptions);
    }


    update(_storeCoin: StoreCoin): Observable<StoreCoin> {
        return this.httpClient.put<StoreCoin>(`${this.API_URL}${_storeCoin._id}`, JSON.stringify(_storeCoin), this.headerOptions);
    }


    delete(_id: string): Observable<StoreCoin> {
        return this.httpClient.delete<StoreCoin>(this.API_URL + _id);
    }


    deleteByIdArray(arrayOfIds): Observable<any[]> {
        const deleteRequest = {ids: arrayOfIds};
        return this.httpClient.put<any>(this.API_URL + 'delete-forever/by-array', JSON.stringify(deleteRequest), this.headerOptions);
    }
}