import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PublicPortalComponent } from './public-portal/public-portal.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';

// import { RecruitingSalesComponent } from './recruiting-sales/recruiting-sales.component';
// import { HiringOnboardingComponent } from './hiring-onboarding/hiring-onboarding.component';
// import { TalentManagementComponent } from './talent-management/talent-management.component';
// import { TimeKeepingInvoicingComponent } from './time-keeping-invoicing/time-keeping-invoicing.component';
// import { MarketingAutomationComponent } from './marketing-automation/marketing-automation.component';
// import { ReportingAnalyticsComponent } from './reporting-analytics/reporting-analytics.component';
// import { PricingComponent } from './pricing/pricing.component';
// import { AboutUsComponent } from './about-us/about-us.component';
// import { RecruiterGamesComponent } from './recruiter-games/recruiter-games.component';
import { GmailAuthCodeComponent } from './gmail-auth-code/gmail-auth-code.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { NewPasswordComponent } from './new-password/new-password.component';
import { TestComponent } from './test/test.component';

const publicRoutes: Routes = [
  {
      path: '',
      component: PublicPortalComponent,
      children: [
        { path: '', redirectTo: '/login', pathMatch: 'full' },
        { path: 'home', component: LoginComponent },
        // { path: 'recruiting-sales', component: RecruitingSalesComponent },
        // { path: 'hiring-credentialing', component: HiringOnboardingComponent },
        // { path: 'talent-management', component: TalentManagementComponent },
        // { path: 'time-attendance', component: TimeKeepingInvoicingComponent },
        // { path: 'marketing-campaign-management', component: MarketingAutomationComponent },
        // { path: 'reporting-analytics', component: ReportingAnalyticsComponent },
        // { path: 'pricing', component: PricingComponent },
        // { path: 'recruiter-games', component: RecruiterGamesComponent },
        // { path: 'about-us', component: AboutUsComponent },
        { path: 'login', component: LoginComponent},
        { path: 'test', component: TestComponent},
        { path: 'logout', component: LogoutComponent },
        { path: 'gmail-callback', component: GmailAuthCodeComponent },
        { path: 'forgot-password', component: ForgotPasswordComponent },
        { path: 'new-password', component: NewPasswordComponent },
      ]
  }
];

@NgModule({
    imports: [
      RouterModule.forChild(publicRoutes)
    ],
    exports: [
      RouterModule
    ]
  })

export class PublicRoutingModule { }
