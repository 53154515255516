export class LeaderboardItem {
    rank: number;
    image: string;
    ownerId: string;
    title: string;
    score: number;
    credits: number;
    members: LeaderboardItemMember[];
}

export class LeaderboardItemMember {
    ownerId: string;
    name: string;
    score: number;
    credits: number;
}