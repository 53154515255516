import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService, UserService } from '@app/services';
import { lastValueFrom } from 'rxjs/internal/lastValueFrom';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {
  errorMessage: string = '';
  loginAvailable: boolean = false;

  constructor(
    private _authService: AuthService,
    private _userService: UserService,
    private router: Router
  ) { }

  ngOnInit() {
    this.logout();
  }


  async logout() {
    await lastValueFrom(this._userService.logoutWithoutRemovingUser());

    this._authService.stopUserIdle();

    const hasCurrentUserInStorage = localStorage.getItem('currentUser');

    if (hasCurrentUserInStorage) {
      // console.log('Has User in storage. Removing them and reloading...');
      localStorage.removeItem('currentUser');
      window.location.reload();
    } else {
      // do not want them to navigate away to login before reloading.. Need to get new layouts, etc.
      this.loginAvailable = true;
    }

    this._authService.logout();
  }


  login() {
    this.router.navigate(['/login']);
  }  

}
