export class ReportChart {
    _id: string;
    title: string;
    parentReport: any;
    chartType: string;
    chartTheme: any; // Color scheme for chart
    attributes: ChartAttribute;
    deleted: boolean;   
}

export class ChartAttribute {
    dataGroupBy: any;
    allRowGroups: any;
    dataValueType: string;
    showXAxis: boolean;
    showYAxis: boolean;
    gradient: boolean;
    showLegend: boolean;
    legendPosition: string;
    showLabels: boolean;
    showXAxisLabel: boolean;
    showYAxisLabel: boolean;
    yAxisLabel: string;
    xAxisLabel: string;
    trimXAxisTicks: boolean;
    trimYAxisTicks: boolean;
    showGridLines: boolean;
    showDataLabel: boolean;
    tooltipDisabled: boolean;
    timeline: boolean;
    gaugeMax: number;

    // Used for pie chart
    isDoughnut: boolean;
    arcWidth: number;
}