import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/internal/Observable';
import { ModuleReportDashboard } from '@app/models';

export interface ModuleReportDashboardApi {
    items: any[];
    total_count: number;
}

@Injectable({ providedIn: 'root' })
export class ModuleReportDashboardService {
    private currentModuleReportDashboard;

    private API_URL = '/api/module-report-dashboards/';
    private headerOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    constructor(
        private httpClient: HttpClient
    ) { }

    setCurrent(_moduleReportDashboard): void {
        this.currentModuleReportDashboard = _moduleReportDashboard;
    }


    getCurrent() {
        return this.currentModuleReportDashboard;
    }


    getAll(): Observable<ModuleReportDashboard[]> {
        return this.httpClient.get<ModuleReportDashboard[]>(this.API_URL);
    }


    get(_id: string): Observable<ModuleReportDashboard> {
        return this.httpClient.get<ModuleReportDashboard>(this.API_URL + _id);
    }


    getByModule(_moduleId: string): Observable<ModuleReportDashboard> {
        return this.httpClient.get<ModuleReportDashboard>(this.API_URL + 'byModule/' + _moduleId);
    }


    create(_moduleReportDashboard): Observable<ModuleReportDashboard> {
        return this.httpClient.post<ModuleReportDashboard>(this.API_URL, JSON.stringify(_moduleReportDashboard), this.headerOptions);
    }


    selectAllSearch(searchParams): Observable<ModuleReportDashboardApi> {
        searchParams['selectAll'] = true;
        return this.httpClient.post<ModuleReportDashboardApi>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchParams), this.headerOptions);
    }


    getCustomDatasource(searchParams, sortField = 'name', sortDirection = 'desc', pageNumber: number = 0, pageSize: number = 10): Observable<ModuleReportDashboardApi> {
        searchParams['selectAll'] = false;

        searchParams.sortField = sortField;
        searchParams.sortOrder = sortDirection;
        searchParams.pageNumber = pageNumber;
        searchParams.pageSize = pageSize;

        return this.httpClient.post<ModuleReportDashboardApi>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchParams), this.headerOptions);
    }


    update(_moduleReportDashboard: ModuleReportDashboard): Observable<ModuleReportDashboard> {
        return this.httpClient.put<ModuleReportDashboard>(`${this.API_URL}${_moduleReportDashboard._id}`, JSON.stringify(_moduleReportDashboard), this.headerOptions);
    }


    delete(_id: string): Observable<ModuleReportDashboard> {
        return this.httpClient.delete<ModuleReportDashboard>(this.API_URL + _id);
    }


    deleteByIdArray(arrayOfIds): Observable<any[]> {
        const deleteRequest = {ids: arrayOfIds};
        return this.httpClient.put<any>(this.API_URL + 'delete-forever/by-array', JSON.stringify(deleteRequest), this.headerOptions);
    }


    sortArrayByOrder(_arrayToSort): Promise<any[]> {
        return new Promise(async(resolve) => {
            let sortedUserDashArray = _arrayToSort.sort(this.sortByOrder);
            resolve(sortedUserDashArray);
        });
    }


    sortByOrder( a, b ) {
        if (a.options == undefined || a.options.order == undefined) return 1;
        else if (b.options == undefined || b.options.order == undefined) return -1;
        else if ( a.options.order < b.options.order ) return -1;
        else if ( a.options.order > b.options.order ) return 1;
        else return 0;
    }
}