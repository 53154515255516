import {Injectable} from '@angular/core';
import {UntypedFormArray, UntypedFormControl, UntypedFormGroup} from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class FormValidationService {

  formErrors: { [id: string]: any } = {};
  errorMessages: { [id: string]: { [id: string]: string } };

  constructor() {
  }

  initForm(errors: { [id: string]: { [id: string]: string } }) { // {[id: string]: {[id: string]: string}}
      return this.clearErrorMessages(errors);
  }

  clearErrorMessages(errors) {
      // caution state set in this service
      this.errorMessages = errors;
      this.formErrors = {};

      for (let key in this.errorMessages) {
          if (this.errorMessages.hasOwnProperty(key)) {
              if (key.indexOf('.') === -1) {
                  this.formErrors[key] = '';
              } else {
                  const controlgroup = key.split('.');
                  const group = controlgroup[0];
                  key = controlgroup[1];
                  if (!this.formErrors[group]) {
                      this.formErrors[group] = {};
                  }
                  if (key.indexOf('[]') === -1) {
                      this.formErrors[group][key] = '';
                  } else {
                      key = key.replace(/[\[\]']+/g, '');
                      this.formErrors[group][key] = [];
                  }
              }
          }
      }
      return this.formErrors;
  }

  validateForm(form: UntypedFormGroup, formError: { [id: string]: string }, isSubmit: Boolean = false) {
      let hasError = false;
      for (const field in formError) {
          if (formError.hasOwnProperty(field)) {
              if (typeof formError[field] === 'object') {
                  if (Array.isArray(form.controls[field].value)) {
                      // formArray
                      const formArray = <UntypedFormArray>form.controls[field]; // <FormArray>form.controls.profiles;
                      const fieldsInFormError = <any>formError[field];
                      for (const infield in fieldsInFormError) {
                          if (!fieldsInFormError.hasOwnProperty(infield)) {
                              continue;
                          }
                          // object keys
                          // infield title, location, empCategory for profiles
                          formError[field][infield] = []; // clear current errors
                          if (form.controls[field].dirty && !form.controls[field].valid) { // any error in formArray
                              for (let i = 0; i < formArray.length; i++) {
                                  // expecting one profile in first version
                                  if (fieldsInFormError[infield]) { // ensure registered error in formArray object
                                      const fieldData = formArray.controls[i]['controls'][infield];
                                      if (fieldData.dirty && !fieldData.valid) {
                                          const fieldErrors = fieldData.errors;
                                          // loop errors
                                          let errorMessages = '';
                                          for (const fieldError in fieldErrors) {
                                              if (!fieldErrors.hasOwnProperty(fieldError)) {
                                                  continue;
                                              }
                                              if (this.errorMessages[field + '.' + infield + '[]'][fieldError]) {
                                                  if (errorMessages.length) {
                                                      errorMessages += ', ';
                                                  }
                                                  errorMessages += this.errorMessages[field + '.' + infield + '[]'][fieldError];
                                              }
                                          }
                                          formError[field][infield]
                                              .splice(i, 0, errorMessages);
                                      }
                                  }
                              }
                          }
                      }
                  } else {
                      const groupObject = formError[field];
                      const group = field;
                      for (const infield in <any>groupObject) {
                          if (groupObject.hasOwnProperty(infield)) {
                              formError[group][infield] = '';
                              hasError = (( form.controls[group]['controls'][infield].dirty && !isSubmit )
                                  && !form.controls[group]['controls'][infield].valid );
                              if (hasError) {
                                  const fieldErrors = form.controls[group]['controls'][infield].errors;
                                  for (const fieldError in fieldErrors) {
                                      if (fieldErrors.hasOwnProperty(fieldError)) {
                                          if (this.errorMessages[group + '.' + infield][fieldError]) {
                                              formError[group][infield] +=
                                                  this.errorMessages[group + '.' + infield][fieldError] + ' ';
                                          }
                                      }
                                  }
                              }
                          }
                      }
                  }
              } else {
                  formError[field] = '';
                  hasError = (( form.controls[field].dirty && !isSubmit ) && !form.controls[field].valid );
                  if (hasError) {
                      const fieldErrors = form.controls[field].errors;
                      for (const key in fieldErrors) {
                          if (fieldErrors.hasOwnProperty(key)) {
                              if (this.errorMessages[field][key]) {
                                  formError[field] +=
                                      this.errorMessages[field][key] + ' ';
                              }
                          }
                      }
                  } else {
                      // this is a check for general form validation - not field specific
                      // the field should not have a validator or issues may occur
                      if (form.hasError(field)) {
                          const fieldErrors = this.errorMessages[field];
                          for (const key in fieldErrors) {
                              if (fieldErrors.hasOwnProperty(key) && this.errorMessages[field][key] && form.hasError(key)) {
                                  formError[field] +=
                                      this.errorMessages[field][key] + ' ';
                              }
                          }
                      }
                  }

              }

          }
      }
      return formError;
  }

  /////////////////////////////
  // custom field validators
  /////////////////////////////

  validateUsername(c: UntypedFormControl) {
      const USERNAME_REGEXP = /[a-z0-9]{5,15}$/i;
      return USERNAME_REGEXP.test(c.value) ? null : {
          validateUsername: {
              valid: false
          }
      };
  }

  validatePassword(c: UntypedFormControl) {
      const PASSWORD_REGEXP = /.{8,}$/i;
      return PASSWORD_REGEXP.test(c.value) ? null : {
          validatePassword: {
              valid: false
          }
      };
  }

  wholeNumber(c: UntypedFormControl) {
      return (!isNaN(parseFloat(c.value)) && isFinite(c.value) && c.value >= 0 && Math.floor(c.value) === Number(c.value)) || null
          ? null : {
          notWholeNumber: {
              valid: false
          }
      };
  }

  validateMatchingFields(field1Key: string, field2Key: string) {
      return (group: UntypedFormGroup): { [key: string]: any } => {
          const field1 = group.controls[field1Key];
          const field2 = group.controls[field2Key];

          if (field1 && field2 && field1.value !== field2.value) {
              return {
                  mismatchFields: true
              };
          }
      };
  }

  validateEmail(c: UntypedFormControl) {
      const EMAIL_REGEXP = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/i;
      return EMAIL_REGEXP.test(c.value) ? null : {
          validateEmail: {
              valid: false
          }
      };
  }

  validatePin(c: UntypedFormControl) {
      const PIN_REGEXP = /^[0-9]{4}$/i;
      return PIN_REGEXP.test(c.value) ? null : {
          validatePin: {
              valid: false
          }
      };
  }

  validateDOB(c: UntypedFormControl) {
      const d1 = new Date();
      const d2 = new Date(c.value);
      d1.setHours(0, 0, 0, 0);
      d2.setHours(0, 0, 0, 0);
      if (!c.value || c.value.length === 0 ||
          ( d2.getFullYear() > d1.getFullYear() - 12 )) {
          return {
              validateDOB: {
                  valid: false
              }
          };
      } else {
          return null;
      }
  }

  validatePermGroup(c: UntypedFormControl) {
      return c.value && c.value.length ? null : {
          validatePermGroup: {
              valid: false
          }
      };
  }

  validateBirthDate(c: UntypedFormControl) {
      const d1 = new Date();
      const birthday = new Date(c.value);
      d1.setHours(0, 0, 0, 0);
      birthday.setHours(0, 0, 0, 0);

      const ageDate = new Date(d1.getTime() - birthday.getTime());
      const age = Math.abs(ageDate.getUTCFullYear() - 1970);

      // todo: restrict to 14 years old
      if (!c.value || c.value.length === 0 ||
          ( age < 14 )) {
          return {
              dateOfBirth: {
                  valid: false
              }
          };
      } else {
          return null;
      }
  }

  validateHireDate(c: UntypedFormControl) {
      if (c.parent && c.parent.controls['type'] && c.parent.controls['type'].value === 'emp') {
          if (!c.value) {
              return {
                  hireDate: {
                      valid: false
                  }
              };
          }
          const expDate = new Date(c.value);
          const today = new Date();
          if (expDate < today) {
              return null;
          } else {
              return {
                  hireDate: {
                      valid: false
                  }
              };
          }
      }
  }

  requiredForEmp(c: UntypedFormControl) {
      if (c.parent && c.parent.controls['type'] && c.parent.controls['type'].value === 'emp' && !c.value) {
          return {
              requiredForEmp: {
                  valid: false
              }
          };
      } else {
          return null;
      }
  }

  /////////////////////////
  // form group validators
  //////////////////////////
  districtSelectValidator(g: UntypedFormGroup) {
      const errors = {};
      if (g.controls['districtsSelect'].value === 'select' && !g.controls['selectedDistricts'].value.length) {
          errors['districtsSelect'] = true;
          errors['noDistrictsSelected'] = true;
      }
      if (g.controls['system'] && !g.controls['system'].value && g.controls['districtsSelect'].value === 'none') {
          errors['system'] = true;
          errors['systemAndOrDistricts'] = true;
      }
      return Object.keys(errors).length ? errors : null;
  }

  numHoursRequired(g: UntypedFormGroup) {
      const errors = {};
      if ((g.controls['eventType'].value === 'Early Release' || g.controls['eventType'].value === 'Delayed Start')
          && !g.controls['hoursOffset'].value) {
          errors['numHours'] = true;
          errors['noNumHours'] = true;
      }
      return Object.keys(errors).length ? errors : null;
  }

  systemMessageValidator(g: UntypedFormGroup) {
      const errors = {};
      if (( g.value.recipients.sysadmin == null || g.value.recipients.sysadmin === false ) &&
          ( g.value.recipients.admin == null || g.value.recipients.admin === false ) &&
          ( g.value.recipients.emp == null || g.value.recipients.emp === false ) &&
          ( g.value.recipients.sub == null || g.value.recipients.sub === false )) {
          errors['recipients'] = true;
          errors['noRecipientsSelected'] = true;
      }
      return Object.keys(errors).length ? errors : null;
  }
}
