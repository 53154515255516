import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/internal/Observable';

import { Layout } from '../models/layout';

@Injectable({ providedIn: 'root' })
export class MergeService {
  private API_URL = '/api/merge/';
  private headerOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

  constructor(
    private httpClient: HttpClient,
  ) { }

  mergeRequest(_moduleName, fromRecordId, toRecordId): Observable<any> {
    return this.httpClient.post<Layout>(this.API_URL, JSON.stringify({_moduleName, fromRecordId, toRecordId}), this.headerOptions);
  } 

}
