import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/internal/Observable';
import { DefaultDashboard } from '@app/models';

export interface DefaultDashboardApi {
    items: any[];
    total_count: number;
}

@Injectable({ providedIn: 'root' })
export class DefaultDashboardService {
    private currentDefaultDashboard;

    private API_URL = '/api/default-dashboards/';
    private headerOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    constructor(
        private httpClient: HttpClient
    ) { }

    setCurrent(_defaultDashboard): void {
        this.currentDefaultDashboard = _defaultDashboard;
    }


    getCurrent() {
        return this.currentDefaultDashboard;
    }


    getAll(): Observable<DefaultDashboard[]> {
        return this.httpClient.get<DefaultDashboard[]>(this.API_URL);
    }


    get(_id: string): Observable<DefaultDashboard> {
        return this.httpClient.get<DefaultDashboard>(this.API_URL + _id);
    }


    create(_defaultDashboard): Observable<DefaultDashboard> {
        return this.httpClient.post<DefaultDashboard>(this.API_URL, JSON.stringify(_defaultDashboard), this.headerOptions);
    }


    selectAllSearch(searchParams): Observable<DefaultDashboardApi> {
        searchParams['selectAll'] = true;
        return this.httpClient.post<DefaultDashboardApi>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchParams), this.headerOptions);
    }


    getCustomDatasource(searchParams, sortField = 'name', sortDirection = 'desc', pageNumber: number = 0, pageSize: number = 10): Observable<DefaultDashboardApi> {
        searchParams['selectAll'] = false;

        searchParams.sortField = sortField;
        searchParams.sortOrder = sortDirection;
        searchParams.pageNumber = pageNumber;
        searchParams.pageSize = pageSize;

        return this.httpClient.post<DefaultDashboardApi>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchParams), this.headerOptions);
    }


    update(_defaultDashboard: DefaultDashboard): Observable<DefaultDashboard> {
        return this.httpClient.put<DefaultDashboard>(`${this.API_URL}${_defaultDashboard._id}`, JSON.stringify(_defaultDashboard), this.headerOptions);
    }


    delete(_id: string): Observable<DefaultDashboard> {
        return this.httpClient.delete<DefaultDashboard>(this.API_URL + _id);
    }


    deleteByIdArray(arrayOfIds): Observable<any[]> {
        const deleteRequest = {ids: arrayOfIds};
        return this.httpClient.put<any>(this.API_URL + 'delete-forever/by-array', JSON.stringify(deleteRequest), this.headerOptions);
    }


    sortArrayByOrder(_arrayToSort): Promise<any[]> {
        return new Promise(async(resolve) => {
            let sortedUserDashArray = _arrayToSort.sort(this.sortByOrder);
            resolve(sortedUserDashArray);
        });
    }


    sortByOrder( a, b ) {
        if (a.options == undefined || a.options.order == undefined) return 1;
        else if (b.options == undefined || b.options.order == undefined) return -1;
        else if ( a.options.order < b.options.order ) return -1;
        else if ( a.options.order > b.options.order ) return 1;
        else return 0;
    }
}