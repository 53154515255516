import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/internal/Observable';
import { Subject } from 'rxjs/internal/Subject';

import { cacheable } from '../utils/rxjs-functions';
import { SharedUtilsService } from './shared-utils.service';

import { ExpiringCache } from '../models/expiringCache';

export interface DocumentApi {
  items: any[];
  total_count: number;
}

@Injectable({ providedIn: 'root' })
export class DocumentService {
  private documentObs$ = new Subject<any>();
  private currentDocument;

  public currentSearch = {
    params: null,
    results: null
  };

  private API_URL = '/api/documents/';

  private headerOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

  documentsStorage: ExpiringCache = new ExpiringCache();

  constructor(
    private httpClient: HttpClient,
    private _sharedUtilsService: SharedUtilsService
  ) { }


  setCurrent(_document): void {
    this.currentDocument = _document;
  }


  getCurrent() {
    return this.currentDocument;
  }


  listenForDocumentChange() {
    return this.documentObs$;
  }


  announceDocumentChange(_doc: any) {
      this.documentObs$.next(_doc);
  }


  create(_document): Observable<any> {
    return this.httpClient.post<any>(this.API_URL, JSON.stringify(_document), this.headerOptions);
  }


  getCached(): Observable<any[]> {
    // smarter than just going to api every time. Getting them all, so just cache for use in dropdowns all over
    if (this._sharedUtilsService.isStorageValid(this.documentsStorage)) {
      // console.log('Saved an API call for documents. Retreived from cache.');
      return this.documentsStorage.itemCache;
    }

    this.documentsStorage = this._sharedUtilsService.updateStorageExpiration(this.documentsStorage);
    return this.documentsStorage.itemCache = cacheable<any>(this.httpClient.get<any[]>(this.API_URL));
  }


  updateFieldsByIdArray(arrayOfIds, fieldsToUpdate): Observable<any[]> {
    const updateRequest = {ids: arrayOfIds, fields: fieldsToUpdate};
    return this.httpClient.put<any[]>(this.API_URL + 'update-certain-fields-array/', JSON.stringify(updateRequest), this.headerOptions);
  }


  updateFieldsById(_id, fieldsToUpdate): Observable<any[]> {
    return this.httpClient.put<any[]>(this.API_URL + 'update-certain-fields/' + _id, JSON.stringify(fieldsToUpdate), this.headerOptions);
  }


  update(_document): Observable<any> {
    return this.httpClient.put<any>(this.API_URL + _document._id, JSON.stringify(_document), this.headerOptions);
  }


  updateFavorites(_document): Observable<any> {
    return this.httpClient.put<any>(this.API_URL + 'favorite/' + _document._id, JSON.stringify(_document), this.headerOptions);
  }


  search(searchTerms): Observable<any[]> {
    searchTerms['selectAll'] = true;
    searchTerms['selectNumberOfRecords'] = false;
    return this.httpClient.post<any[]>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchTerms), this.headerOptions);
  }


  getDocument(_id: string): Observable<any> {
    return this.httpClient.get<any>(this.API_URL + _id);
  }


  delete(id: string): Observable<any> {
    return this.httpClient.put(this.API_URL + 'mark-deleted/' + id, this.headerOptions);
  }


  advancedSearch(searchTerms): Observable<any[]> {
    return this.httpClient.post<any[]>(this.API_URL + 'advancedSearch', JSON.stringify(searchTerms), this.headerOptions);
  }


  selectAllSearch(_moduleOptions): Observable<any[]> {
    // searchTerms['selectAll'] = true;
    _moduleOptions.searchTerms['selectAll'] = true;
    _moduleOptions.searchTerms['selectNumberOfRecords'] = false;
    return this.httpClient.post<any[]>(this.API_URL + 'dataSourceSearch', JSON.stringify(_moduleOptions), this.headerOptions);
  }


  selectNumberOfRecordsSearch(searchParams): Observable<any> {
    searchParams['selectAll'] = false;
    searchParams['selectNumberOfRecords'] = true;
    return this.httpClient.post<any>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchParams), this.headerOptions);
  }


  getDataSource(searchParams, sortField = 'name', sortDirection = 'asc', pageNumber: number = 0, pageSize: number = 10): Observable<DocumentApi> {
    searchParams['selectAll'] = false;
    searchParams['selectNumberOfRecords'] = false;

    searchParams.sortField = sortField;
    searchParams.sortOrder = sortDirection;
    searchParams.pageNumber = pageNumber;
    searchParams.pageSize = pageSize;

    return this.httpClient.post<DocumentApi>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchParams), this.headerOptions); 
  }
}