import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';

import { Observable } from 'rxjs/internal/Observable';
import { Subject } from 'rxjs/internal/Subject';

import { District } from '@app/models/district';

export interface DistrictApi {
  items: District[];
  total_count: number;
}

@Injectable({ providedIn: 'root' })
export class DistrictService {
  private districtChangedObs$ = new Subject<District>();
  private currentDistrictBeingViewed: District;

  private districtStorageKey = 'district-mode';

  form: UntypedFormGroup;

  private API_URL = '/api/districts/';
  headerOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};   

  constructor(
    private httpClient: HttpClient,
    private formBuilder: UntypedFormBuilder
  ) { }

  setCurrentDistrictBeingViewed(_district: District): void {
    this.currentDistrictBeingViewed = _district;
  }

  getCurrentDistrictBeingViewed(): District {
    return this.currentDistrictBeingViewed;
  }


  enterDistrictMode(_district) {
    this.setCurrentDistrictBeingViewed(_district);
    // console.log('DISTRICT MODE SET FOR: ', _district);
    this.announceDistrictMode(_district);
  }


  clearDistrictMode() {
    this.setCurrentDistrictBeingViewed(null);
    this.cancelEmulateDistrict().subscribe(() => {});
    localStorage.removeItem(this.districtStorageKey);        
  }


  updateDistrictFieldsByIdArray(arrayOfIds, fieldsToUpdate): Observable<District[]> {
    const updateRequest = {ids: arrayOfIds, fields: fieldsToUpdate};
    return this.httpClient.put<District[]>(this.API_URL + 'update-certain-fields-array/', JSON.stringify(updateRequest), this.headerOptions);
  }   


  saveInLocal(_district): void {
    // create expires field
    const expireMinutes = 60; // want it to expire in 60 minutes
    const expirationMS = expireMinutes * 60 * 1000; // 1000 (1 second) * 60 = 1 minute
    const record = {district: JSON.stringify(_district), expires: new Date().getTime() + expirationMS}
    localStorage.setItem(this.districtStorageKey, JSON.stringify(record));
  }  


  getFromLocal(): District {
		const record = JSON.parse(localStorage.getItem(this.districtStorageKey));
    if (!record) return null;

    // check if current time is less than the time it expires
    if (new Date().getTime() < record.expires && record.district && JSON.parse(record.district)) {
      // console.log('Still valid. Not expired.');
      // console.log('Record from object: ', JSON.parse(record.district));
      return JSON.parse(record.district);
    } else {
      return null;
    }
  }  


  checkForDistrictMode() {
    return this.districtChangedObs$;
  }

  announceDistrictMode(_district: District) {
    this.saveInLocal(_district);
    this.emulateDistrict(_district).subscribe(() => this.districtChangedObs$.next(_district));
  }
  
  emulateDistrict(_district): Observable<District> {
    let param = {};

    // cannot stringify json for this?
    if (!_district || !_district.name) {
      param = {_id: _district};
    } else {
      param = _district;
    }

    return this.httpClient.post<District>(this.API_URL + 'emulate-district', JSON.stringify(param), this.headerOptions);
  }

  cancelEmulateDistrict(): Observable<District> {
    return this.httpClient.post<District>(this.API_URL + 'cancel-emulate-district', this.headerOptions);
  }  


  createDistrict(_district: District): Observable<District> {
    return this.httpClient.post<District>(this.API_URL, JSON.stringify(_district), this.headerOptions);
  }


  updateDistrict(_district: District): Observable<District> {
    return this.httpClient.put<District>(this.API_URL + _district._id, JSON.stringify(_district), this.headerOptions);
  }


  doDistrictSearch(searchTerms): Observable<District[]> {
    return this.httpClient.post<District[]>(this.API_URL + 'search', JSON.stringify(searchTerms), this.headerOptions);
  }


  updateFieldsById(_id, fieldsToUpdate): Observable<District[]> {
    return this.httpClient.put<District[]>(this.API_URL + 'update-certain-fields/' + _id, JSON.stringify(fieldsToUpdate), this.headerOptions);
  }


  getAllDistricts(): Observable<District[]> {
    return this.httpClient.get<District[]>(this.API_URL);
  }


  getDistrictsByVendor(_vendorId: string): Observable<District[]> {
    return this.httpClient.get<District[]>(this.API_URL + 'vendor/' + _vendorId);
  }


  getDistrict(id: any): Observable<District> {
    return this.httpClient.get<District>(this.API_URL + id);
  }
  

  delete(id: string): Observable<any> {
    return this.httpClient.put(this.API_URL + 'mark-deleted/' + id, this.headerOptions);
  }


  getNextAvailableDistrictId() {
    return this.httpClient.get<Object>(this.API_URL + 'get-next-available-id');
  }


  selectAllSearch(searchTerms: District): Observable<District[]> {
    searchTerms['selectAll'] = true;
    return this.httpClient.post<District[]>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchTerms), this.headerOptions);
  }    


  getDistrictCustomDatasource(searchParams, sortField = 'date_created', sortDirection = 'desc', pageNumber: number = 0, pageSize: number = 10): Observable<DistrictApi> {
    searchParams['selectAll'] = false;

    searchParams.sortField = sortField;
    searchParams.sortOrder = sortDirection;
    searchParams.pageNumber = pageNumber;
    searchParams.pageSize = pageSize;

    return this.httpClient.post<DistrictApi>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchParams), this.headerOptions); 
  }      
  

  districtFormGroup(formData: District) {
    let _vendorId = '';
    if (formData.vendor) _vendorId = (formData.vendor._id) ? formData.vendor._id : formData.vendor;

    return this.formBuilder.group({
      _id: [formData._id],
      districtId: [formData.districtId],
      name: [formData.name],
      imageUrl: [formData.imageUrl],
      twilioPhone: [formData.twilioPhone],
      vendor: [formData.vendor],
      active: [formData.active],
      vendorId: _vendorId,
    });
  }

}
