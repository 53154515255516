import { Injectable } from '@angular/core'
import { Router, NavigationEnd } from '@angular/router'
import { Location } from '@angular/common'
import { SharedUtilsService } from './shared-utils.service'

@Injectable({ providedIn: 'root' })
export class NavigationService {
    private history: string[] = []
    private routesToIgnore: string[] = ['/admin', '/login', '/logout', '/app/load'];
    private storage_key: string = 'navigation-history';

    constructor(
        private router: Router,
        private location: Location,
        private _sharedService: SharedUtilsService
    ) { }

    startSaveHistory(): void {
        // Get the history from storage. If found set it, else set history to empty array.
        const historyInStorage: string[] = this._sharedService.getFromLocal(this.storage_key);
        this.history = (historyInStorage != undefined && historyInStorage.length) ? historyInStorage : [];

        this.router.events.subscribe((event) => {
            // Get last route to use in condition so we can make sure the same route is not added twice. Happens on profiles sometimes when using paginators.
            const lastRoute: string = (this.history != undefined && this.history.length) ? this.history[this.history.length - 1] : null;

            // Make sure url passes this validation before adding the new route to the history.
            if (event instanceof NavigationEnd && !this.routesToIgnore.includes(event.urlAfterRedirects) && !event.urlAfterRedirects.startsWith('/admin') && event.urlAfterRedirects !== lastRoute) {
                this.history.push(event.urlAfterRedirects);

                if (this.history.length > 10) this.history.shift(); // If array is too large remove oldest route from history. First one in array.
                this._sharedService.saveInLocal(this.storage_key, this.history); // Update history in storage.
            }
        });
    }


    getHistory(): string[] {
        return this.history;
    }


    goBack(): void {
        const previousRoute = this.getPreviousUrl(); // Gets second from last route if there is one. Which would be the last route we visited.

        this.history.pop(); // Remove current route from history. Which is last item in history.

        // If there is no previous route fall back to location.back.
        if (previousRoute != undefined) {
            this.router.navigateByUrl('/app/load', {skipLocationChange: true}).then(() => this.router.navigate([previousRoute]));
        } else {
            this.location.back();
        }

        this._sharedService.saveInLocal(this.storage_key, this.history); // Update history in storage.
    }


    getPreviousUrl(): string {
        if (this.history.length > 0) {
            return this.history[this.history.length - 2];
        }

        return null;
    }
}