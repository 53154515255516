import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/internal/Observable';

import { SidebarMenuInstance } from '@app/models';

@Injectable({ providedIn: 'root' })
export class SidebarMenuInstancesService {
  private currentTemplate;

  private API_URL = '/api/sidebar-menu-instances/';
  private headerOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

  constructor(
    private httpClient: HttpClient
  ) { }

  setCurrent(_template): void {
    this.currentTemplate = _template;
  }

  getCurrent() {
    return this.currentTemplate;
  }


  get(_id: string): Observable<SidebarMenuInstance> {
    return this.httpClient.get<SidebarMenuInstance>(this.API_URL + _id);
  }

  getByUser(_id: string): Observable<SidebarMenuInstance> {
    return this.httpClient.get<SidebarMenuInstance>(this.API_URL + 'by-user/' + _id);
  }

  getAll(): Observable<SidebarMenuInstance[]> {
    return this.httpClient.get<SidebarMenuInstance[]>(this.API_URL);
  }  

  create(_setting): Observable<SidebarMenuInstance> {
    return this.httpClient.post<SidebarMenuInstance>(this.API_URL, JSON.stringify(_setting), this.headerOptions);
  } 

  update(_campaign: SidebarMenuInstance): Observable<SidebarMenuInstance> {
    return this.httpClient.put<SidebarMenuInstance>(`${this.API_URL}${_campaign._id}`, JSON.stringify(_campaign), this.headerOptions);
  }  

  delete(id: string): Observable<any> {
    return this.httpClient.delete<any>(this.API_URL + 'mark-deleted/' + id);
  }  
  
  search(searchTerms): Observable<any[]> {
    searchTerms['selectAll'] = true;
    return this.httpClient.post<any[]>(this.API_URL + 'search', JSON.stringify(searchTerms), this.headerOptions);
  }

}
