import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss']
})
export class TestComponent implements OnInit {
  currentPayRate = 3640;
  payPeriod = 'bi-monthly';
  weeklyHours = 40;
  currentPayRaise = 260;

  resultsTableData = {
    differencePerc: 0,
    hourly: {
      currentPay: 0,
      newPay: 0,
      difference: 0
    },
    weekly: {
      currentPay: 0,
      newPay: 0,
      difference: 0
    },
    biWeekly: {
      currentPay: 0,
      newPay: 0,
      difference: 0
    },
    biMonthly: {
      currentPay: 0,
      newPay: 0,
      difference: 0
    },
    monthly: {
      currentPay: 0,
      newPay: 0,
      difference: 0
    },
    annually: {
      currentPay: 0,
      newPay: 0,
      difference: 0
    },
  };

  payPeriodOptions: string[] = ['hourly', 'weekly', 'bi-weekly', 'bi-monthly', 'monthly', 'annually'];

  constructor() { }

  ngOnInit(): void {
    this.calculateHourly();
  }


  calculateHourly() {
    const currentPayPeriod = this.payPeriod;
    const newPayRate = this.currentPayRate + this.currentPayRaise;

    let currentHourly = this.currentPayRate;
    let newHourly = newPayRate;

    if (currentPayPeriod === 'hourly') {
      currentHourly = this.currentPayRate;
      newHourly = newPayRate;
    } else if (currentPayPeriod === 'weekly') {
      currentHourly = this.currentPayRate / this.weeklyHours;
      newHourly = newPayRate / this.weeklyHours;
    } else if (currentPayPeriod === 'bi-weekly') {
      currentHourly = (this.currentPayRate / this.weeklyHours) / 2;
      newHourly = (newPayRate / this.weeklyHours) / 2;
    } else if (currentPayPeriod === 'bi-monthly') {
      const currentYearly = (this.currentPayRate * 2) * 12; // current pay rate is in bi monthly, so twice a month for 12 months would give annual
      const newYearly = (newPayRate * 2) * 12; // twice a month for 12 months

      currentHourly = currentYearly / (this.weeklyHours * 52);
      newHourly = newYearly / (this.weeklyHours * 52);
    } else if (currentPayPeriod === 'monthly') {
      const currentYearly = this.currentPayRate * 12; // twice a month for 12 months
      const newYearly = newPayRate * 12; // twice a month for 12 months

      currentHourly = currentYearly / (this.weeklyHours * 52);
      newHourly = newYearly / (this.weeklyHours * 52);
    } else if (currentPayPeriod === 'annually') {
      const currentYearly = this.currentPayRate; // twice a month for 12 months
      const newYearly = newPayRate; // twice a month for 12 months

      currentHourly = currentYearly / (this.weeklyHours * 52);
      newHourly = newYearly / (this.weeklyHours * 52);
    }

    // starting with yearly allows me to convert down easier
    const currentYearlyPay = currentHourly * this.weeklyHours * 52;
    const newYearlyPay = newHourly * this.weeklyHours * 52;

    this.resultsTableData = {
      differencePerc: (this.currentPayRaise / this.currentPayRate) * 100,
      hourly: {
        currentPay: currentHourly,
        newPay: newHourly,
        difference: newHourly - currentHourly
      },
      weekly: {
        currentPay: currentYearlyPay / 52,
        newPay: newYearlyPay / 52,
        difference: (newYearlyPay / 52) - (currentYearlyPay / 52)
      },
      biWeekly: {
        currentPay: currentYearlyPay / 26,
        newPay: newYearlyPay / 26,
        difference: (newYearlyPay / 26) - (currentYearlyPay / 26)
      },
      biMonthly: {
        currentPay: currentYearlyPay / 24,
        newPay: newYearlyPay / 24,
        difference: (newYearlyPay / 24) - (currentYearlyPay / 24)
      },
      monthly: {
        currentPay: currentYearlyPay / 12,
        newPay: newYearlyPay / 12,
        difference: (newYearlyPay / 12) - (currentYearlyPay / 12)
      },
      annually: {
        currentPay: currentYearlyPay,
        newPay: newYearlyPay,
        difference: newYearlyPay - currentYearlyPay
      },
    };
  }

}
