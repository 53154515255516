import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Validators, UntypedFormBuilder } from '@angular/forms';

import { Observable } from 'rxjs/internal/Observable';
import { Subject } from 'rxjs/internal/Subject';

import { EmailFolder } from '../models';

@Injectable({ providedIn: 'root' })
export class EmailFoldersService {
    private API_URL = '/api/email-folders/';
    private headerOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    private foldersObs$ = new Subject<any>();

    constructor(
        private httpClient: HttpClient,
        private formBuilder: UntypedFormBuilder
    ) { }


    get(_id: string): Observable<EmailFolder> {
        return this.httpClient.get<EmailFolder>(this.API_URL + _id);
    }


    getAllUsersFolders(_userId: string): Observable<EmailFolder[]> {
        return this.httpClient.get<EmailFolder[]>(this.API_URL + 'user/' + _userId);
    }

    listenForChanges() {
        return this.foldersObs$;
    }

    announceChanges(_settings) {
        this.foldersObs$.next(_settings);
    } 



    create(_folder): Observable<EmailFolder> {
        return this.httpClient.post<EmailFolder>(this.API_URL, JSON.stringify(_folder), this.headerOptions);
    }


    update(_folder: EmailFolder): Observable<EmailFolder> {
        return this.httpClient.put<EmailFolder>(`${this.API_URL}${_folder._id}`, JSON.stringify(_folder), this.headerOptions);
    }


    delete(_folderId: string): Observable<any> {
        return this.httpClient.delete<any>(`${this.API_URL}${_folderId}`, this.headerOptions);
    }


    folderFormGroup(formData: EmailFolder) {
        return this.formBuilder.group({
          name: [formData.name, Validators.required],
          user: [formData.user]  
        });
    }
}