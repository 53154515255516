import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UntypedFormBuilder, Validators } from '@angular/forms';

import { Observable } from 'rxjs/internal/Observable';
import { Subject } from 'rxjs/internal/Subject';

import { HrPolicy } from '../models/hrPolicy';

import { cacheable } from '../utils/rxjs-functions';
import { SharedUtilsService } from './shared-utils.service';

import { ExpiringCache } from '../models/expiringCache';

export interface HrPolicyApi {
  items: any[];
  total_count: number;
}

@Injectable({ providedIn: 'root' })
export class HrPolicyService {
  private hrPolicyObs$ = new Subject<any>();
  private currentHrPolicy;

  public currentSearch = {
    params: null,
    results: null
  };

  private API_URL = '/api/hr-policies/';

  private headerOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

  hrPoliciesStorage: ExpiringCache = new ExpiringCache();

  constructor(
    private httpClient: HttpClient,
    private _sharedUtilsService: SharedUtilsService,
    private formBuilder: UntypedFormBuilder
  ) { }


  setCurrent(_hrPolicy): void {
    this.currentHrPolicy = _hrPolicy;
  }


  getCurrent() {
    return this.currentHrPolicy;
  }


  create(_hrPolicy): Observable<any> {
    return this.httpClient.post<any>(this.API_URL, JSON.stringify(_hrPolicy), this.headerOptions);
  }


  getCached(): Observable<any[]> {
    // smarter than just going to api every time. Getting them all, so just cache for use in dropdowns all over
    if (this._sharedUtilsService.isStorageValid(this.hrPoliciesStorage)) {
      // console.log('Saved an API call for hrPolicies. Retreived from cache.');
      return this.hrPoliciesStorage.itemCache;
    }

    this.hrPoliciesStorage = this._sharedUtilsService.updateStorageExpiration(this.hrPoliciesStorage);
    // console.log('Set hrPolicies storage to: ', this.locationsStorage);
    return this.hrPoliciesStorage.itemCache = cacheable<any>(this.httpClient.get<any[]>(this.API_URL));
  }


  updateFieldsByIdArray(arrayOfIds, fieldsToUpdate): Observable<any[]> {
    const updateRequest = {ids: arrayOfIds, fields: fieldsToUpdate};
    return this.httpClient.put<any[]>(this.API_URL + 'update-certain-fields-array/', JSON.stringify(updateRequest), this.headerOptions);
  }


  updateFieldsById(_id, fieldsToUpdate): Observable<HrPolicy[]> {
    return this.httpClient.put<HrPolicy[]>(this.API_URL + 'update-certain-fields/' + _id, JSON.stringify(fieldsToUpdate), this.headerOptions);
  }


  update(_hrPolicy): Observable<any> {
    return this.httpClient.put<any>(this.API_URL + _hrPolicy._id, JSON.stringify(_hrPolicy), this.headerOptions);
  }


  updateFavorites(_hrPolicy): Observable<any> {
    return this.httpClient.put<any>(this.API_URL + 'favorite/' + _hrPolicy._id, JSON.stringify(_hrPolicy), this.headerOptions);
  }


  search(searchTerms): Observable<any[]> {
    searchTerms['selectAll'] = true;
    return this.httpClient.post<any[]>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchTerms), this.headerOptions);
  }


  getHrPolicy(_id: string): Observable<any> {
    return this.httpClient.get<any>(this.API_URL + _id);
  }


  delete(id: string): Observable<any> {
    return this.httpClient.put(this.API_URL + 'mark-deleted/' + id, this.headerOptions);
  }


  selectAllSearch(searchTerms): Observable<any[]> {
    searchTerms['selectAll'] = true;
    searchTerms['selectNumberOfRecords'] = false;
    return this.httpClient.post<any[]>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchTerms), this.headerOptions);
  }


  selectNumberOfRecordsSearch(searchParams): Observable<any> {
    searchParams['selectAll'] = false;
    searchParams['selectNumberOfRecords'] = true;
    return this.httpClient.post<any>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchParams), this.headerOptions);
  }


  getCustomDatasource(searchParams, sortField = 'name', sortDirection = 'asc', pageNumber: number = 0, pageSize: number = 10): Observable<HrPolicyApi> {
    searchParams['selectAll'] = false;
    searchParams['selectNumberOfRecords'] = false;

    searchParams.sortField = sortField;
    searchParams.sortOrder = sortDirection;
    searchParams.pageNumber = pageNumber;
    searchParams.pageSize = pageSize;

    return this.httpClient.post<HrPolicyApi>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchParams), this.headerOptions); 
  }
}