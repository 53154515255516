<div class="login-logout-wrapper">
    <div class="colored-bg-wrapper bg">
        <div class="colored-bg-container vertical-align">
            <form class="colored-bg-content full-width" novalidate>
                <div class="header-block">
                    <h2 class="formTitle">JumpCRM</h2>
                </div>
    
                <div *ngIf="errorMessage">
                    <p style="color: red;">{{ errorMessage }}</p>
                </div>
    
                <div class="main">
                    <p>You have been logged out.</p>
                </div>
    
                <form novalidate (ngSubmit)="login()" *ngIf="loginAvailable">
                    <div class="submit-wrapper">
                        <button type="submit" class="btn btn-primary btn-lg btn-block signin-btn">Login</button>
                        <!-- <button type="submit" mat-flat-button color="primary">Login</button> -->
                    </div>
                </form>            
            </form>
        </div>
    
    
        <div class="floating-logo">
            <img src="/assets/images/jh-logo.png" width="200">
        </div>
    </div>
</div>
