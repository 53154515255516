<div class="leaderboard-wrapper" [ngClass]="{'is-in-dialog': inDialog, 'is-in-profile' : isInProfile}">
    <div *ngIf="!inDialog" class="leaderboard-header">
        <div class="leaderboard-header-title">Leaderboard</div>
    </div>

    <div *ngIf="leaderboardItemsPassedIn?.length" class="leaderboard-content">
        <div class="leaderboard-switch" [ngSwitch]="leaderboardMode">
            <div class="leaderboard-case-wrapper" *ngSwitchCase="'team-leaderboard'">
                <div class="team-leaders-list">
                    <div class="team-leader-item" *ngFor="let _boardTeamItem of leaderboardItemsPassedIn; let i=index">
                        <div class="leader-item-team-info">
                            <div class="item-team-title">{{ _boardTeamItem.title }}</div>
                            <div *ngIf="!useScoreWithoutPipe" class="item-team-score">{{ _boardTeamItem?.score | number : '1.2-2' }}</div>
                            <div *ngIf="useScoreWithoutPipe" class="item-team-score">{{ _boardTeamItem?.score }}</div>
                        </div>

                        <div class="leader-item-team-members-list">
                            <ng-container *ngIf="_boardTeamItem?.members?.length">
                                <div class="team-members-list-header">
                                    <div class="rank-col"></div>
                                    <div class="title-col"></div>
                                    <div class="credits-col">Credits</div>
                                    <div class="score-col">Score</div>
                                </div>
                                <div class="team-member-item" *ngFor="let _member of _boardTeamItem.members; let j=index">
                                    <div class="rank-col member-item-rank">{{ j + 1 }}</div>
                                    <div class="title-col member-item-title" [matTooltip]="_member?.name">{{ _member?.name }}</div>
                                    <div *ngIf="_member?.credits >= 0" class="credits-col member-item-credits">{{ _member?.credits }}</div>
                                    <div *ngIf="!useScoreWithoutPipe" class="score-col member-item-score">{{ _member?.score | number : '1.2-2' }}</div>
                                    <div *ngIf="useScoreWithoutPipe" class="score-col member-item-score">{{ _member?.score }}</div>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="!_boardTeamItem?.members?.length">
                                <p class="no-members">No members on team</p>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>

            <div class="leaderboard-case-wrapper" *ngSwitchDefault>
                <div class="top-3-leaders-wrapper">
                    <div class="top-3-leaders-list">
                        <div class="top-3-leader-item" *ngFor="let _topLeader of topLeaders; let i=index">
                            <div class="top-3-item-rank-col">
                                <div class="top-3-item-rank-badge" [ngClass]="{'silver-medal': i === 1, 'bronze-medal' : i === 2}"></div>
                                <div class="top-3-item-rank">{{ _topLeader.rank }}</div>
                            </div>

                            <div class="top-3-item-data-col">
                                <div class="top-3-item-data-image-wrapper">
                                    <img *ngIf="_topLeader?.image" class="top-3-item-data-image" [src]="_topLeader.image" onerror="this.src='/assets/images/icons/icons8-test-account-50.svg';">
                                    <img *ngIf="!_topLeader?.image" class="top-3-item-data-image" src="/assets/images/icons/icons8-test-account-50.svg">
                                </div>

                                <div class="top-3-item-data-title">{{ _topLeader.title }}</div>
                                <div *ngIf="_topLeader?.credits >= 0" class="top-3-item-data-credits">Credits: {{ _topLeader?.credits }}</div>
                                <div *ngIf="!useScoreWithoutPipe" class="top-3-item-data-score">Score: {{ _topLeader?.score | number : '1.2-2' }}</div>
                                <div *ngIf="useScoreWithoutPipe" class="top-3-item-data-score">Score: {{ _topLeader?.score }}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="normal-leaders-wrapper">
                    <div class="normal-leaders-list">
                        <!-- Don't show first 3 because they are in top 3 list -->
                        <ng-container *ngFor="let _boardItem of leaderboardItemsPassedIn; let i=index">
                            <div class="normal-leader-item" *ngIf="_boardItem?.rank > 3">
                                <div class="normal-item-rank-col">{{ _boardItem.rank }}</div>

                                <div class="normal-item-data-col">
                                    <div class="data-col-left-col">
                                        <div class="normal-item-image-wrapper">
                                            <img *ngIf="_boardItem?.image" class="normal-item-image" [src]="_boardItem.image" onerror="this.src='/assets/images/icons/icons8-test-account-50.svg';">
                                            <img *ngIf="!_boardItem?.image" class="normal-item-image" src="/assets/images/icons/icons8-test-account-50.svg">
                                        </div>
                                    </div>

                                    <div class="data-col-right-col">
                                        <div class="normal-item-title give-mr">{{ _boardItem.title }}</div>
                                        <div *ngIf="_boardItem?.credits >= 0" class="normal-item-credits give-mr">Credits: {{ _boardItem?.credits }}</div>
                                        <div *ngIf="!useScoreWithoutPipe" class="normal-item-score">Score: {{ _boardItem?.score | number : '1.2-2' }}</div>
                                        <div *ngIf="useScoreWithoutPipe" class="normal-item-score">Score: {{ _boardItem?.score }}</div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="!leaderboardItemsPassedIn?.length" class="missing-leaderboard-data-content">
        <p class="no-data">No leaderboard data to view</p>
    </div>
</div>