<div class="container confirm-action-dialog">
  <h1 mat-dialog-title class="text-center">{{ title }}</h1>
  <p>{{ textToDisplay }}</p>

  <div class="row" *ngIf="items && items.length">
    <mat-list dense class="scrollable-dialog-list">
      <mat-list-item *ngFor="let item of items">{{ item.name }}</mat-list-item>
    </mat-list>
  </div>

  <div class="row" *ngIf="buttonsInfo">
    <mat-dialog-actions class="two-button-full-width-flex">
      <button *ngIf="buttonsInfo.denyText" mat-stroked-button mat-dialog-close (click)="unconfirmDelete()">{{ buttonsInfo.denyText }}</button>
      <button *ngIf="buttonsInfo.confirmText" mat-raised-button color="{{ buttonsInfo.confirmBtnColor }}" (click)="confirmDelete()">{{ buttonsInfo.confirmText }}</button>
    </mat-dialog-actions>
  </div>
</div>
