import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/internal/Observable';
import { Subject } from 'rxjs/internal/Subject';

import { GameActivity, GameInstance, User } from '@app/models';

@Injectable({ providedIn: 'root' })
export class GameActivityService {
    private API_URL = '/api/game-activities/';
    private headerOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    private currentBeingViewed;
    private newGameActivitiesObs$ = new Subject<GameActivity[]>();

    constructor(
        private httpClient: HttpClient
    ) { }


    setCurrentBeingViewed(_gameActivity: GameActivity): void {
        this.currentBeingViewed = _gameActivity;
    }


    getCurrentBeingViewed(): GameActivity {
        return this.currentBeingViewed;
    }


    listenForNewGameActivities() {
        return this.newGameActivitiesObs$;
    }


    announceNewGameActivities(_gameActivities: GameActivity[]) {
        // console.log('Change detected in GameActivities!');
        this.newGameActivitiesObs$.next(_gameActivities);
    }


    get(_id: string): Observable<GameActivity> {
        return this.httpClient.get<GameActivity>(this.API_URL + _id);
    }


    getAll(): Observable<GameActivity[]> {
        return this.httpClient.get<GameActivity[]>(this.API_URL);
    }


    create(_gameActivity): Observable<GameActivity> {
        return this.httpClient.post<GameActivity>(this.API_URL, JSON.stringify(_gameActivity), this.headerOptions);
    }


    update(_gameActivity: GameActivity): Observable<GameActivity> {
        return this.httpClient.put<GameActivity>(`${this.API_URL}/${_gameActivity._id}`, JSON.stringify(_gameActivity), this.headerOptions);
    }


    delete(id: string): Observable<GameActivity> {
        return this.httpClient.delete<GameActivity>(this.API_URL + id);
    }


    deleteByGameInstance(gameInstanceId: string): Observable<GameActivity> {
        return this.httpClient.delete<GameActivity>(this.API_URL + 'by-game-instance/' + gameInstanceId);
    }


    search(searchTerms): Observable<GameActivity[]> {
        return this.httpClient.post<GameActivity[]>(this.API_URL + 'search', JSON.stringify(searchTerms), this.headerOptions);
    }


    searchForOne(searchTerms): Observable<GameActivity> {
        return this.httpClient.post<GameActivity>(this.API_URL + 'search-for-one', JSON.stringify(searchTerms), this.headerOptions);
    }


    selectAllSearch(searchTerms: GameActivity): Observable<GameActivity[]> {
        searchTerms['selectAll'] = true;
        return this.httpClient.post<GameActivity[]>(this.API_URL + 'search', JSON.stringify(searchTerms), this.headerOptions);
    }


    buildAndCreateGameActivity(_instance: GameInstance, _user: User, _message: string): Promise<GameActivity> {
        return new Promise(async (resolve) => {
            let createdActivity: GameActivity = null;

            if ((_instance != undefined && _instance._id != undefined) && (_user != undefined && _user._id != undefined) && _message != undefined) {
                const newGameActivity = new GameActivity();
                newGameActivity.game_instance = _instance._id;
                newGameActivity.user = _user._id;
                newGameActivity.message = _message;

                const newActivity = await this.create(newGameActivity).toPromise();
                createdActivity = (newActivity != undefined) ? newActivity : null;
            }

            resolve(createdActivity);
        });
    }
}