import { Injectable } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/internal/Observable';
import { Workflow } from '../models';

export interface WorkflowApi {
    items: Workflow[];
    total_count: number;
}

@Injectable({ providedIn: 'root' })
export class WorkflowService {
    private API_URL = '/api/workflows/';
    private headerOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    private currentBeingViewed;

    public executionOccursOptions = [{label: 'When record saved', value: 'save'}, {label: 'After time elapses', value: 'time'}];
    public appliesToOptions = [{label: 'New and Updated Records', value: 'both'}, {label: 'New Record Only', value: 'new'}, {label: 'Updated Records Only', value: 'update'}];
    public processingOrderOptions = [{label: 'Alerts then Actions', value: 'alerts-first'}, {label: 'Actions then Alerts', value: 'actions-first'}];

    constructor(
        private formBuilder: UntypedFormBuilder,
        private httpClient: HttpClient
    ) { }


    setCurrentBeingViewed(_workflow: Workflow): void {
        this.currentBeingViewed = _workflow;
    }
    
    
    getCurrentBeingViewed(): Workflow {
        return this.currentBeingViewed;
    }


    get(_id: string): Observable<Workflow> {
        return this.httpClient.get<Workflow>(this.API_URL + _id);
    }


    getAll(): Observable<Workflow[]> {
        return this.httpClient.get<Workflow[]>(this.API_URL);
    }


    create(_workflow): Observable<Workflow> {
        return this.httpClient.post<Workflow>(this.API_URL, JSON.stringify(_workflow), this.headerOptions);
    }


    update(_workflow: Workflow): Observable<Workflow> {
        return this.httpClient.put<Workflow>(`${this.API_URL}/${_workflow._id}`, JSON.stringify(_workflow), this.headerOptions);
    }


    delete(id: string): Observable<Workflow> {
        return this.httpClient.delete<Workflow>(this.API_URL + id);
    }


    search(searchTerms): Observable<Workflow[]> {
        return this.httpClient.post<Workflow[]>(this.API_URL + 'search', JSON.stringify(searchTerms), this.headerOptions);
    }


    selectAllSearch(searchTerms: Workflow): Observable<Workflow[]> {
        searchTerms['selectAll'] = true;
        return this.httpClient.post<Workflow[]>(this.API_URL + 'search', JSON.stringify(searchTerms), this.headerOptions);
    }  


    getCustomDatasource(searchParams, sortField = 'name', sortDirection = 'desc', pageNumber: number = 0, pageSize: number = 10): Observable<WorkflowApi> {
        searchParams['selectAll'] = false;
    
        searchParams.sortField = sortField;
        searchParams.sortOrder = sortDirection;
        searchParams.pageNumber = pageNumber;
        searchParams.pageSize = pageSize;
    
        return this.httpClient.post<WorkflowApi>(this.API_URL + 'paged-search', JSON.stringify(searchParams), this.headerOptions); 
    }


    workflowFormGroup(formData: Workflow) {
        return this.formBuilder.group({
          name: [formData.name, Validators.required],
          module: [formData.module],
          weight: [formData.weight],
          description: [formData.description],
          executionOccurs: [formData.executionOccurs],
          appliesTo: [formData.appliesTo],
          processingOrder: [formData.processingOrder],          
          active: [formData.active]
        });
    }

}