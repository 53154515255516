import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { Subject } from 'rxjs/internal/Subject';

import { timer } from 'rxjs/internal/observable/timer';

@Component({
  selector: 'system-reset-confirmation-dialog',
  templateUrl: './system-reset-confirmation-dialog.html'
})
export class SystemResetConfirmationDialogComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  @Input() secondsUntilTimeout;
  @Input() minutesUntilReminder;

  currentTimeRemaining: number;
  countdownSubscription;

  constructor(public dialogRef: MatDialogRef<SystemResetConfirmationDialogComponent>) { }

  ngOnInit() {
    this.currentTimeRemaining = this.secondsUntilTimeout;

    // start timer right away and report back every second
    const countdownTimer = timer(0, 1000);

    this.countdownSubscription = countdownTimer.pipe(takeUntil(this.ngUnsubscribe)).subscribe(_secondCount => {
      this.currentTimeRemaining = this.secondsUntilTimeout - _secondCount;

      if (_secondCount === this.secondsUntilTimeout || _secondCount > this.secondsUntilTimeout) {
        this.countdownSubscription.unsubscribe();
        this.closeDialog(true);
      }
    });    
  }


  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }


  closeDialog(_wantsRefreshNow: boolean) {
    return this.dialogRef.close(_wantsRefreshNow);
  }
}
