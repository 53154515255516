<div class="colored-bg-wrapper bg">
  <div class="colored-bg-container">
      <div class="colored-bg-content">
          <form class="new-password-form full-width" novalidate>
              <div class="header-block">
                  <h2 class="formTitle">Enter your new password</h2>
              </div>

              <div *ngIf="errorMessage">
                  <p style="color: red;">{{ errorMessage }}</p>
              </div>    

              <div class="loading-shaded-spinner" *ngIf="isLoading">
                  <mat-spinner></mat-spinner>
              </div>

              <div *ngIf="!showRegisterForm" class="temp-login">
                  <p class="white-message">Please verify with temporary password received in email.</p>

                  <form novalidate class="inner-form" (ngSubmit)="confirmAccountRequest()" *ngIf="!isLoading">
                      <div class="flex-col">
                          <div class="form-group">
                              <label for="loginEmailInput" class="align-left">Email</label>
                              <input type="email" [(ngModel)]="user.email" name="loginEmailInput" class="form-control" id="loginEmailInput" placeholder="">
                          </div>

                          <div class="form-group">
                              <label for="loginPasswordInput" class="align-left">Temporary Password</label>
                              <input type="password" [(ngModel)]="user.password" name="loginPasswordInput" class="form-control" id="loginPasswordInput" placeholder="">
                          </div>
                      </div>

                      <div class="submit-wrapper">
                          <button type="submit" [disabled]="(!user.email || user.email === '') || (!user.password || user.password === '')"
                              class="btn btn-primary btn-lg btn-block signin-btn">Verify
                          </button>
                      </div>
                  </form>
              </div>

              <div *ngIf="showRegisterForm" class="complete-registration">
                  <p class="white-message">Enter your new password.</p>

                  <form novalidate [formGroup]="passwordForm" class="inner-form" (ngSubmit)="completeAccount()" *ngIf="!isLoading">
                      <div class="flex-col">
                          <div class="form-group">
                              <label for="register-passwordInput" class="align-left">Create Password</label>
                              <input type="password" formControlName="password" required minlength="6" maxlength="20" class="form-control">

                              <div *ngIf="password && password.invalid && (password.dirty || password.touched)">
                                  <div class="text-danger" *ngIf="password.errors?.required">Password is required</div>
                                  <div class="text-danger" *ngIf="password.errors?.minlength">Must be at least 6 characters long</div>
                                  <div class="text-danger" *ngIf="password.errors?.pattern">{{ _userService.passwordPatternDescription }}</div>
                              </div>
                          </div>

                          <div class="form-group">
                              <label for="register-passwordRepeatInput" class="align-left">Confirm Password</label>
                              <input type="password" formControlName="passwordConfirm" class="form-control">

                              <div class="text-danger" *ngIf="pwConfirm && pwConfirm.invalid && (pwConfirm.dirty || pwConfirm.touched)">
                                  <div *ngIf="pwConfirm.errors['required']">Password confirm is required</div>
                                  <div *ngIf="pwConfirm.errors['compare']">Passwords do not match</div>
                              </div>
                          </div>
                      </div>

                      <div class="submit-wrapper">
                          <button type="submit" [disabled]="passwordForm.invalid" class="btn btn-primary btn-lg btn-block signin-btn">Submit</button>
                      </div>
                  </form>
              </div>
          </form>
      </div>
  </div>

  <div class="floating-logo">
    <img src="/assets/images/yeeHRologo.png" width="200">
  </div>
</div>

