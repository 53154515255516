import { Injectable } from '@angular/core';
import { Achievement, Wallet } from '@app/models';
import { AchievementNotificationPopupData } from '@app/models/achievementNotificationPopupData';

import { AchievementService, UserRewardsRecognitionService } from '.';

import { AuthService } from './auth.service';
import { GenericModuleService } from './generic-module.service';
import { SocialConversationService } from './social-conversation.service';
import { WalletService } from './wallet.service';

@Injectable({ providedIn: 'root' })
export class AchievementDetectorService {
  constructor(
    private _achievementService: AchievementService,
    private _authService: AuthService,
    private _genericModuleService: GenericModuleService,
    private _socialConversationService: SocialConversationService,
    private _userRewardsRecognitionService: UserRewardsRecognitionService,
    private _walletService: WalletService
  ) { }


  triggerAchievementDetector(_module, _uneditedRecord, _newRecord) {
    return new Promise(async(resolve, reject) => {
      // do we store the current users achievements on the user service when they log in?
      // would prevent having to look it up every time to see if they've gotten it..

      const currentUser = this._authService.getCurrentlyLoggedInUser();
      const currentUserAwardsAndRec = await this._userRewardsRecognitionService.getCurrentUserRewardsAndRec(currentUser._id);
      const achievementNotificationsToShow: AchievementNotificationPopupData[] = []; // Any created AchievementNotificationPopupData should be pushed here to use after function finishes
      const achievementsForSocialConversations: Achievement[] = []; // Push achievements we need to create social stuff for here. 

      const currentUserAchievements = (currentUserAwardsAndRec && currentUserAwardsAndRec.achievements && currentUserAwardsAndRec.achievements.length) ? currentUserAwardsAndRec.achievements.map(_a => _a.achievement._id) : [];
      let coinsToAwardUser: number = 0;
      let coinHistoryToAddForUser: any[] = [];

      // console.log('Triggering achievement detector.');
      // console.log({_module, _uneditedRecord, _newRecord});
      // console.log('Module: ', _module);

      const usersTeams = currentUser.teams.map(_t => _t._id);
      const achievementsForModule = await this._achievementService.search({teams: usersTeams, active: true, related_module: [_module._id]}).toPromise();
      // console.log('Achievements for this module: ', achievementsForModule);

      const _actionType = (!_uneditedRecord || !_uneditedRecord._id) ? 'new' : 'update';
      let awardsNeedsUpdating = false;

      // console.log('This action type is: ', _actionType);
      // console.log('currentUserAchievements: ', currentUserAchievements);

      const achievementPromises = achievementsForModule
        .filter(_a => _a.appliesTo === 'both' || _a.appliesTo === _actionType)
        .filter(_a => !currentUserAchievements.includes(_a._id))
        .map(async(_achievement, _index) => {
            // console.log(`**** ------- ${_index} -------- ****`);
            // console.log('Acheivement through filters. Checking: ', _achievement);

            const passesConditions = await this.shouldTriggerAchievement(_module, _achievement, _uneditedRecord, _newRecord, currentUser, _actionType);

            // console.log('Achievement Name: ', _achievement.name);
            // console.log('Passes conditions: ', passesConditions);

            if (passesConditions && !currentUserAchievements.includes(_achievement._id)) {
              // console.log('PASSES acheivement checks....');
              const newAchievement = {hasTriggered: true, dateAccomplished: new Date(), achievement: _achievement._id};

              if (!currentUserAwardsAndRec.achievements || !currentUserAwardsAndRec.achievements.length) currentUserAwardsAndRec.achievements = [newAchievement];
              else currentUserAwardsAndRec.achievements.push(newAchievement);

              achievementsForSocialConversations.push(_achievement); // passesConditions was true so push achievement to create social stuff for.

              // console.log('Executing triggers...');

              // award any rewards
              let score = 0;

              if (_achievement.rewards != undefined && _achievement.rewards.length) {
                awardsNeedsUpdating = true;

                _achievement.rewards.forEach(_reward => {
                  currentUserAwardsAndRec.rewards.push({dateRewarded: new Date(), reward: {rewardType: _reward.rewardType, amount: _reward.amount, reward_source: 'Achievement'}});
                });

                const rewardsWithPoints = _achievement.rewards.filter(_reward => _reward.rewardType != undefined && _reward.rewardType === 'points' && _reward.amount != undefined && _reward.amount > 0);

                if (rewardsWithPoints != undefined && rewardsWithPoints.length) {
                  rewardsWithPoints.forEach(_r => {                  
                    currentUserAwardsAndRec.pointsHistory.push({dateApplied: new Date(), points: _r.amount, actionType: 'added', reason: _achievement.name + ' reached', source_type: 'Achievement', modifiedBy: null});

                    score += _r.amount;
                  });
                }

                // update points balance here
                currentUserAwardsAndRec.pointsBalance += score;

                // If rewards with coins, get users wallet and award coins, then update
                const rewardsWithCoins = _achievement.rewards.filter(_reward => _reward.rewardType != undefined && _reward.rewardType === 'coins' && _reward.amount != undefined && _reward.amount > 0);
                if (rewardsWithCoins != undefined && rewardsWithCoins.length) {
                  rewardsWithCoins.forEach(_r => {
                    if (_r.amount > 0) {
                      coinsToAwardUser += _r.amount;
                      coinHistoryToAddForUser.push({dateApplied: new Date(), count: _r.amount, actionType: 'added', reason: _achievement.name + ' reached', source_type: 'Achievement', modifiedBy: null});
                    }
                  });
                }
              }

              // create and push popupData to be shown after map is finished.
              const popupData = new AchievementNotificationPopupData(_achievement.name, score, _achievement.isRare, false, _achievement.badge);
              achievementNotificationsToShow.push(popupData);
            }
        });

      Promise.all(achievementPromises).then(async() => {
        if (coinsToAwardUser > 0) await this.assignCoinsToUserWallet(currentUser, coinsToAwardUser, coinHistoryToAddForUser);

        if (awardsNeedsUpdating) {
          const updatedRewardsAndRec = await this._userRewardsRecognitionService.update(currentUserAwardsAndRec).toPromise();
          this._userRewardsRecognitionService.setCurrentUserAchievements(updatedRewardsAndRec);
        }

        if (achievementsForSocialConversations.length) await this._socialConversationService.createSocialConversationsForAchievements(currentUser, achievementsForSocialConversations).toPromise();

        // If popupData was pushed to array set them and trigger function to show them.
        if (achievementNotificationsToShow.length) {
          this._achievementService.setNotificationsToShow(achievementNotificationsToShow); // Set AchievementNotificationPopupData[] on service.
          this._achievementService.showMultiAchievementNotification(); // Trigger function to show achievement notifications
        }

        resolve(null);
      });
    });
  }


  shouldTriggerAchievement(_module, _achievement, _uneditedRecord, _newRecord, currentUser, _updateType): Promise<boolean> {
    return new Promise(async(resolve, reject) => {
        // console.log('shouldTriggerAchievement - Processing Achievement: ', _achievement);

        // _updateType is 'new' 'update' or 'both'
        let _conditionCheck = false;

        // must pass all conditions in order to trigger actions/alerts
        const condition = _achievement.condition;
        // console.log('Condition to trigger this achievement: ', condition);

        if (condition) {
          const spanType = (condition.timeFrame && condition.timeFrame.spanType) ? condition.timeFrame.spanType : null;

          if (spanType) {
            // has to have all the trigger count in the span type given

            const genericSearchData = {
              name: _module.name,
              schema: _module.customSchema,
              fields: _module.fields,
              searchTerms: null
            };

            const triggerSearchTerms = {};

            let updatedOrCreatedUser = null;

            if (_module.name === "leads") updatedOrCreatedUser = currentUser._id;
            else updatedOrCreatedUser = {inclusionType: 'Include', value: currentUser._id};

            const updateKey = (_updateType === 'update') ? 'modified_by' : 'created_by';
            triggerSearchTerms[updateKey] = updatedOrCreatedUser;

            const dateSearchTerm = await this.getFormattedTimeFrame(condition.timeFrame); // Format date for backend
            // console.log('********** Date Search Terms for ach ' + _achievement.name + ': ', dateSearchTerm);

            if (dateSearchTerm) {
              if (_module.name === "leads") {
                // Needed format: searchData.dateCreatedRange, searchData.dateCreated, searchData.dateCreatedEnd
                if (_updateType === 'update') {
                  triggerSearchTerms['dateModifiedRange'] = dateSearchTerm['dateCreatedRange'];

                  if (dateSearchTerm['dateModified'] != undefined) triggerSearchTerms['dateCreated'] = dateSearchTerm['dateCreated'];
                  if (dateSearchTerm['dateModifiedEnd'] != undefined) triggerSearchTerms['dateCreatedEnd'] = dateSearchTerm['dateCreatedEnd'];
                } else {
                  triggerSearchTerms['dateCreatedRange'] = dateSearchTerm['dateCreatedRange'];

                  if (dateSearchTerm['dateCreated'] != undefined) triggerSearchTerms['dateCreated'] = dateSearchTerm['dateCreated'];
                  if (dateSearchTerm['dateCreatedEnd'] != undefined) triggerSearchTerms['dateCreatedEnd'] = dateSearchTerm['dateCreatedEnd'];
                }
              } else {
                const key = (_updateType === 'update') ? 'updatedAt' : 'createdAt';
                // console.log('dateSearchTerm when adding to generic search term: ', dateSearchTerm);
                triggerSearchTerms[key] = {inclusionType: 'Include', value: dateSearchTerm};
              }
            }

            if ((!triggerSearchTerms[condition.functionField.fieldName] || !triggerSearchTerms[condition.functionField.fieldName].value) && condition.functionField && condition.functionFieldValue) {
              // console.log('OVERWRITEING THE SEARCH VALUES...');
              triggerSearchTerms[condition.functionField.fieldName] = {inclusionType: condition.functionFieldValue.type, value: condition.functionFieldValue.value};
            }

            // console.log('Date Search Terms for ach ' + _achievement.name + ': ', dateSearchTerm);

            genericSearchData.searchTerms = triggerSearchTerms;
            // console.log('genericSearchData for ach ' + _achievement.name + ': ', genericSearchData);

            const matchingRecords = await this._genericModuleService.filteredSmartSearch(genericSearchData).toPromise();

            // console.log('Records matching trigger requirements: ', matchingRecords);
            // console.log('matchingRecords.length: ', matchingRecords.length);
            // console.log('condition: ', condition);
            // console.log('condition.functionType: ', condition.functionType);
            // console.log('condition.trigger: ', condition.trigger);

            if (condition.functionType === 'count' && matchingRecords && matchingRecords.length >= condition.trigger) {
              _conditionCheck = true;
            } else if (condition.functionType === 'sum' && matchingRecords && matchingRecords.length) {
              // total of the target field on all records
              // console.log('Get the total of the target field from all records: ');

              const fieldToCheck = (condition.functionField && condition.functionField.fieldName) ? condition.functionField.fieldName : null;

              if (fieldToCheck) {
                const sumOfAllRecords = matchingRecords
                  .map((_r) => +(_r[fieldToCheck]))
                  .reduce((previous, current) => previous + current, 0);

                if (sumOfAllRecords >= condition.trigger) _conditionCheck = true;
              }
            } else if (condition.functionType === 'avg' && matchingRecords && matchingRecords.length) {
              // avg of the target field on all records..
              const fieldToCheck = (condition.functionField && condition.functionField.fieldName) ? condition.functionField.fieldName : null;

              if (fieldToCheck) {
                const sumOfAllRecords = matchingRecords
                  .map((_r) => +(_r[fieldToCheck]))
                  .reduce((previous, current) => previous + current, 0);

                if ((sumOfAllRecords / matchingRecords.length) >= condition.trigger) _conditionCheck = true;
              }
            }
          }
        }

        resolve(_conditionCheck);
    });
  }


  // Returns formatted Date object, null, or returns missing_data if incomplete data.
  getFormattedTimeFrame(_timeFrame): Promise<any> {
    return new Promise(async(resolve, reject) => {
      // console.log('****** _timeFrame: ', _timeFrame);
      if (_timeFrame == undefined || _timeFrame.spanType == undefined) resolve('missing_data');

      const spanType = _timeFrame.spanType;
      const dateSpecifier = (_timeFrame.dateSpecifier != undefined) ? _timeFrame.dateSpecifier : null;
      const dateSpecifierEnd = (_timeFrame.dateSpecifierEnd != undefined) ? _timeFrame.dateSpecifierEnd : null;

      let spanSearchTerms = {dateCreatedRange: null, dateCreated: null, dateCreatedEnd: null};

        if (spanType === 'Day') {
          // all of the trigger counts have to have been logged today
          spanSearchTerms.dateCreatedRange = "0";
        } else if (spanType === 'Week') {
          // all of the trigger counts have to have been logged this week
          spanSearchTerms.dateCreatedRange = "this_week";
        } else if (spanType === 'Month') {
          // all of the trigger counts have to have been logged this month
          spanSearchTerms.dateCreatedRange = "this_month";
        }  else if (spanType === 'Year') {
          // all of the trigger counts have to have been logged this year
          spanSearchTerms.dateCreatedRange = "this_year";
        } else if (spanType === 'Indefinite') {
          // The triggers count for any time, so that means get all records
          spanSearchTerms = null;
        } else if (spanType === 'Between') {
          // all of the trigger counts have to have been logged between these dates
          spanSearchTerms.dateCreatedRange = "Between";
          spanSearchTerms.dateCreated = dateSpecifier;
          spanSearchTerms.dateCreatedEnd = dateSpecifierEnd;
        } else if (spanType === 'Before') {
          // all of the trigger counts have to have been logged on or before
          spanSearchTerms.dateCreatedRange = "Before";
          spanSearchTerms.dateCreated = dateSpecifier;
        } else if (spanType === 'After') {
          // all of the trigger counts have to have been logged on or after
          spanSearchTerms.dateCreatedRange = "After";
          spanSearchTerms.dateCreated = dateSpecifier;
        }

        resolve(spanSearchTerms);
    });
  }


  assignCoinsToUserWallet(_currentUser, _coinAmount: number, _coinHistory: any[]): Promise<any> {
    return new Promise(async(resolve) => {
        if (_coinHistory == undefined) _coinHistory = []; // Needs to be an array to use in map and setting to new wallet.

        const foundWallet = await this._walletService.getForUser(_currentUser._id).toPromise();
        if (foundWallet != undefined) {
          foundWallet.balance = (foundWallet.balance != undefined) ? foundWallet.balance + _coinAmount : _coinAmount;
          foundWallet.coinHistory = (foundWallet.coinHistory != undefined && foundWallet.coinHistory.length) ? foundWallet.coinHistory.concat(_coinHistory) : _coinHistory;

          await this._walletService.update(foundWallet).toPromise();
        } else {
          const _newWallet = new Wallet();
          _newWallet.user = _currentUser._id;
          _newWallet.balance = _coinAmount;
          _newWallet.coinHistory = _coinHistory;

          await this._walletService.create(_newWallet).toPromise();
        }

        resolve(null);
    });
  }
}