import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UntypedFormBuilder, Validators } from '@angular/forms';

import { Observable } from 'rxjs/internal/Observable';
import { GmailAccount } from '../models/gmailAccount';

@Injectable({ providedIn: 'root' })
export class GmailAccountService {
  private API_URL = '/api/gmail-accounts/';
  private headerOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

  constructor(
    private httpClient: HttpClient,
    private formBuilder: UntypedFormBuilder
  ) { }

  get(_id: string): Observable<GmailAccount> {
    return this.httpClient.get<GmailAccount>(this.API_URL + _id);
  }  

  getAll(): Observable<GmailAccount[]> {
    return this.httpClient.get<GmailAccount[]>(this.API_URL);
  }  

  create(_setting): Observable<GmailAccount> {
    return this.httpClient.post<GmailAccount>(this.API_URL, JSON.stringify(_setting), this.headerOptions);
  }

  createNonPasswordAccount(_setting): Observable<GmailAccount> {
    return this.httpClient.post<GmailAccount>(this.API_URL + 'no-password-account', JSON.stringify(_setting), this.headerOptions);
  }

  update(_gmailAccount: GmailAccount): Observable<GmailAccount> {
    return this.httpClient.put<GmailAccount>(this.API_URL + _gmailAccount._id, JSON.stringify(_gmailAccount), this.headerOptions);
  }  

  updateNonPassword(_gmailAccount: GmailAccount): Observable<GmailAccount> {
    return this.httpClient.put<GmailAccount>(this.API_URL + 'update-non-password/' + _gmailAccount._id, JSON.stringify(_gmailAccount), this.headerOptions);
  }

  findByUserId(_userId: string): Observable<GmailAccount> {
    return this.httpClient.get<GmailAccount>(this.API_URL + 'user-id/' + _userId);
  }  

  delete(id: string): Observable<GmailAccount> {
    return this.httpClient.delete<GmailAccount>(this.API_URL + id);
  }    

  search(searchTerms): Observable<GmailAccount[]> {
    return this.httpClient.post<GmailAccount[]>(this.API_URL + 'search', JSON.stringify(searchTerms), this.headerOptions);
  }

  submitApprovalRequest(_approvalRequest): Observable<any> {
    return this.httpClient.post<any>(this.API_URL + 'submit-approval-code', JSON.stringify(_approvalRequest), this.headerOptions);
  }

  requestAuthToken(_authRequest): Observable<GmailAccount[]> {
    return this.httpClient.post<GmailAccount[]>(this.API_URL + 'request-auth-token', JSON.stringify(_authRequest), this.headerOptions);
  }

  syncGmailAccount(_accountOptions): Observable<GmailAccount[]> {
    return this.httpClient.post<GmailAccount[]>(this.API_URL + 'sync-account-inbox', JSON.stringify(_accountOptions), this.headerOptions);
  }

  syncGmailAccount2(_userId): Observable<any[]> {
    return this.httpClient.get<any>(this.API_URL + 'sync-account/' + _userId);
  }

  sendTest(_gmailAccount: GmailAccount): Observable<GmailAccount> {
    return this.httpClient.post<GmailAccount>(`${this.API_URL}/send-test`, JSON.stringify(_gmailAccount), this.headerOptions);
  } 

  sendVerificationEmail(_gmailAccount: GmailAccount): Observable<GmailAccount> {
    return this.httpClient.post<GmailAccount>(`${this.API_URL}/send-verification`, JSON.stringify(_gmailAccount), this.headerOptions);
  } 

  gmailAccountFormGroup(formData: GmailAccount) {   
    return this.formBuilder.group({
      gmail_email_address: [formData.gmail_email_address, Validators.required],
      gmail_password: [formData.gmail_password],
      user: [formData.user],
      isDefault: [formData.isDefault],
      account_nickname: [formData.account_nickname],
    });
  }  

}
