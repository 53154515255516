import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { Observable } from 'rxjs/internal/Observable';
import { Subject } from 'rxjs/internal/Subject';

import { Socket } from 'ngx-socket-io';
import { Task } from '../models/task';

export interface TaskApi {
  items: Task[];
  total_count: number;
}

@Injectable({ providedIn: 'root' })
export class TaskService {
  private currentTaskBeingViewed: Task;

  public currentSearch = {
    params: null,
    results: null
  };

  private eventServerYeehroTaskAnnouncment$ = new Subject<Task>();
  private clearTaskNotificationObs$ = new Subject<any>();

  form: UntypedFormGroup;

  public taskStatusList = ['Open', 'In Progress', 'Completed', 'Past Due'];

  yeehroTaskEvents = this.socket.fromEvent<any>('new-yeehro-task-event');

  private API_URL = '/api/tasks/';
  private headerOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

  private taskUpdateObs$ = new Subject<Task>();

  constructor(
    private httpClient: HttpClient,
    private formBuilder: UntypedFormBuilder,
    private socket: Socket
  ) { }

  setCurrentTaskBeingViewed(_po: Task): void {
    this.currentTaskBeingViewed = _po;
  }


  getCurrentTaskBeingViewed(): Task {
    return this.currentTaskBeingViewed;
  }


  // ------- Used to listen for and announce messages from the event server -----
  announceEventServerYeehroTasks(_task: Task): void {
      // console.log('Announcing new yeehro message from message service!: ', _message);
      this.eventServerYeehroTaskAnnouncment$.next(_task);
  }

  listenForYeehroTasksFromEventServer() {
      return this.eventServerYeehroTaskAnnouncment$;
  }

  getYeehroTasksFromEventServer() {  
      this.yeehroTaskEvents.subscribe(_newTaskReceived => {
          // console.log('A new yeehro message has been announced. Is it yours? Message: ', _newMessageReceived);
          this.announceEventServerYeehroTasks(_newTaskReceived);
      });
  }
  // -----------------------------------------------------------------------------


  // -- Used for to clear task notifications ----
  listenForClearTasks() {
    return this.clearTaskNotificationObs$;
  }

  announceClearTasks() {
      // console.log('Change detected in YeehroMessage!');
      this.clearTaskNotificationObs$.next(null);
  }
  // -----------------------------------------------------------------------------


  listenForTaskUpdate() {
    return this.taskUpdateObs$;
  }

  announceTaskUpdate(task: Task) {
    this.taskUpdateObs$.next(task);
  }


  searchForOne(searchTerms): Observable<Task> {
    return this.httpClient.post<Task>(this.API_URL + 'search-for-one', JSON.stringify(searchTerms), this.headerOptions);
  }


  search(searchTerms): Observable<Task[]> {
    return this.httpClient.post<Task[]>(this.API_URL + 'search', JSON.stringify(searchTerms), this.headerOptions);
  }


  updateTaskFieldsByIdArray(arrayOfIds, fieldsToUpdate): Observable<Task[]> {
    const updateRequest = {ids: arrayOfIds, fields: fieldsToUpdate};
    return this.httpClient.put<Task[]>(this.API_URL + 'update-certain-fields-array/', JSON.stringify(updateRequest), this.headerOptions);
  }    


  updateFieldsById(_id, fieldsToUpdate): Observable<Task[]> {
    return this.httpClient.put<Task[]>(this.API_URL + 'update-certain-fields/' + _id, JSON.stringify(fieldsToUpdate), this.headerOptions);
  }     


  create(task: Task): Observable<Task> {
    return this.httpClient.post<Task>(this.API_URL, JSON.stringify(task), this.headerOptions);
  }


  getByStudentIdArray(studentIdArray): Observable<Task[]> {
    return this.httpClient.post<Task[]>(this.API_URL + 'get-by-ids', JSON.stringify(studentIdArray), this.headerOptions);
  }    


  updateTask(updatedTask: Task): Observable<Task> {
    return this.httpClient.put<Task>(this.API_URL + updatedTask._id, JSON.stringify(updatedTask), this.headerOptions);
  }


  getAll(): Observable<Task[]> {
    return this.httpClient.get<Task[]>(this.API_URL);
  }


  getUserTasks(userId: string): Observable<Task[]> {
    return this.httpClient.get<Task[]>(this.API_URL + 'user/' + userId);
  }


  // getOpenUrgentTasks(userId: string): Observable<Task[]> {
  //   return this.httpClient.get<Task[]>(this.API_URL + 'user/' + userId + '/open-urgent-tasks');
  // }


  getTask(taskId: string): Observable<Task> {
    return this.httpClient.get<Task>(this.API_URL + taskId);
  }


  getModuleTasks(moduleType: string, moduleRecordId: string): Observable<Task[]> {
    return this.httpClient.get<Task[]>(this.API_URL + 'tasks-by-module/' + moduleType + '/' + moduleRecordId);
  }


  getUserModuleTasks(moduleType: string, moduleRecordId: string, userId: string): Observable<Task[]> {
    return this.httpClient.get<Task[]>(this.API_URL + 'tasks-by-module/' + moduleType + '/' + moduleRecordId + '/user/' + userId);
  }


  assignTasksToMarkedUsers(_newTask: Task) {
    return this.httpClient.put<Task>(this.API_URL + 'assign-profile-update-tasks', JSON.stringify(_newTask), this.headerOptions);
  }


  delete(id: String): Observable<any> {
    return this.httpClient.delete(this.API_URL + id);
  }


  selectAllSearch(searchTerms): Observable<Task[]> {
    searchTerms['selectAll'] = true;
    searchTerms['selectNumberOfRecords'] = false;
    return this.httpClient.post<Task[]>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchTerms), this.headerOptions);
  }


  selectNumberOfRecordsSearch(searchParams): Observable<any> {
    searchParams['selectAll'] = false;
    searchParams['selectNumberOfRecords'] = true;
    return this.httpClient.post<any>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchParams), this.headerOptions);
  }


  getTaskCustomDatasource(searchParams, sortField = 'date_created', sortDirection = 'desc', pageNumber: number = 0, pageSize: number = 10): Observable<TaskApi> {
    searchParams['selectAll'] = false;
    searchParams['selectNumberOfRecords'] = false;

    searchParams.sortField = sortField;
    searchParams.sortOrder = sortDirection;
    searchParams.pageNumber = pageNumber;
    searchParams.pageSize = pageSize;

    return this.httpClient.post<TaskApi>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchParams), this.headerOptions); 
  }   


  taskFormGroup(formData: Task) {
    return this.formBuilder.group({
      _id: [formData._id],
      description: [formData.description, Validators.required],
      assigned_to: [formData.assigned_to, Validators.required],
      related_to_module: [formData.related_to_module],
      related_to_record_id: [formData.related_to_record_id],
      related_record_name: [formData.related_record_name],      
      created_by: [formData.created_by],
      status: [formData.status, Validators.required],
      is_urgent: [formData.is_urgent],
      is_private: [formData.is_private],
      due_date: [formData.due_date],
      date_created: [formData.date_created],
      deleted: [formData.deleted]  
    });
  }

}
