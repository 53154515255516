import { Injectable } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/internal/Observable';
import { Recording } from '../models';

@Injectable({ providedIn: 'root' })
export class RecordingService {
    private API_URL = '/api/recordings/';
    private headerOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    private currentBeingViewed;

    public templateTypes = ['Call', 'Voicemail'];

    constructor(
        private formBuilder: UntypedFormBuilder,
        private httpClient: HttpClient
    ) { }


    setCurrentBeingViewed(_recording: Recording): void {
        this.currentBeingViewed = _recording;
    }
    
    
    getCurrentBeingViewed(): Recording {
        return this.currentBeingViewed;
    }


    get(_id: string): Observable<Recording> {
        return this.httpClient.get<Recording>(this.API_URL + _id);
    }


    getAll(): Observable<Recording[]> {
        return this.httpClient.get<Recording[]>(this.API_URL);
    }


    create(_recording): Observable<Recording> {
        return this.httpClient.post<Recording>(this.API_URL, JSON.stringify(_recording), this.headerOptions);
    }


    update(_recording: Recording): Observable<Recording> {
        return this.httpClient.put<Recording>(`${this.API_URL}${_recording._id}`, JSON.stringify(_recording), this.headerOptions);
    }


    createVoicemailCall(voicemailRequest: any): Observable<any> {
        return this.httpClient.post<any>(`${this.API_URL}request-vm-call`, JSON.stringify(voicemailRequest), this.headerOptions);
    }


    delete(id: string): Observable<Recording> {
        return this.httpClient.delete<Recording>(this.API_URL + id);
    }


    deleteByIdArray(arrayOfIds): Observable<any[]> {
        const deleteRequest = {ids: arrayOfIds};
        return this.httpClient.put<any>(this.API_URL + '/delete-forever/by-array', JSON.stringify(deleteRequest), this.headerOptions);
    }


    search(searchTerms): Observable<Recording[]> {
        return this.httpClient.post<Recording[]>(this.API_URL + 'search', JSON.stringify(searchTerms), this.headerOptions);
    }


    recordingFormGroup(formData: Recording) {
        return this.formBuilder.group({
            name: [formData.name, Validators.required],
            type: [formData.type, Validators.required],
            description: [formData.description, Validators.required],
            deleted: [formData.deleted]  
        });
    }
}