import { Component, OnInit } from '@angular/core';

import { MatDialogRef } from '@angular/material/dialog';

import { Lead } from '@app/models';

@Component({
  selector: 'dup-lead-dialog',
  templateUrl: './dup-lead-dialog.component.html'
})
export class DupLeadDialogComponent implements OnInit {
  items: Lead[];
  confirmationResult = false;

  displayedColumns: string[] = ['name', 'profession', 'address'];

  constructor(public dialogRef: MatDialogRef<DupLeadDialogComponent>) { }

  ngOnInit() {}


  confirmCreate(_doMerge) {
    // this.confirmationResult = true;
    this.closeDialog(_doMerge);
  }

  cancel() {
    // this.confirmationResult = false;
    this.closeDialog(null);
  }

  closeDialog(_doMerge) {
    return this.dialogRef.close(_doMerge);
  }

}
