import { Injectable } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { CustomRelationship } from '@app/models';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class RelationshipService {
  private API_URL = '/api/relationships/';
  private headerOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

  // One to One is a relate field
  public relationshipTypes = ['One to Many', 'Many to Many'];
  private currentRelationship: CustomRelationship;

  constructor(
    private httpClient: HttpClient,
    private formBuilder: UntypedFormBuilder
  ) { }

  setCurrent(_customRelationship: CustomRelationship): void {
    this.currentRelationship = _customRelationship;
  }

  getCurrent(): CustomRelationship {
    return this.currentRelationship;
  }


  get(_id: string): Observable<CustomRelationship> {
    return this.httpClient.get<CustomRelationship>(this.API_URL + _id);
  }


  findByName(_name: string): Observable<CustomRelationship> {
    return this.httpClient.get<CustomRelationship>(this.API_URL + 'by-name/' + _name);
  }


  getAll(): Observable<CustomRelationship[]> {
    return this.httpClient.get<CustomRelationship[]>(this.API_URL);
  }


  create(_module: CustomRelationship): Observable<CustomRelationship> {
    return this.httpClient.post<CustomRelationship>(this.API_URL, JSON.stringify(_module), this.headerOptions);
  }


  createRelationship(_relationship): Observable<any> {
    return this.httpClient.post<any>(this.API_URL + 'create-relationship', JSON.stringify(_relationship), this.headerOptions);
  }


  saveRelationshipRecord(_relationship): Observable<any> {
    return this.httpClient.post<any>(this.API_URL + 'save-relationship-record', JSON.stringify(_relationship), this.headerOptions);
  }


  update(_module: CustomRelationship): Observable<CustomRelationship> {
    return this.httpClient.put<CustomRelationship>(`${this.API_URL}${_module._id}`, JSON.stringify(_module), this.headerOptions);
  }


  search(searchTerms): Observable<CustomRelationship[]> {
    return this.httpClient.post<CustomRelationship[]>(this.API_URL + 'search', JSON.stringify(searchTerms), this.headerOptions);
  }


  searchRecords(searchTerms): Observable<any[]> {
    return this.httpClient.post<any[]>(this.API_URL + 'search-records', JSON.stringify(searchTerms), this.headerOptions);
  }


  delete(id: string): Observable<any> {
    return this.httpClient.delete(this.API_URL + id);
  }


  relatePagedSearch(_moduleOptions, sortField = 'name', sortDirection = 'desc', pageNumber: number = 0, pageSize: number = 10): Observable<any> {
    _moduleOptions.searchTerms['selectAll'] = false;

    _moduleOptions.searchTerms.sortField = sortField;
    _moduleOptions.searchTerms.sortOrder = sortDirection;
    _moduleOptions.searchTerms.pageNumber = pageNumber;
    _moduleOptions.searchTerms.pageSize = pageSize; 

    return this.httpClient.post<any>(this.API_URL + 'relate-search', JSON.stringify(_moduleOptions), this.headerOptions); 
  }


  getDataSource(_moduleOptions, sortField = 'name', sortDirection = 'desc', pageNumber: number = 0, pageSize: number = 10): Observable<any> {
    _moduleOptions.searchTerms['selectAll'] = false;

    _moduleOptions.searchTerms.sortField = sortField;
    _moduleOptions.searchTerms.sortOrder = sortDirection;
    _moduleOptions.searchTerms.pageNumber = pageNumber;
    _moduleOptions.searchTerms.pageSize = pageSize; 

    return this.httpClient.post<any>(this.API_URL + 'dataSourceSearch', JSON.stringify(_moduleOptions), this.headerOptions); 
  }


  relationshipFormGroup(formData: CustomRelationship) {
    return this.formBuilder.group({
      name: [formData.name, Validators.required],
      label: [formData.label, Validators.required],
      modules_involved: [formData.modules_involved],
      primary_module: [formData.primary_module],
      related_module: [formData.related_module],
      type: [formData.type]
    });
  }
}