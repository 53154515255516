import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/internal/Observable';
import { Subject } from 'rxjs/internal/Subject';

import { GameInstance, GameSession, User } from '@app/models';

@Injectable({ providedIn: 'root' })
export class GameSessionService {
    private API_URL = '/api/game-sessions/';
    private headerOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    private currentBeingViewed;
    private newGameSessionsObs$ = new Subject<GameSession[]>();

    constructor(
        private httpClient: HttpClient
    ) { }


    setCurrentBeingViewed(_gameSession: GameSession): void {
        this.currentBeingViewed = _gameSession;
    }


    getCurrentBeingViewed(): GameSession {
        return this.currentBeingViewed;
    }


    listenForNewGameSessions() {
        return this.newGameSessionsObs$;
    }


    announceNewGameSessions(_sessions: GameSession[]) {
        // console.log('Change detected in GameSessions!');
        this.newGameSessionsObs$.next(_sessions);
    }


    get(_id: string): Observable<GameSession> {
        return this.httpClient.get<GameSession>(this.API_URL + _id);
    }


    getAll(): Observable<GameSession[]> {
        return this.httpClient.get<GameSession[]>(this.API_URL);
    }


    create(_gameSession): Observable<GameSession> {
        return this.httpClient.post<GameSession>(this.API_URL, JSON.stringify(_gameSession), this.headerOptions);
    }


    createSessionsForUsers(_gameInstance, _users: any[]): Observable<GameSession[]> {
        const creationRequest = {_game_instance: _gameInstance, _userIds: _users.map((_user) => (_user && _user._id) ? _user._id : _user)};
        return this.httpClient.post<GameSession[]>(this.API_URL + 'create-sessions-for-users', JSON.stringify(creationRequest), this.headerOptions);
    }


    removeSessionsForUsers(_gameInstance, _users: any[]): Observable<GameSession[]> {
        const removalRequest = {_game_instance: _gameInstance, _userIds: _users.map((_user) => (_user && _user._id) ? _user._id : _user)};
        return this.httpClient.post<GameSession[]>(this.API_URL + 'remove-sessions-for-users', JSON.stringify(removalRequest), this.headerOptions);
    }


    update(_gameSession: GameSession): Observable<GameSession> {
        return this.httpClient.put<GameSession>(`${this.API_URL}/${_gameSession._id}`, JSON.stringify(_gameSession), this.headerOptions);
    }


    delete(id: string): Observable<GameSession> {
        return this.httpClient.delete<GameSession>(this.API_URL + id);
    }


    deleteByGameInstance(gameInstanceId: string): Observable<GameSession> {
        return this.httpClient.delete<GameSession>(this.API_URL + 'by-game-instance/' + gameInstanceId);
    }


    search(searchTerms): Observable<GameSession[]> {
        return this.httpClient.post<GameSession[]>(this.API_URL + 'search', JSON.stringify(searchTerms), this.headerOptions);
    }


    searchForOne(searchTerms): Observable<GameSession> {
        return this.httpClient.post<GameSession>(this.API_URL + 'search-for-one', JSON.stringify(searchTerms), this.headerOptions);
    }


    selectAllSearch(searchTerms: GameSession): Observable<GameSession[]> {
        searchTerms['selectAll'] = true;
        return this.httpClient.post<GameSession[]>(this.API_URL + 'search', JSON.stringify(searchTerms), this.headerOptions);
    }


    addSessionForUsers(_instance: GameInstance, _users: User[]) {
        return new Promise(async (resolve) => {
            if ((_instance != undefined && _instance._id != undefined) && (_users != undefined && _users.length)) {
                const createdSessions = await this.createSessionsForUsers(_instance, _users).toPromise();
                this.announceNewGameSessions(createdSessions);
            }

            resolve(null);
        });
    }


    removeSessionForUsers(_instance: GameInstance, _users: User[]) {
        return new Promise(async (resolve) => {
            if ((_instance != undefined && _instance._id != undefined) && (_users != undefined && _users.length)) {
                await this.removeSessionsForUsers(_instance, _users).toPromise();
                this.announceNewGameSessions(null);
            }

            resolve(null);
        });
    }
}