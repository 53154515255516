import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  // { path: '', redirectTo: '/login', pathMatch: 'full' },
  // { path: 'login', component: LoginComponent },
  { path: '', loadChildren: () => import('./modules/public/public.module').then(m => m.PublicModule)},
  { path: 'dashboard', loadChildren: () => import('./modules/dashboard-user/dashboard-user.module').then(m => m.DashboardUserModule)},
  { path: 'app', loadChildren: () => import('./modules/main/main.module').then(m => m.MainModule)},
  { path: 'store', loadChildren: () => import('./modules/store/store.module').then(m => m.StoreModule)},
  { path: 'admin', loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule)},
  { path: 'esign', loadChildren: () => import('./modules/esign/esign.module').then(m => m.EsignModule)},
  { path: 'doc-cap', loadChildren: () => import('./modules/doc-cap/doc-cap.module').then(m => m.DocCapModule)},
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule { }
