import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { Subject } from 'rxjs/internal/Subject';
import { Observable } from 'rxjs/internal/Observable';

import { CourseEvalEntry } from '../models/course-eval-entry';

export interface CourseEvalEntryApi {
  items: CourseEvalEntry[];
  total_count: number;
}

@Injectable({ providedIn: 'root' })
export class CourseEvalEntryService {
  private currentServiceLogBeingViewed: CourseEvalEntry;

  private evalTakenObs$ = new Subject<CourseEvalEntry>();

  form: UntypedFormGroup;

  private API_URL = '/api/course-eval-entries/';
  private headerOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

  constructor(
    private httpClient: HttpClient,
    private formBuilder: UntypedFormBuilder
  ) { }

  setCurrentBeingViewed(_courseEvalEntry: CourseEvalEntry): void {
    this.currentServiceLogBeingViewed = _courseEvalEntry;
  }


  getCurrentBeingViewed(): CourseEvalEntry {
    return this.currentServiceLogBeingViewed;
  }


  listenForEntry() {
    return this.evalTakenObs$;
  }


  announceEntry(_entry: CourseEvalEntry) {
    this.evalTakenObs$.next(_entry);
  }


  getAll(): Observable<CourseEvalEntry[]> {
    return this.httpClient.get<CourseEvalEntry[]>(this.API_URL);
  }


  get(_id: string): Observable<CourseEvalEntry> {
    return this.httpClient.get<CourseEvalEntry>(this.API_URL + _id);
  }


  update(_courseEvalEntry: CourseEvalEntry): Observable<CourseEvalEntry> {
    return this.httpClient.put<CourseEvalEntry>(this.API_URL + _courseEvalEntry._id, JSON.stringify(_courseEvalEntry), this.headerOptions);
  }


  delete(id: string): Observable<any> {
    return this.httpClient.put(this.API_URL + 'mark-deleted/' + id, this.headerOptions);
  }


  deleteByIdArray(arrayOfIds): Observable<any[]> {
    const deleteRequest = {ids: arrayOfIds};
    return this.httpClient.put<any>(this.API_URL + 'mark-as-deleted/by-array', JSON.stringify(deleteRequest), this.headerOptions);
  }


  create(_courseEvalEntry: CourseEvalEntry): Observable<CourseEvalEntry> {
    return this.httpClient.post<CourseEvalEntry>(this.API_URL, JSON.stringify(_courseEvalEntry), this.headerOptions);
  }


  createCourseEvalEntryForUsers(_courseEvalEntryBuildObject): Observable<any> {
    return this.httpClient.post<any>(this.API_URL + 'createEntryForUsers', JSON.stringify(_courseEvalEntryBuildObject), this.headerOptions); 
  }


  search(searchTerms): Observable<CourseEvalEntry[]> {
    searchTerms['selectAll'] = true;
    return this.httpClient.post<CourseEvalEntry[]>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchTerms), this.headerOptions);
  }


  getCustomDatasource(searchParams, sortField = 'createdAt', sortDirection = 'asc', pageNumber: number = 0, pageSize: number = 10): Observable<CourseEvalEntryApi> {
    searchParams['selectAll'] = false;

    searchParams.sortField = sortField;
    searchParams.sortOrder = sortDirection;
    searchParams.pageNumber = pageNumber;
    searchParams.pageSize = pageSize;

    return this.httpClient.post<CourseEvalEntryApi>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchParams), this.headerOptions);
  }


  deleteForever(id: string): Observable<CourseEvalEntry> {
    return this.httpClient.delete<CourseEvalEntry>(this.API_URL + id);
  }


  courseEvalEntryFormGroup(formData: CourseEvalEntry) {
    return this.formBuilder.group({
      user: [formData.user],
      course: [formData.course],
      course_eval: [formData.course_eval, Validators.required],
      overall_score: [formData.overall_score],
      comments: [formData.comments],
      answers: [formData.answers],
      created_by: [formData.created_by],
      modified_by: [formData.modified_by],
      date_taken: [formData.date_taken],
      deleted: [formData.deleted],
      createdAt: [formData.createdAt],
      updatedAt: [formData.updatedAt]
    });
  }
}
