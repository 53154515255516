import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/internal/Observable';
import { Subject } from 'rxjs/internal/Subject';

@Injectable({ providedIn: 'root' })
export class ZipService {
  private API_URL = '/api/zips/';
  private headerOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

  constructor(
    private httpClient: HttpClient
  ) { }


  search(searchTerms): Observable<any[]> {
    return this.httpClient.post<any[]>(this.API_URL + 'advancedSearch', JSON.stringify(searchTerms), this.headerOptions);
  }


  getAll(): Observable<any[]> {
    return this.httpClient.get<any[]>(this.API_URL);
  }

  getZipDetails(zip: string): Observable<any> {
    return this.httpClient.get<any>(this.API_URL + 'by-code/' + zip);
  }

}
