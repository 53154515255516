import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/internal/Observable';
import { Subject } from 'rxjs/internal/Subject';

import { EmailSettings } from '../models/emailSettings';

@Injectable({ providedIn: 'root' })
export class EmailSettingsService {
  private API_URL = '/api/email-settings/';
  private headerOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

  private currentSettings: EmailSettings;
  private settingsObs$ = new Subject<any>();
  
  constructor(
    private httpClient: HttpClient
  ) { }


  setCurrent(_settings: EmailSettings): void {
    this.currentSettings = _settings;
  }

  getCurrent(): EmailSettings {
    return this.currentSettings;
  }

  
  listenForSettingsChange() {
    return this.settingsObs$;
  }

  announceSettingsChange(_settings) {
    this.currentSettings = _settings;
    this.settingsObs$.next(_settings);
  } 


  getSettingsForCurrentUser(_userId): Promise<EmailSettings> {
    return new Promise(async(resolve) => {
      let currentSettings = this.currentSettings;

      if (!currentSettings) {
        currentSettings = await this.getByUser(_userId).toPromise();
        this.currentSettings = currentSettings;
      }

      resolve(currentSettings);
    });
  }

  get(_id: string): Observable<EmailSettings> {
    return this.httpClient.get<EmailSettings>(this.API_URL + _id);
  }  

  getByUser(_userId: string): Observable<EmailSettings> {
    return this.httpClient.get<EmailSettings>(this.API_URL + 'by-user/' + _userId);
  }

  getAll(): Observable<EmailSettings[]> {
    return this.httpClient.get<EmailSettings[]>(this.API_URL);
  }  

  create(_setting): Observable<EmailSettings> {
    return this.httpClient.post<EmailSettings>(this.API_URL, JSON.stringify(_setting), this.headerOptions);
  } 

  update(_setting: EmailSettings): Observable<EmailSettings> {
    return this.httpClient.put<EmailSettings>(this.API_URL + _setting._id, JSON.stringify(_setting), this.headerOptions);
  }  

  delete(id: string): Observable<EmailSettings> {
    return this.httpClient.delete<EmailSettings>(this.API_URL + id);
  }    

  search(searchTerms): Observable<EmailSettings[]> {
    return this.httpClient.post<EmailSettings[]>(this.API_URL + 'search', JSON.stringify(searchTerms), this.headerOptions);
  }

}
