import { Injectable } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/internal/Observable';
import { GameAlert } from '@app/models';

@Injectable({ providedIn: 'root' })
export class GameAlertService {
    private API_URL = '/api/game-alerts/';
    private headerOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    private currentBeingViewed;

    public alertTypes: any[] = [
        {label: 'Invite', value: 'invite'},
        {label: 'Email', value: 'email'}
    ];

    public sourceTypes: any[] = [
        {label: 'Normal', value: 'normal'},
        {label: 'Customized', value: 'template'}
    ];


    constructor(
        private formBuilder: UntypedFormBuilder,
        private httpClient: HttpClient
    ) { }


    setCurrentBeingViewed(_gameAlert: GameAlert): void {
        this.currentBeingViewed = _gameAlert;
    }


    getCurrentBeingViewed(): GameAlert {
        return this.currentBeingViewed;
    }


    get(_id: string): Observable<GameAlert> {
        return this.httpClient.get<GameAlert>(this.API_URL + _id);
    }


    getAll(): Observable<GameAlert[]> {
        return this.httpClient.get<GameAlert[]>(this.API_URL);
    }


    create(_gameAlert): Observable<GameAlert> {
        return this.httpClient.post<GameAlert>(this.API_URL, JSON.stringify(_gameAlert), this.headerOptions);
    }


    update(_gameAlert: GameAlert): Observable<GameAlert> {
        return this.httpClient.put<GameAlert>(`${this.API_URL}/${_gameAlert._id}`, JSON.stringify(_gameAlert), this.headerOptions);
    }


    delete(id: string): Observable<GameAlert> {
        return this.httpClient.delete<GameAlert>(this.API_URL + id);
    }


    deleteByGame(gameId: string): Observable<GameAlert> {
        return this.httpClient.delete<GameAlert>(this.API_URL + 'by-game/' + gameId);
    }


    search(searchTerms): Observable<GameAlert[]> {
        return this.httpClient.post<GameAlert[]>(this.API_URL + 'search', JSON.stringify(searchTerms), this.headerOptions);
    }


    selectAllSearch(searchTerms: GameAlert): Observable<GameAlert[]> {
        searchTerms['selectAll'] = true;
        return this.httpClient.post<GameAlert[]>(this.API_URL + 'search', JSON.stringify(searchTerms), this.headerOptions);
    }


    gameAlertFormGroup(formData: GameAlert) {
        return this.formBuilder.group({
            game: [formData.game, Validators.required],
            name: [formData.name],
            alertType: [formData.alertType, Validators.required],
            sourceType: [formData.sourceType, Validators.required],
            subject: [formData.subject],
            message: [formData.message],
            campaignTemplate: [formData.campaignTemplate],
            variable_module: [formData.variable_module],
            variable_field: [formData.variable_field],
            variable: [formData.variable]
        });
    }
}