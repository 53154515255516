import { GamePlayerState } from "./gamePlayerState";
import { GameTeamItem } from "./gameTeamItem";

export class GameInstance {
    _id: string;
    game: any;
    start_date: Date;
    end_date: Date;
    users: any;
    current_state: GamePlayerState[]; // Array of objects for each player in the game. Holds goal progress, and the user.
    results: any;
    date_completed: Date;
    show_player_animations: boolean;
    game_teams: GameTeamItem[];
    has_paid_out: boolean;
    active: boolean;
    deleted: boolean;
    createdAt: Date;
    updatedAt: Date;

    // Virtual fields.
    game_sessions: any;
    game_activities: any;

    // Used for fake instance which is built in game-displayer and allows free play for arcade games.
    allow_free_play: boolean;

    // For filters
    dateCreatedRange: any; 
    dateCreated;
    dateCreatedEnd;
    hasSearchTerms;

    // Special Search Terms
    start_range: any;
    start_date_finish;
    end_range: any;
    end_date_finish;
    hasStarted: boolean;
    hasEnded: boolean;
    date_completed_set: boolean;
}