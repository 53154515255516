import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { MatSnackBar } from '@angular/material/snack-bar';

import { MintNotificationComponent } from '@app/modules/notifications/mint-notification/mint-notification.component';
import { compareValidator } from '@app/directives/compare-validator.directive';

import { User } from '@app/models/user';
import { AuthService, SharedUtilsService, UserService } from '@app/services';

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.scss']
})
export class NewPasswordComponent implements OnInit {
  user: User = new User();

  errorMessage: string = '';
  showRegisterForm: Boolean = false;
  passwordConf = '';
  passwordForm: UntypedFormGroup;
  isLoading: boolean = false;

  constructor(
    private router: Router,
    private fb: UntypedFormBuilder,
    private _authService: AuthService,
    private _sharedUtilsService: SharedUtilsService,
    public _userService: UserService,
    public snackBar: MatSnackBar
  ) { }


  ngOnInit() {
    this.createForm();
  }  


  createForm() {
    this.passwordForm = this.fb.group({
      password: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(20), Validators.pattern(this._userService.passwordPattern)]],
      passwordConfirm: ['', [Validators.required, compareValidator('password')]]
    });
  }  


  async confirmAccountRequest() {
    this.isLoading = true;
    const tempLoginAttempt = await this._userService.tempLogin(this.user).toPromise();
    if (tempLoginAttempt['status'] === true) {
      this.user = tempLoginAttempt['user'];
      this.user.password = '';
      this.errorMessage = '';
      this.showRegisterForm = true;
      this.isLoading = false;
    } else {
      this.user = new User();
      this.isLoading = false;

      if (tempLoginAttempt['user'] && tempLoginAttempt['status'] === false) this.errorMessage = 'Temporary Password was not correct';
      else if (tempLoginAttempt['error']) this.errorMessage = tempLoginAttempt['error'];
      else this.errorMessage = 'Email or temporary password was incorrect';
      this._sharedUtilsService.showErrorDialog(this.errorMessage);
    }
  }


  async completeAccount() {
    this.isLoading = true;
    this.user.password = this.passwordForm.value.password;

    const registration = await this._userService.completeRegistration(this.user).toPromise();

    // console.log('registration: ', registration);

    if (registration && registration !== undefined) {
      this.isLoading = false;
      this.showDialog();

      this.login();
      this._authService.announceLogin(this.user);

      // this.router.navigate(['/login']);
    } else {
      this.isLoading = false;
      this._sharedUtilsService.showErrorDialog('There was an issue setting new password. Please try again later. Or contact us if this issue persists.');
    }
  }


  async login() {
    this.isLoading = true;

    const userLoginAttempt = await this._userService.login(this.user).toPromise();

    if (userLoginAttempt.user && userLoginAttempt.status !== false) {
      this._authService.setToken(userLoginAttempt.token);
      this._authService.setUserIdle(); 

      this._sharedUtilsService.previousRoute = '';

      const userToSave = userLoginAttempt.user;

      if (userLoginAttempt.teams) {
        userToSave.teams = userLoginAttempt.teams;
      }

      this._authService.setCurrentlyLoggedInUser(userToSave);

      this.sendToPortal(userLoginAttempt);
    } else {
      this.errorMessage = userLoginAttempt['error'];
      this.isLoading = false;
    }
  }


  setEmailInStorage() {
    localStorage.setItem('login_email', this.user.email);
  }


  sendToPortal(userLoginAttempt) {
    this.snackBar.openFromComponent(MintNotificationComponent, {
      duration: 1500,
      horizontalPosition: 'start',
      data: {
        class: 'success',
        message: 'Welcome ' + userLoginAttempt.user.first_name + '. Login Successful!'
      }
    });

    let _route = '/app/dashboard';
    if (userLoginAttempt.user.userType === 'Dashboard') _route = '/dashboard';

    this.router.navigate([_route]);
    this.isLoading = false;
  }


  showDialog() {
    this.snackBar.openFromComponent(MintNotificationComponent, {
      duration: 1000,
      horizontalPosition: 'start',
      data: {
        class: 'success',
        message: 'Password Successfully Reset. Sending to login.'
      }
    });   
  }


  get password() {
    return this.passwordForm.get('password');
  }


  get pwConfirm() {
    return this.passwordForm.get('passwordConfirm');
  }   
}  
