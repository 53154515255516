import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UntypedFormBuilder, Validators } from '@angular/forms';

import { Observable } from 'rxjs/internal/Observable';
import { AdminNote } from '../models/admin-note';

export interface AdminNoteApi {
  items: AdminNote[];
  total_count: number;
}

@Injectable({ providedIn: 'root' })
export class AdminNoteService {
  private currentAdminNoteBeingViewed: AdminNote;

  public currentSearch = {
    params: null,
    results: null
  };

  private API_URL = '/api/admin-notes/';

  headerOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

  constructor(
    private httpClient: HttpClient,
    private formBuilder: UntypedFormBuilder
  ) { }


  setCurrentBeingViewed(_course: AdminNote): void {
    this.currentAdminNoteBeingViewed = _course;
  }


  getCurrentBeingViewed(): AdminNote {
    return this.currentAdminNoteBeingViewed;
  }


  getAll(): Observable<AdminNote[]> {
    return this.httpClient.get<AdminNote[]>(this.API_URL);
  }


  getById(id: string): Observable<AdminNote> {
    return this.httpClient.get<AdminNote>(this.API_URL + id);
  }


  update(course: AdminNote): Observable<AdminNote> {
    return this.httpClient.put<AdminNote>(this.API_URL + course._id, JSON.stringify(course), this.headerOptions);
  }


  create(course: AdminNote): Observable<AdminNote> {
    return this.httpClient.post<AdminNote>(this.API_URL, JSON.stringify(course), this.headerOptions);
  }


  search(searchTerms): Observable<any[]> {
    return this.httpClient.post<any[]>(this.API_URL + 'search', JSON.stringify(searchTerms), this.headerOptions);
  }


  doSearch(searchTerms): Observable<AdminNote[]> {
    searchTerms['selectAll'] = true;
    return this.httpClient.post<AdminNote[]>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchTerms), this.headerOptions);
  }


  getCustomDatasource(searchParams, sortField = 'title', sortDirection = 'asc', pageNumber: number = 0, pageSize: number = 10): Observable<AdminNoteApi> {
    searchParams['selectAll'] = false;

    searchParams.sortField = sortField;
    searchParams.sortOrder = sortDirection;
    searchParams.pageNumber = pageNumber;
    searchParams.pageSize = pageSize;

    return this.httpClient.post<AdminNoteApi>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchParams), this.headerOptions); 
  }


  markDeletedByIdArray(arrayOfIds): Observable<AdminNote[]> {
    const updateRequest = {ids: arrayOfIds};
    return this.httpClient.put<AdminNote[]>(this.API_URL + 'custom/mark-deleted/by-array', JSON.stringify(updateRequest), this.headerOptions);
  }


  delete(id: string): Observable<any> {
    return this.httpClient.put(this.API_URL + 'mark-deleted/' + id, this.headerOptions);
  }


  deleteByIdArray(arrayOfIds): Observable<any[]> {
    const deleteRequest = {ids: arrayOfIds};
    return this.httpClient.put<any>(this.API_URL + 'mark-as-deleted/by-array', JSON.stringify(deleteRequest), this.headerOptions);
  }


  adminNoteFormGroup(formData: AdminNote) {   
    return this.formBuilder.group({
      name: [formData.name, Validators.required],
      note: [formData.note, Validators.required],
      related_module: [formData.related_module, Validators.required],
      deleted: [formData.deleted]
    });
  }
}