import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/internal/Observable';
import { Subject } from 'rxjs/internal/Subject';
import { StoreItem } from '@app/models';

export interface StoreItemApi {
    items: any[];
    total_count: number;
}

@Injectable({ providedIn: 'root' })
export class StoreItemService {
    private currentStoreItem;
    private storeItemChanged$ = new Subject<StoreItem>();

    private API_URL = '/api/store-items/';
    private headerOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    constructor(
        private httpClient: HttpClient
    ) { }

    setCurrent(_storeItem): void {
        this.currentStoreItem = _storeItem;
    }


    getCurrent() {
        return this.currentStoreItem;
    }


    listenForStoreItemChange() {
        return this.storeItemChanged$;
    }


    announceStoreItemChange(_storeItem: StoreItem) {
        this.storeItemChanged$.next(_storeItem);
    }


    getAll(): Observable<StoreItem[]> {
        return this.httpClient.get<StoreItem[]>(this.API_URL);
    }


    get(_id: string): Observable<StoreItem> {
        return this.httpClient.get<StoreItem>(this.API_URL + _id);
    }


    getByName(_itemName: string): Observable<StoreItem> {
        return this.httpClient.get<StoreItem>(this.API_URL + 'byName/' + _itemName);
    }


    getByItemId(_itemId: string): Observable<StoreItem> {
        return this.httpClient.get<StoreItem>(this.API_URL + 'byItemId/' + _itemId);
    }


    getNextAvailableId(): Observable<Object> {
        return this.httpClient.get<Object>(this.API_URL + 'get-next-available/itemId');
    }


    create(_setting): Observable<StoreItem> {
        return this.httpClient.post<StoreItem>(this.API_URL, JSON.stringify(_setting), this.headerOptions);
    }


    search(searchTerms): Observable<StoreItem[]> {
        return this.httpClient.post<StoreItem[]>(this.API_URL + 'search', JSON.stringify(searchTerms), this.headerOptions);
    }


    searchWithLimitAndSort(searchTerms, sortField = 'name', sortDirection = 'desc', pageSize: number = 10): Observable<StoreItem[]> {
        searchTerms.sortField = sortField;
        searchTerms.sortOrder = sortDirection;
        searchTerms.pageSize = pageSize;

        return this.httpClient.post<StoreItem[]>(this.API_URL + 'search-with-limit-and-sort', JSON.stringify(searchTerms), this.headerOptions);
    }


    selectAllSearch(searchParams): Observable<StoreItemApi> {
        searchParams['selectAll'] = true;
        return this.httpClient.post<StoreItemApi>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchParams), this.headerOptions);
    }


    getCustomDatasource(searchParams, sortField = 'name', sortDirection = 'desc', pageNumber: number = 0, pageSize: number = 20): Observable<StoreItemApi> {
        searchParams['selectAll'] = false;

        searchParams.sortField = sortField;
        searchParams.sortOrder = sortDirection;
        searchParams.pageNumber = pageNumber;
        searchParams.pageSize = pageSize;

        return this.httpClient.post<StoreItemApi>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchParams), this.headerOptions);
    }


    update(_storeItem: StoreItem): Observable<StoreItem> {
        return this.httpClient.put<StoreItem>(`${this.API_URL}${_storeItem._id}`, JSON.stringify(_storeItem), this.headerOptions);
    }


    deleteByIdArray(arrayOfIds): Observable<any[]> {
        const deleteRequest = {ids: arrayOfIds};
        return this.httpClient.put<any>(this.API_URL + 'delete-forever/by-array', JSON.stringify(deleteRequest), this.headerOptions);
    }


    delete(_id: string): Observable<StoreItem> {
        return this.httpClient.delete<StoreItem>(this.API_URL + _id);
    }
}