import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/internal/Observable';
import { Client } from '../models/client';
import { Subject } from 'rxjs/internal/Subject';

export interface ClientApi {
  items: any[];
  total_count: number;
}

@Injectable({ providedIn: 'root' })
export class ClientService {
  private clientEnabled;
  private currentClient;
  private clientUpdateObs$ = new Subject<Client>();

  private API_URL = '/api/clients/';
  private headerOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

  constructor(
    private httpClient: HttpClient
  ) { }

  setCurrent(_client): void {
    this.currentClient = _client;
  }


  getCurrent() {
    return this.currentClient;
  }


  listenForClientModeChange() {
    return this.clientUpdateObs$;
  }


  announceClientModeChange(_client: Client) {
    this.clientEnabled = _client;
    this.clientUpdateObs$.next(_client);
  }


  setClientMode(_client): void {
    this.clientEnabled = _client;
    this.announceClientModeChange(_client);
  }


  getClientMode() {
    return this.clientEnabled;
  }


  get(_id: string): Observable<Client> {
    return this.httpClient.get<Client>(this.API_URL + _id);
  }


  getAll(): Observable<Client[]> {
    return this.httpClient.get<Client[]>(this.API_URL);
  }


  getCurrentClient(): Observable<Client> {
    return this.httpClient.get<Client>(this.API_URL + 'get-current-client');
  }


  getCurrentOrEduClient(): Observable<Client> {
    return this.httpClient.get<Client>(this.API_URL + 'get-current-client-or-edu-client');
  }


  clientMode(_client): Observable<Client> {
    this.setClientMode(_client);
    return this.httpClient.post<Client>(this.API_URL + 'enable-client-mode', JSON.stringify(_client), this.headerOptions);
  }


  exitClientMode(_client): Observable<Client> {
    this.setClientMode(null);
    return this.httpClient.post<Client>(this.API_URL + 'disable-client-mode', null, this.headerOptions);
  }


  create(_setting): Observable<Client> {
    return this.httpClient.post<Client>(this.API_URL, JSON.stringify(_setting), this.headerOptions);
  }


  update(_client: Client): Observable<Client> {
    return this.httpClient.put<Client>(`${this.API_URL}${_client._id}`, JSON.stringify(_client), this.headerOptions);
  }


  delete(id: string): Observable<any> {
    return this.httpClient.delete<any>(this.API_URL + 'mark-deleted/' + id);
  }


  selectAllSearch(searchTerms): Observable<any[]> {
    searchTerms['selectAll'] = true;
    return this.httpClient.post<any[]>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchTerms), this.headerOptions);
  }


  getCustomDatasource(searchParams, sortField = 'name', sortDirection = 'asc', pageNumber: number = 0, pageSize: number = 10): Observable<ClientApi> {
    searchParams['selectAll'] = false;

    searchParams.sortField = sortField;
    searchParams.sortOrder = sortDirection;
    searchParams.pageNumber = pageNumber;
    searchParams.pageSize = pageSize;

    return this.httpClient.post<ClientApi>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchParams), this.headerOptions); 
  }
}
