import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CKEditorModule } from 'ng2-ckeditor';

import { AngularMaterialModule } from '@app/modules/angular-material/angular-material.module';
import { FilePickerModule } from '../file-picker/file-picker.module';
import { TemplatePickerDialogModule } from '../template-picker-dialog/template-picker-dialog.module';

import { EmailAccountPickerComponent } from './email-account-picker/email-account-picker.component';
import { GmailFormComponent } from './gmail-form/gmail-form.component';
import { GmailAuthDialogComponent } from './gmail-auth-dialog/gmail-auth-dialog.component';
import { GmailAuthCodeComponent } from './gmail-auth-code/gmail-auth-code.component';
import { GmailMessageFormComponent } from './gmail-message-form/gmail-message-form.component';
import { ManageAccountsComponent } from './manage-accounts/manage-accounts.component';
import { AttachmentsDialogComponent } from './attachments-dialog/attachments-dialog.component';
import { AttachmentsBottomSheetComponent } from './attachments-bottom-sheet/attachments-bottom-sheet.component';
import { SignatureMenuComponent } from './signature-menu/signature-menu.component';
import { TemplatePickerMenuComponent } from './template-picker-menu/template-picker-menu.component';
import { EmailSettingsComponent } from './email-settings/email-settings.component';
import { GeneralSettingsComponent } from './general-settings/general-settings.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    CKEditorModule,
    FilePickerModule,
    ReactiveFormsModule, 
    AngularMaterialModule,
    TemplatePickerDialogModule,
  ],
  declarations: [
    EmailAccountPickerComponent, 
    GmailFormComponent, 
    GmailAuthDialogComponent, 
    GmailAuthCodeComponent, 
    GmailMessageFormComponent, 
    ManageAccountsComponent, 
    AttachmentsDialogComponent, 
    AttachmentsBottomSheetComponent, 
    SignatureMenuComponent, 
    TemplatePickerMenuComponent,
    EmailSettingsComponent,
    GeneralSettingsComponent
  ],
  exports: [
    EmailAccountPickerComponent,
    GmailFormComponent,
    GmailAuthDialogComponent,
    GmailAuthCodeComponent,
    GmailMessageFormComponent,
    ManageAccountsComponent,
    EmailSettingsComponent,
    GeneralSettingsComponent
  ],
})
export class GmailModule { }
