import { Injectable } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/internal/Observable';
import { AchievementCondition } from '@app/models';

@Injectable({ providedIn: 'root' })
export class AchievementConditionService {
    private API_URL = '/api/achievement-conditions/';
    private headerOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    private currentBeingViewed;

    public functionTypeOptions = [
        {label: 'Count', value: 'count'},
        {label: 'Sum', value: 'sum'},
        {label: 'Average', value: 'avg'}
    ];

    public spanTypeList = [
        {label: 'Day', value: 'Day'},
        {label: 'Week', value: 'Week'},
        {label: 'Month', value: 'Month'},
        {label: 'Year', value: 'Year'},
        {label: 'Indefinite', value: 'Indefinite'},
        {label: 'Between', value: 'Between'},
        {label: 'On or Before', value: 'Before'},
        {label: 'On or After', value: 'After'}
    ];

    public spanTypeDatePickerFieldsList = ['Before', 'After', 'Between'];

    constructor(
        private formBuilder: UntypedFormBuilder,
        private httpClient: HttpClient
    ) { }


    setCurrentBeingViewed(_achievementCondition: AchievementCondition): void {
        this.currentBeingViewed = _achievementCondition;
    }


    getCurrentBeingViewed(): AchievementCondition {
        return this.currentBeingViewed;
    }


    get(_id: string): Observable<AchievementCondition> {
        return this.httpClient.get<AchievementCondition>(this.API_URL + _id);
    }


    getAll(): Observable<AchievementCondition[]> {
        return this.httpClient.get<AchievementCondition[]>(this.API_URL);
    }


    create(_achievementCondition): Observable<AchievementCondition> {
        return this.httpClient.post<AchievementCondition>(this.API_URL, JSON.stringify(_achievementCondition), this.headerOptions);
    }


    update(_achievementCondition: AchievementCondition): Observable<AchievementCondition> {
        return this.httpClient.put<AchievementCondition>(`${this.API_URL}/${_achievementCondition._id}`, JSON.stringify(_achievementCondition), this.headerOptions);
    }


    delete(id: string): Observable<AchievementCondition> {
        return this.httpClient.delete<AchievementCondition>(this.API_URL + id);
    }


    deleteByAchievement(achievementId: string): Observable<AchievementCondition> {
        return this.httpClient.delete<AchievementCondition>(this.API_URL + 'by-achievement/' + achievementId);
    }


    search(searchTerms): Observable<AchievementCondition[]> {
        return this.httpClient.post<AchievementCondition[]>(this.API_URL + 'search', JSON.stringify(searchTerms), this.headerOptions);
    }


    selectAllSearch(searchTerms: AchievementCondition): Observable<AchievementCondition[]> {
        searchTerms['selectAll'] = true;
        return this.httpClient.post<AchievementCondition[]>(this.API_URL + 'search', JSON.stringify(searchTerms), this.headerOptions);
    }


    achievementConditionFormGroup(formData: AchievementCondition) {
        return this.formBuilder.group({
            achievement: [formData.achievement, Validators.required]
        });
    }
}