<div class="container confirm-action-dialog">
  <div class="loading-shaded-spinner" *ngIf="!massUpdateForm || isBuildingData">
    <mat-spinner></mat-spinner>
  </div>

  <div class="mass-update-content-wrapper" *ngIf="massUpdateForm && !isBuildingData">
    <h1 mat-dialog-title>{{ updateInfo?.moduleName }} Mass Update on {{ chosenRecordCount }} Chosen Records</h1>

    <mat-dialog-content>
      <p>
        Change to new value for each desired field.
      </p>

      <form [formGroup]="massUpdateForm" class="force-poppins full-width ss-form-detail input-form">
          <div class="row massUpdate-row">
            <div class="col-md-6" *ngFor="let _field of filteredFields; let i = index;">

              <!-- <pre>Field: {{ _field | json }}</pre> -->

              <field-type-edit 
                  [fieldPassedIn]="_field" 
                  [recordPassedIn]="newRecord" 
                  [fullModule]="fullModule" 
                  [relatedFields]="null" 
                  (relatedFieldChosen)="relatedFieldChosen($event)">
              </field-type-edit> 
            
          </div>
        </div>
      </form>

      <mat-list>
        <mat-list-item *ngFor="let item of items">
          <mat-icon mat-list-icon>arrow_right</mat-icon>
          <h4 mat-line>{{ item.name }}</h4>
        </mat-list-item>
      </mat-list>

    </mat-dialog-content>

    <mat-dialog-actions class="pin-to-bottom">
      <button mat-button mat-dialog-close (click)="cancel()">Cancel</button>
      <button mat-raised-button color="primary" (click)="doMassUpdate()">Mass Update</button>
    </mat-dialog-actions>
  </div>
</div>