import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularMaterialModule } from '@app/modules/angular-material/angular-material.module';
import { PipesModule } from '@app/pipes/pipes.module';

import { LeaderboardComponent } from './leaderboard.component';

@NgModule({
  imports: [
    CommonModule,
    AngularMaterialModule,
    PipesModule
  ],
  declarations: [LeaderboardComponent],
  exports: [LeaderboardComponent]
})
export class LeaderboardModule { }