import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/internal/Observable';

@Injectable({ providedIn: 'root' })
export class GeoCodingService {
  private API_URL = '/api/geocoding/';
  private headerOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

  constructor(
    private httpClient: HttpClient
  ) { }


  getLatLngFromAddress(searchObj): Observable<any> {
    return this.httpClient.post<any>(this.API_URL + 'address-lookup', JSON.stringify(searchObj), this.headerOptions);
  }

}
