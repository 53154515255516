import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// import * as moment from 'moment-mini-ts'

import { Observable } from 'rxjs/internal/Observable';

import { CalendarEvent, User } from '@app/models';
import { CalendarGroupService } from './calendar-group.service';
import { UserService } from './user.service';
import { lastValueFrom } from 'rxjs/internal/lastValueFrom';


@Injectable({ providedIn: 'root' })
export class CalendarEventService {
  private currentCalendarEventBeingViewed: CalendarEvent;

  public colors = [
    /*
    {
      key: 'red',
      label: 'Red',
      primary: '#ad2121',
      secondary: '#FAE3E3'
    },
    */
    {
      key: 'orange',
      label: 'Orange',
      primary: '#FF6700',
      secondary: '#FF9045'
    },
    {
      key: 'yellow',
      label: 'Yellow',
      primary: '#e3bc08',
      secondary: '#FDF1BA'
    },        
    {
      key: 'green',
      label: 'Green',
      primary: '#3F7C15',
      secondary: '#74B945'
    },    
    {
      key: 'blue',
      label: 'Blue',
      primary: '#1e90ff',
      secondary: '#D1E8FF'
    },
    {
      key: 'purple',
      label: 'Purple',
      primary: '#8806CE',
      secondary: '#A849DB'
    },
    {
      key: 'gray',
      label: 'Gray',
      primary: '#757575',
      secondary: '#B3B3B3'
    }        
  ];  

  private API_URL = '/api/calendar-events/';
  headerOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

  constructor(
    private httpClient: HttpClient,
    private _calendarGroupService: CalendarGroupService,
    private _userService: UserService
  ) { }


  setCurrentCalendarEventBeingViewed(_event: CalendarEvent): void {
    this.currentCalendarEventBeingViewed = _event;
  }


  getCurrentCalendarEventBeingViewed(): CalendarEvent {
    return this.currentCalendarEventBeingViewed;
  }


  getColorByKey(colorKey) {
    let districtColor = null;

    this.colors.forEach(_color => {
      if (_color.key === colorKey) {
        districtColor = _color;
        return districtColor;
      }
    });

    return districtColor;    
  }


  showStringAsTime(timeString) {
    if (!timeString) {
      return '';
    }
    
    const timeParts = timeString.split(':');

    let hour = parseInt(timeParts[0]);
    const minutes = timeParts[1];
    let amPm = 'AM';

    if (hour >= 12) {
      amPm = 'PM';
    } 

    if (hour > 12) {
      hour -= 12;
    }

    return `${hour}:${minutes} ${amPm}`;
  }


  addRfpDueDateToCalendar(_rfpRecord): Promise<any> {
    return new Promise(async(resolve, reject) => {
      // console.log('Adding _rfpRecord due dates to calendar.');

      // console.log('Rfp Record: ', _rfpRecord);
      // console.log('Account: ', _account);

      const calEvent = new CalendarEvent();

      const rfpName = (_rfpRecord && _rfpRecord.name) ? _rfpRecord.name : '';
      calEvent.title = `${rfpName} RFP Pipeline Due`;
      calEvent.start = new Date(_rfpRecord.due_date);
      calEvent.end = new Date(_rfpRecord.due_date);
      calEvent.details = `${rfpName} is due today. ${_rfpRecord.description}`;
      calEvent.color = this.getColorByKey('blue');
      calEvent.public = true;
      calEvent.user = _rfpRecord.created_by;

      const calGroups = await lastValueFrom(this._calendarGroupService.search({title: 'RFP Proposals'}));

      if (calGroups && calGroups.length) {
        const calGroup = calGroups[0];
        calEvent.calendarGroup = calGroup._id;
      }

      await lastValueFrom(this.create(calEvent));

      resolve(null);
    });
	}


  getAll(): Observable<CalendarEvent[]> {
    return this.httpClient.get<CalendarEvent[]>(this.API_URL);
  }


  getDistrictEvents(_districtId: string): Observable<CalendarEvent[]> {
    return this.httpClient.get<CalendarEvent[]>(this.API_URL + 'events-for-district/' + _districtId);
  }


  create(activity: CalendarEvent): Observable<CalendarEvent> {
    return this.httpClient.post<CalendarEvent>(this.API_URL, JSON.stringify(activity), this.headerOptions);
  }


  update(activity: CalendarEvent): Observable<CalendarEvent> {
    return this.httpClient.put<CalendarEvent>(this.API_URL + activity._id, JSON.stringify(activity), this.headerOptions);
  }


  search(searchTerms): Observable<CalendarEvent[]> {
    return this.httpClient.post<CalendarEvent[]>(this.API_URL + 'search', JSON.stringify(searchTerms), this.headerOptions);
  }


  searchForOne(searchTerms): Observable<CalendarEvent> {
    return this.httpClient.post<CalendarEvent>(this.API_URL + 'search-for-one', JSON.stringify(searchTerms), this.headerOptions);
  }


  deleteEvent(id: String): Observable<any> {
    return this.httpClient.delete(this.API_URL + id);
  }
}