import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/internal/Observable';
import { Subject } from 'rxjs/internal/Subject';

import { Socket } from 'ngx-socket-io';
import { ConversationMessageBucket, EventServerYeehroMessageAnnouncment, YeehroMessage } from '@app/models';

export interface YeehroMessageApi {
    items: any[];
    total_count: number;
}

@Injectable({ providedIn: 'root' })
export class YeehroMessageService {
    private currentYeehroMessage;

    private clearMessageNotificationObs$ = new Subject<any>();
    private eventServerYeehroMessageAnnouncments$ = new Subject<EventServerYeehroMessageAnnouncment>();
    private newYeehroMessagesObs$ = new Subject<any>();

    private API_URL = '/api/yeehro-messages/';
    private EVENT_URL = '/events/';

    messageEvents = this.socket.fromEvent<any>('message-event');
    yeehroMessageEvents = this.socket.fromEvent<any>('new-yeehro-message-event');

    chatDialogRef = null; // Used to make sure one of these dialogs can open at one time. Set to mat dialog ref.

    private headerOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    public unreadMessages = null;

    constructor(
        private httpClient: HttpClient,
        private socket: Socket
    ) { }


    playNotificationSound() {
        const textTone: string = '/assets/sounds/message_notification_long.wav'; // Sound to be played for normal achievements.

        const audio = new Audio();
        audio.src = textTone;
        audio.volume = 0.5; // adjusting volume because it is way too loud.
        audio.load();

        const resp = audio.play();

        if (resp!== undefined) resp.then(_ => audio.play()).catch(error => console.log('Error playing audio: ', error));
    }


    setCurrent(_conversation: YeehroMessage): void {
        this.currentYeehroMessage = _conversation;
    }


    getCurrent() {
        return this.currentYeehroMessage;
    }


    // ------- Used to listen for and announce messages from the event server -----
    announceEventServerYeehroMessages(_message: EventServerYeehroMessageAnnouncment): void {
        // console.log('Announcing new yeehro message from message service!: ', _message);
        this.eventServerYeehroMessageAnnouncments$.next(_message);
    }

    listenForYeehroMessagesFromEventServer() {
        return this.eventServerYeehroMessageAnnouncments$;
    }

    getYeehroMessagesFromEventServer() {  
        this.yeehroMessageEvents.subscribe(_newMessageReceived => {
            // console.log('A new yeehro message has been announced. Is it yours? Message: ', _newMessageReceived);
            this.announceEventServerYeehroMessages(_newMessageReceived);
        });
    }
    // -----------------------------------------------------------------------------


    // -- Used for to clear yeehro message notifications ----
    listenForClearNoficiations() {
        return this.clearMessageNotificationObs$;
    }

    announceClearNotifications() {
        // console.log('Change detected in YeehroMessage!');
        this.clearMessageNotificationObs$.next(null);
    }
    // -----------------------------------------------------------------------------


    // -- Used for letting main chat feed know when to get new messages ----
    listenForNewYeehroMessage() {
        return this.newYeehroMessagesObs$;
    }

    announceNewYeehroMessage(_message: YeehroMessage[]) {
        // console.log('Change detected in YeehroMessage!');
        this.newYeehroMessagesObs$.next(_message);
    }
    // -----------------------------------------------------------------------------


    getAll(): Observable<YeehroMessage[]> {
        return this.httpClient.get<YeehroMessage[]>(this.API_URL);
    }


    get(_id: string): Observable<YeehroMessage> {
        return this.httpClient.get<YeehroMessage>(this.API_URL + _id);
    }


    sendNotification(_message) {
        return this.httpClient.post<YeehroMessage>(this.EVENT_URL + 'announce-new-yeehro-messages', JSON.stringify(_message), this.headerOptions);
    }

    createMessageWithNotification(_message: YeehroMessage) {
        return new Promise(async (resolve, reject) => {
            const _savedMessage = await this.create(_message).toPromise();

            this.sendNotification(_savedMessage).toPromise();

            resolve(_savedMessage);
        }); 
    }


    arraysAreEqual(_array1, _array2) {
        if (_array1 === _array2) return true;
        if (_array1 == undefined || _array2 == undefined) return false;
        if (_array1.length !== _array2.length) return false;

        let arraysMatch: boolean = true;
        const array1Ids = _array1.map((_user) => _user._id); // Used for includes search in second array

        // Map through second array and see if the user id is in array1Ids array
        _array2.map((_user) => {
            if (!array1Ids.includes(_user._id)) arraysMatch = false;
        });

        return arraysMatch;
    }


    create(_message: YeehroMessage): Observable<YeehroMessage> {
        return this.httpClient.post<YeehroMessage>(this.API_URL, JSON.stringify(_message), this.headerOptions);
    }


    search(searchTerms): Observable<YeehroMessage[]> {
        return this.httpClient.post<YeehroMessage[]>(this.API_URL + 'search', JSON.stringify(searchTerms), this.headerOptions);
    }


    distinctSearch(searchTerms): Observable<YeehroMessage[]> {
        return this.httpClient.post<YeehroMessage[]>(this.API_URL + 'search-distinct', JSON.stringify(searchTerms), this.headerOptions);
    }


    searchForOne(searchTerms): Observable<YeehroMessage> {
        return this.httpClient.post<YeehroMessage>(this.API_URL + 'search-for-one', JSON.stringify(searchTerms), this.headerOptions);
    }


    searchSkinny(searchTerms): Observable<YeehroMessage[]> {
        return this.httpClient.post<YeehroMessage[]>(this.API_URL + 'search-skinny', JSON.stringify(searchTerms), this.headerOptions);
    }


    selectAllSearch(searchParams): Observable<YeehroMessageApi> {
        searchParams['selectAll'] = true;
        return this.httpClient.post<YeehroMessageApi>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchParams), this.headerOptions);
    }


    getCustomDatasource(searchParams, sortField = 'name', sortDirection = 'desc', pageNumber: number = 0, pageSize: number = 10): Observable<YeehroMessageApi> {
        searchParams['selectAll'] = false;

        searchParams.sortField = sortField;
        searchParams.sortOrder = sortDirection;
        searchParams.pageNumber = pageNumber;
        searchParams.pageSize = pageSize;

        return this.httpClient.post<YeehroMessageApi>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchParams), this.headerOptions);
    }


    update(_socialConversation: YeehroMessage): Observable<YeehroMessage> {
        return this.httpClient.put<YeehroMessage>(`${this.API_URL}${_socialConversation._id}`, JSON.stringify(_socialConversation), this.headerOptions);
    }


    updateFieldsByIdArray(arrayOfIds, fieldsToUpdate): Observable<any[]> {
        const updateRequest = {ids: arrayOfIds, fields: fieldsToUpdate};
        return this.httpClient.put<any[]>(this.API_URL + 'update-certain-fields-array/', JSON.stringify(updateRequest), this.headerOptions);
    }


    markDeleted(id: string): Observable<any> {
        return this.httpClient.put(this.API_URL + 'mark-deleted/' + id, this.headerOptions);
    }


    markAsRemovedFromBarBetweenUsers(_sentTo: string, _createdBy: string, _groupUsersIds: any[] = null): Observable<any> {
        const updateRequest = { sentTo: _sentTo, createdBy: _createdBy, groupUsersIds: _groupUsersIds};
        return this.httpClient.put<any>(this.API_URL + 'mark-as-removed-from-bar/between-users', JSON.stringify(updateRequest), this.headerOptions);
    }


    markAsReadByUser(arrayOfIds, _userToAdd): Observable<any[]> {
        const updateRequest = {ids: arrayOfIds, userToAdd: (_userToAdd._id != undefined) ? _userToAdd._id : _userToAdd};
        return this.httpClient.put<any>(this.API_URL + 'mark-as-read-by/by-user', JSON.stringify(updateRequest), this.headerOptions);
    }


    markDeletedByIdArray(arrayOfIds): Observable<any[]> {
        const deleteRequest = { ids: arrayOfIds };
        return this.httpClient.put<any>(this.API_URL + 'mark-as-deleted/by-array', JSON.stringify(deleteRequest), this.headerOptions);
    }


    deleteByIdArray(arrayOfIds): Observable<any[]> {
        const deleteRequest = {ids: arrayOfIds};
        return this.httpClient.put<any>(this.API_URL + 'delete-forever/by-array', JSON.stringify(deleteRequest), this.headerOptions);
    }


    delete(_id: string): Observable<YeehroMessage> {
        return this.httpClient.delete<YeehroMessage>(this.API_URL + _id);
    }
}