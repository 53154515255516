import { Injectable } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/internal/Observable';

import { Dropdown } from '@app/models/';
import { SharedUtilsService } from './shared-utils.service';

@Injectable({
  providedIn: 'root'
})
export class DropdownService {
  private API_URL = '/api/dropdowns/';
  private headerOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};
  
  private currentDropdown: Dropdown;

  // save in local storage so we don't lose any data we may be working on
  storageKey: string = 'dropdown';

  constructor(
    private httpClient: HttpClient,
    private formBuilder: UntypedFormBuilder,
    private _sharedUtilsService: SharedUtilsService
  ) { }

  setCurrent(_dropdown: Dropdown): void {
    this.currentDropdown = _dropdown;
  }

  getCurrent(): Dropdown {
    return this.currentDropdown;
  }


  get(_id: string): Observable<Dropdown> {
    return this.httpClient.get<Dropdown>(this.API_URL + _id);
  }  


  getAll(): Observable<Dropdown[]> {
    return this.httpClient.get<Dropdown[]>(this.API_URL);
  }  


  create(_dropdown): Observable<Dropdown> {
    return this.httpClient.post<Dropdown>(this.API_URL, JSON.stringify(_dropdown), this.headerOptions);
  } 


  update(_dropdown: Dropdown): Observable<Dropdown> {
    return this.httpClient.put<Dropdown>(`${this.API_URL}/${_dropdown._id}`, JSON.stringify(_dropdown), this.headerOptions);
  }


  delete(id: string): Observable<any> {
    return this.httpClient.delete<any>(this.API_URL + id);
  }


  getByName(nameOption): Observable<Dropdown> {
    return this.httpClient.post<Dropdown>(this.API_URL + 'get-by-name', JSON.stringify(nameOption), this.headerOptions);
  }  


  search(searchTerms): Observable<Dropdown[]> {
    return this.httpClient.post<Dropdown[]>(this.API_URL + 'search', JSON.stringify(searchTerms), this.headerOptions);
  }
  
  
  dropdownFormGroup(formData: Dropdown) {   
    return this.formBuilder.group({
      name: [formData.name, Validators.required]
    });
  }   

}
