export class Wallet {
    _id: string;
    user: any;
    balance: number;
    coinHistory: any;
    createdAt: Date;
    updatedAt: Date;

    // For filters
    dateCreatedRange: any; 
    dateCreated;
    dateCreatedEnd;
    hasSearchTerms;
}