import { Injectable } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/internal/Observable';
import { WorkflowAlert } from '@app/models';

@Injectable({ providedIn: 'root' })
export class WorkflowAlertService {
    private API_URL = '/api/workflow-alerts/';
    private headerOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    private currentBeingViewed;

    public alertTypes: any[] = [
        {label: 'Invite', value: 'invite'},
        {label: 'Email', value: 'email'}
    ];

    public sourceTypes: any[] = [
        {label: 'Normal', value: 'normal'},
        {label: 'Customized', value: 'template'}
    ];


    constructor(
        private formBuilder: UntypedFormBuilder,
        private httpClient: HttpClient
    ) { }


    setCurrentBeingViewed(_workflow: WorkflowAlert): void {
        this.currentBeingViewed = _workflow;
    }
    
    
    getCurrentBeingViewed(): WorkflowAlert {
        return this.currentBeingViewed;
    }


    get(_id: string): Observable<WorkflowAlert> {
        return this.httpClient.get<WorkflowAlert>(this.API_URL + _id);
    }


    getAll(): Observable<WorkflowAlert[]> {
        return this.httpClient.get<WorkflowAlert[]>(this.API_URL);
    }


    create(_workflow): Observable<WorkflowAlert> {
        return this.httpClient.post<WorkflowAlert>(this.API_URL, JSON.stringify(_workflow), this.headerOptions);
    }


    update(_workflow: WorkflowAlert): Observable<WorkflowAlert> {
        return this.httpClient.put<WorkflowAlert>(`${this.API_URL}/${_workflow._id}`, JSON.stringify(_workflow), this.headerOptions);
    }


    delete(id: string): Observable<WorkflowAlert> {
        return this.httpClient.delete<WorkflowAlert>(this.API_URL + id);
    }


    deleteByWorkflow(workflowId: string): Observable<WorkflowAlert> {
        return this.httpClient.delete<WorkflowAlert>(this.API_URL + 'by-workflow/' + workflowId);
    }


    search(searchTerms): Observable<WorkflowAlert[]> {
        return this.httpClient.post<WorkflowAlert[]>(this.API_URL + 'search', JSON.stringify(searchTerms), this.headerOptions);
    }


    selectAllSearch(searchTerms: WorkflowAlert): Observable<WorkflowAlert[]> {
        searchTerms['selectAll'] = true;
        return this.httpClient.post<WorkflowAlert[]>(this.API_URL + 'search', JSON.stringify(searchTerms), this.headerOptions);
    }


    workflowAlertFormGroup(formData: WorkflowAlert) {
        return this.formBuilder.group({
          workflow: [formData.workflow, Validators.required],
          name: [formData.name],
          alertType: [formData.alertType, Validators.required],
          sourceType: [formData.sourceType, Validators.required],
          subject: [formData.subject],
          message: [formData.message],
          campaignTemplate: [formData.campaignTemplate],
          variable_module: [formData.variable_module],
          variable_field: [formData.variable_field],
          variable: [formData.variable]
        });
    }
}