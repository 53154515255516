import { Component, OnInit, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { timer } from 'rxjs/internal/observable/timer';

@Component({
  selector: 'app-timeout-dialog',
  templateUrl: './timeout-dialog.component.html'
})
export class TimeoutDialogComponent implements OnInit {
  @Input() secondsUntilTimeout;

  currentTimeRemaining: number;
  countdownSubscription;

  constructor(public dialogRef: MatDialogRef<TimeoutDialogComponent>) { }

  ngOnInit() {
    this.currentTimeRemaining = this.secondsUntilTimeout;

    // start timer right away and report back every second
    const countdownTimer = timer(0, 1000);

    this.countdownSubscription = countdownTimer.subscribe(_secondCount => {
      this.currentTimeRemaining = this.secondsUntilTimeout - _secondCount;

      if (_secondCount === this.secondsUntilTimeout || _secondCount > this.secondsUntilTimeout) {
        this.countdownSubscription.unsubscribe();
        this.closeDialog(false);
      }
    });
  }


  closeDialog(_continueSession: boolean) {
    return this.dialogRef.close(_continueSession);
  }
}
