<div class="colored-bg-wrapper bg">
    <div class="colored-bg-container">
        <div class="colored-bg-content">

            <form class="forgot-password-form full-width" novalidate>
                <div class="header-block">
                    <h2 class="formTitle">Password Reset</h2>
                </div>

                <div *ngIf="errorMessage">
                    <p style="color: red;">{{ errorMessage }}</p>
                </div>

                <div class="loading-shaded-spinner" *ngIf="isLoading">
                    <mat-spinner></mat-spinner>
                </div>

                <form novalidate class="inner-form" (ngSubmit)="sendEmail()" *ngIf="!isLoading">
                    <div class="flex-col">
                        <div class="form-group">
                            <label for="loginEmailInput" class="align-left">Email</label>
                            <input type="email" [(ngModel)]="user.email" name="email" class="form-control" placeholder="">
                        </div>
                    </div>

                    <div class="submit-wrapper">
                        <button type="submit" [disabled]="!user.email"
                            class="btn btn-primary btn-lg btn-block signin-btn">Submit</button>

                        <button type="button" class="btn btn-default btn-lg btn-block signin-btn"
                            (click)="backToLogin()">Back to Login</button>
                    </div>
                </form>
            </form>
        </div>
    </div>

    <div class="floating-logo">
        <img src="/assets/images/yeeHRologo.png" width="200">
    </div>
</div>