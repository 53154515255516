import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/internal/Observable';
import { Subject } from 'rxjs/internal/Subject';
import { UserDashboard } from '@app/models';

export interface UserDashboardApi {
    items: any[];
    total_count: number;
}

@Injectable({ providedIn: 'root' })
export class UserDashboardService {
    public  userDashboardsEnabled: boolean = true; // Disable if this is being worked on
    private currentUserDashboard;
    private userDashboardChanged$ = new Subject<UserDashboard>();

    private API_URL = '/api/user-dashboards/';
    private headerOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    constructor(
        private httpClient: HttpClient
    ) { }

    setCurrent(_userDashboard): void {
        this.currentUserDashboard = _userDashboard;
    }


    getCurrent() {
        return this.currentUserDashboard;
    }


    listenForUserDashboardChange() {
        return this.userDashboardChanged$;
    }


    announceUserDashboardChange(_userDashboard: UserDashboard) {
        this.userDashboardChanged$.next(_userDashboard);
    }


    getAll(): Observable<UserDashboard[]> {
        return this.httpClient.get<UserDashboard[]>(this.API_URL);
    }


    get(_id: string): Observable<UserDashboard> {
        return this.httpClient.get<UserDashboard>(this.API_URL + _id);
    }


    getUserDashboard(_userId: string): Observable<UserDashboard> {
        return this.httpClient.get<UserDashboard>(this.API_URL + 'forUser/' + _userId);
    }


    create(_setting): Observable<UserDashboard> {
        return this.httpClient.post<UserDashboard>(this.API_URL, JSON.stringify(_setting), this.headerOptions);
    }


    update(_userDashboard: UserDashboard): Observable<UserDashboard> {
        return this.httpClient.put<UserDashboard>(`${this.API_URL}${_userDashboard._id}`, JSON.stringify(_userDashboard), this.headerOptions);
    }


    deleteByIdArray(arrayOfIds): Observable<any[]> {
        const deleteRequest = {ids: arrayOfIds};
        return this.httpClient.put<any>(this.API_URL + 'delete-forever/by-array', JSON.stringify(deleteRequest), this.headerOptions);
    }


    delete(_id: string): Observable<UserDashboard> {
        return this.httpClient.delete<UserDashboard>(this.API_URL + _id);
    }


    deleteAllUserDashboardsForUser(_userId: string): Observable<UserDashboard> {
        return this.httpClient.delete<UserDashboard>(this.API_URL + 'delete-all-by-user/' + _userId);
    }
}
