import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/internal/Observable';

import { InteractiveDocumentInstance } from '../models/interactiveDocumentInstance';
import { User } from '@app/models';
import { InteractiveDocumentInstanceActionsService } from './interactive-document-instance-actions.service';

export interface InteractiveDocumentInstanceApi {
  items: InteractiveDocumentInstance[];
  total_count: number;
}

@Injectable({ providedIn: 'root' })
export class InteractiveDocumentInstanceService {
  private currentDocument;

  private API_URL = '/api/interactive-document-instances/';

  private headerOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

  constructor(
    private httpClient: HttpClient,
    private _interactiveDocumentActionService: InteractiveDocumentInstanceActionsService
  ) { }


  setCurrent(_document): void {
    this.currentDocument = _document;
  }


  getCurrent() {
    return this.currentDocument;
  }


  getNewInstance(_currentUser: User): Promise<InteractiveDocumentInstance> {
    return new Promise(async(resolve, reject) => {
      const _newInstance = new InteractiveDocumentInstance();
      _newInstance.interactive_document = null;
      _newInstance.created_by = _currentUser._id;
      _newInstance.deleted = false;
      _newInstance.completeInOrder = true;
      _newInstance.status = 'Created';

      resolve(_newInstance);
    });
  }


  requestSignatureFromRecipient(_instance, _recipient, _currentUser) {
    console.log('_recipient: ', _recipient);

    const requestorEmail = (_currentUser && _currentUser.email) ? _currentUser.email : _instance.created_by.email;
    
    return new Promise(async (resolve) => {
        await this.requestSignature({
          recipientId: _recipient.recipientId,
          docId: (_instance && _instance._id) ? _instance._id : _instance,
          requestor: (_currentUser && _currentUser.first_name) ? _currentUser.first_name + ' ' + _currentUser.last_name : _recipient.email,
          requestorEmail: requestorEmail,
          requestee: _recipient.email,
          email: _recipient.email,
          docName: _instance.name,
          notes: _instance.description
        }).toPromise();
    
        const actionDescription = 'Sent out for signature to ' + _recipient.email;
        await this._interactiveDocumentActionService.logAction('signature requested', _instance._id, actionDescription, null);

      resolve(null);
    });
  }


  create(_document): Observable<InteractiveDocumentInstance> {
    return this.httpClient.post<any>(this.API_URL, JSON.stringify(_document), this.headerOptions);
  }


  updateFieldsByIdArray(arrayOfIds, fieldsToUpdate): Observable<any[]> {
    const updateRequest = {ids: arrayOfIds, fields: fieldsToUpdate};
    return this.httpClient.put<any[]>(this.API_URL + 'update-certain-fields-array/', JSON.stringify(updateRequest), this.headerOptions);
  }


  updateFieldsById(_id, fieldsToUpdate): Observable<InteractiveDocumentInstance[]> {
    return this.httpClient.put<InteractiveDocumentInstance[]>(this.API_URL + 'update-certain-fields/' + _id, JSON.stringify(fieldsToUpdate), this.headerOptions);
  }


  update(_document): Observable<InteractiveDocumentInstance> {
    return this.httpClient.put<InteractiveDocumentInstance>(this.API_URL + _document._id, JSON.stringify(_document), this.headerOptions);
  }


  sendCopyToParticipants(_document): Observable<InteractiveDocumentInstance> {
    return this.httpClient.post<InteractiveDocumentInstance>(this.API_URL + 'send-copies', JSON.stringify(_document), this.headerOptions);
  }


  convertToDocument(_document): Observable<InteractiveDocumentInstance> {
    return this.httpClient.post<InteractiveDocumentInstance>(this.API_URL + 'convert-to-document', JSON.stringify(_document), this.headerOptions);
  }


  downloadInstances(_document): Observable<any> {
    return this.httpClient.post<any>(this.API_URL + 'download', JSON.stringify(_document), this.headerOptions);
  }


  search(searchTerms): Observable<InteractiveDocumentInstance[]> {
    searchTerms['selectAll'] = true;
    return this.httpClient.post<InteractiveDocumentInstance[]>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchTerms), this.headerOptions);
  }


  requestSignature(sigReq): Observable<any> {
    return this.httpClient.post<any>(this.API_URL + 'request-signature', JSON.stringify(sigReq), this.headerOptions);
  }


  searchAll(searchTerms): Observable<InteractiveDocumentInstance[]> {
    return this.httpClient.post<InteractiveDocumentInstance[]>(this.API_URL + 'search', JSON.stringify(searchTerms), this.headerOptions);
  }


  getInstance(_id: string): Observable<InteractiveDocumentInstance> {
    return this.httpClient.get<InteractiveDocumentInstance>(this.API_URL + _id);
  }


  delete(id: string): Observable<any> {
    return this.httpClient.put(this.API_URL + 'mark-deleted/' + id, this.headerOptions);
  }


  deleteByIdArray(arrayOfIds): Observable<any[]> {
    const deleteRequest = {ids: arrayOfIds};
    return this.httpClient.put<any>(this.API_URL + 'mark-as-deleted/by-array', JSON.stringify(deleteRequest), this.headerOptions);
  }


  getDataSource(_searchTerms, sortField = 'name', sortDirection = 'asc', pageNumber: number = 0, pageSize: number = 10): Observable<InteractiveDocumentInstanceApi> {
    _searchTerms['selectAll'] = false;
    _searchTerms.sortField = sortField;
    _searchTerms.sortOrder = sortDirection;
    _searchTerms.pageNumber = pageNumber;
    _searchTerms.pageSize = pageSize; 

    return this.httpClient.post<InteractiveDocumentInstanceApi>(this.API_URL + 'dataSourceSearch', JSON.stringify(_searchTerms), this.headerOptions); 
  }
}
