import { Component, OnInit, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { LeaderboardItem } from '@app/models';

@Component({
  selector: 'leaderboard-viewer-dialog',
  templateUrl: './leaderboard-viewer-dialog.component.html',
  styleUrls: ['./leaderboard-viewer-dialog.component.scss']
})
export class LeaderboardViewerDialogComponent implements OnInit {
  @Input() leaderboardItemsPassedIn: LeaderboardItem[] = [];
  @Input() useScoreWithoutPipe?: boolean = false;
  @Input() leaderboardMode?: string = null;

  constructor(public dialogRef: MatDialogRef<LeaderboardViewerDialogComponent>) { }

  ngOnInit() {}

  closeDialog() {
    return this.dialogRef.close();
  }
}