import { Component, OnInit } from '@angular/core';

import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-action-progress-dialog',
  templateUrl: './action-progress-dialog.component.html'
})
export class ActionProgressDialogComponent implements OnInit {
  title: string = '';

  timingStatus = {
    percentComplete: 0,
    currentPerc: 0, // current count
    fullAmount: 0 // number of records to be considered 100% done
  };

  constructor(
    public dialogRef: MatDialogRef<ActionProgressDialogComponent>
  ) { }

  ngOnInit() {
    // console.log('updateInfo: ', this.updateInfo);
  }

  cancel() {
    this.closeDialog();
  }

  closeDialog() {
    return this.dialogRef.close(null);
  }


}
