<div class="container">
  <h1 mat-dialog-title class="text-center">System Update Warning</h1>

  <p>
    Updates have been made to YeeHro. You will not see the updates until your browser is refreshed. 
    A refresh will be triggered in <b>{{ currentTimeRemaining }}</b> seconds.
    <br>
    Do you want to refresh now?
  </p>

  <div class="row">
    <mat-dialog-actions class="two-button-full-width-flex">
      <button mat-flat-button color="primary" mat-dialog-close (click)="closeDialog(true)">Yes, Refresh now</button>

      <button mat-raised-button mat-dialog-close (click)="closeDialog(false)">
        <span *ngIf="minutesUntilReminder">No, Remind me in {{ minutesUntilReminder }} {{ (minutesUntilReminder !== 1) ? 'minutes' : 'minute' }}</span>
        <span *ngIf="!minutesUntilReminder">No, Remind me later</span>
      </button>
    </mat-dialog-actions>
  </div>
</div>