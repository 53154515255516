import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UntypedFormBuilder, Validators } from '@angular/forms';

import { Subject } from 'rxjs/internal/Subject';
import { Observable } from 'rxjs/internal/Observable';

import { Role } from '../models/role';

@Injectable({ providedIn: 'root' })
export class RoleService {
  private API_URL = '/api/roles/';
  private headerOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

  private refreshRequestObs$ = new Subject<Role>();
  private currentRole: Role;

  constructor(
    private httpClient: HttpClient,
    private formBuilder: UntypedFormBuilder
  ) { }


  listenForRefreshRequest() {
    return this.refreshRequestObs$;
  }


  announceRefreshRequest(_ts: Role) {
    this.refreshRequestObs$.next(_ts);
  }


  setCurrent(_role: Role): void {
    this.currentRole = _role;
  }


  getCurrent(): Role {
    return this.currentRole;
  }


  get(_id: string): Observable<Role> {
    return this.httpClient.get<Role>(this.API_URL + _id);
  }


  getAll(): Observable<Role[]> {
    return this.httpClient.get<Role[]>(this.API_URL);
  }


  create(_role): Observable<Role> {
    return this.httpClient.post<Role>(this.API_URL, JSON.stringify(_role), this.headerOptions);
  }


  update(_role: Role): Observable<Role> {
    return this.httpClient.put<Role>(`${this.API_URL}/${_role._id}`, JSON.stringify(_role), this.headerOptions);
  }


  delete(id: string): Observable<Role> {
    return this.httpClient.delete<Role>(this.API_URL + id);
  }


  deleteByIdArray(arrayOfIds): Observable<any[]> {
    const deleteRequest = {ids: arrayOfIds};
    return this.httpClient.put<any>(this.API_URL + 'delete-forever/by-array', JSON.stringify(deleteRequest), this.headerOptions);
  }


  search(searchTerms): Observable<Role[]> {
    return this.httpClient.post<Role[]>(this.API_URL + 'search', JSON.stringify(searchTerms), this.headerOptions);
  }


  roleFormGroup(formData: Role) {   
    return this.formBuilder.group({
      name: [formData.name, Validators.required],
      description: [formData.description]
    });
  }
}
