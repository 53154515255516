import { Component, OnInit, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'credits-notification-dialog',
  templateUrl: './credits-notification-dialog.component.html',
  styleUrls: ['./credits-notification-dialog.component.scss']
})
export class CreditsNotificationDialogComponent implements OnInit {
  @Input() creditAmount: number;
  @Input() messagePassedIn?: string = null;

  constructor(public dialogRef: MatDialogRef<CreditsNotificationDialogComponent>) { }

  ngOnInit() {
    setTimeout(() => {
      this.closeDialog();
    }, 4000);
  }

  closeDialog() {
    return this.dialogRef.close();
  }
}