import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AngularMaterialModule } from '../angular-material/angular-material.module';

import { MintNotificationComponent } from './mint-notification/mint-notification.component';
import { NotificationGridComponent } from './notification-grid/notification-grid.component';
import { NotificationProfileComponent } from './notification-profile/notification-profile.component';
import { NotificationFormComponent } from './notification-form/notification-form.component';
import { AchievementNotificationComponent } from './achievement-notification/achievement-notification.component';
import { ConfettiAnimationComponent } from './achievement-notification/confetti-animation/confetti-animation.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        AngularMaterialModule,
        RouterModule
    ],
    declarations: [MintNotificationComponent, NotificationGridComponent, NotificationProfileComponent, NotificationFormComponent, AchievementNotificationComponent, ConfettiAnimationComponent],
    exports: [MintNotificationComponent, NotificationGridComponent, NotificationProfileComponent, NotificationFormComponent, AchievementNotificationComponent]
})
export class NotificationsModule { }
