import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UntypedFormBuilder, Validators } from '@angular/forms';

import { Observable } from 'rxjs/internal/Observable';

import { Layout } from '../models/layout';

@Injectable({ providedIn: 'root' })
export class LayoutService {
  private API_URL = '/api/layouts/';
  private headerOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

  public layouts = ['Edit', 'Detail', 'Grid'];

  public layoutTabs = [
    {
      label: 'List',
      layoutType: 'grid',
      description: 'Main module list',
      templateType: 'manage-grid'
    },
    // {
    //   label: 'Create',
    //   layoutType: 'create',
    //   description: 'Creating new record from a main module list',
    //   templateType: 'dnd-layout-form-builder'
    // },
    {
      label: 'Detail',
      layoutType: 'detail',
      description: 'Viewing a module record',
      templateType: 'basic-profile-builder'
    },
    {
      label: 'Edit/Create',
      layoutType: 'edit',
      description: 'Editing or Creating a module record',
      templateType: 'dnd-layout-form-builder'
    },
    {
      label: 'Quick Create',
      layoutType: 'quick-create',
      description: 'Creating a module record through a related record',
      templateType: 'dnd-layout-form-builder'
    },
    {
      label: 'Preview Panel',
      layoutType: 'preview-panel',
      description: 'Slideout Panel for list views',
      templateType: 'basic-profile-builder'
    },
    {
      label: 'Popup Search',
      layoutType: 'popup-search',
      description: 'Search fields used for popup search when selecting related record',
      templateType: 'hidden-dnd-layout'
    },
    {
      label: 'Popup List',
      layoutType: 'popup-listview',
      description: 'Fields shown in list view for popup search when selecting related record',
      templateType: 'hidden-dnd-layout'
    },
    {
      label: 'Basic Search',
      layoutType: 'basic-search',
      description: 'Search fields shown in list view in right hand slideout menu',
      templateType: 'hidden-dnd-layout'
    },
    {
      label: 'Mass Update',
      layoutType: 'mass-update',
      description: 'Mass Update Fields',
      templateType: 'hidden-dnd-layout'
    }
  ];

  constructor(
    private httpClient: HttpClient,
    private formBuilder: UntypedFormBuilder
  ) { }

  get(_id: string): Observable<Layout> {
    return this.httpClient.get<Layout>(this.API_URL + _id);
  }  

  getForModule(_id: string): Observable<Layout[]> {
    // console.log("🚀 ~ file: layout.service.ts ~ line 77 ~ LayoutService ~ getForModule ~ _id", _id)
    return this.httpClient.get<Layout[]>(this.API_URL + 'module/' + _id);
  } 

  getAll(): Observable<Layout[]> {
    return this.httpClient.get<Layout[]>(this.API_URL);
  }  

  create(_layout): Observable<Layout> {
    return this.httpClient.post<Layout>(this.API_URL, JSON.stringify(_layout), this.headerOptions);
  } 

  update(_layout: Layout): Observable<Layout> {
    return this.httpClient.put<Layout>(this.API_URL + _layout._id, JSON.stringify(_layout), this.headerOptions);
  }  

  search(searchTerms): Observable<Layout[]> {
    return this.httpClient.post<Layout[]>(this.API_URL + 'search', JSON.stringify(searchTerms), this.headerOptions);
  }
  
  layoutFormGroup(formData: Layout) {   
    return this.formBuilder.group({
      ssModule: [formData.ssModule, Validators.required],
      app: [formData.app],
      view: [formData.view, Validators.required],
      panelType: [formData.panelType], 
      sections: [formData.sections],
      role: [formData.role]
    });
  }  

}
