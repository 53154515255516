import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';

import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTableModule } from '@angular/material/table';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatSortModule } from '@angular/material/sort';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatStepperModule } from '@angular/material/stepper';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule, MatTooltipDefaultOptions, MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTreeModule } from '@angular/material/tree';
import { MatChipsModule } from '@angular/material/chips';
import { MatBadgeModule } from '@angular/material/badge';

import {
  NgxMatDateFormats,
  NgxMatDatetimePickerModule, 
  NgxMatNativeDateModule, 
  NgxMatTimepickerModule, 
  NGX_MAT_DATE_FORMATS
} from '@angular-material-components/datetime-picker';

const CUSTOM_MOMENT_FORMATS: NgxMatDateFormats = {
  parse: {
    dateInput: "dddd, MMMM Do YYYY, h:mm a"
  },
  display: {
    dateInput: "dddd, MMMM Do YYYY, h:mm a",
    monthYearLabel: "dddd, MMMM Do YYYY, h:mm a",
    dateA11yLabel: "dddd, MMMM Do YYYY, h:mm a",
    monthYearA11yLabel: "dddd, MMMM Do YYYY, h:mm a"
  }
};

export const OtherOptions: MatTooltipDefaultOptions = {
  showDelay: 0,
  hideDelay: 0,
  touchGestures: 'auto',
  position: 'below',
  touchendHideDelay: 0,
  disableTooltipInteractivity: true,
}

@NgModule({
  declarations: [],
  providers: [
    { provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_MOMENT_FORMATS },
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: OtherOptions }
  ],
  imports: [
    CommonModule,
    ClipboardModule,
    MatCardModule,
    MatBottomSheetModule,
    MatInputModule,
    MatButtonToggleModule,
    MatAutocompleteModule,
    MatTableModule,
    MatListModule,
    MatSelectModule,
    MatRadioModule,
    MatSortModule,
    MatMenuModule,
    MatSidenavModule,
    MatIconModule,
    DragDropModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatDialogModule,
    MatButtonModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSnackBarModule,
    MatStepperModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatRippleModule,
    MatTooltipModule,
    MatChipsModule,
    MatBadgeModule,
    MatTabsModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    MatToolbarModule,
    MatTreeModule,
    CdkTableModule,
    CdkTreeModule
  ],
  exports: [
    MatInputModule,
    ClipboardModule,
    MatCardModule,
    MatButtonToggleModule,
    MatBottomSheetModule,
    MatAutocompleteModule,
    MatTableModule,
    MatListModule,
    MatSelectModule,
    MatRadioModule,
    MatSortModule,
    MatMenuModule,
    MatChipsModule,
    MatBadgeModule,
    MatSidenavModule,
    MatIconModule,
    DragDropModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatDialogModule,
    MatButtonModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSnackBarModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatStepperModule,
    MatRippleModule,
    MatTooltipModule,
    MatTabsModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    MatToolbarModule,
    MatTreeModule,
    CdkTableModule,
    CdkTreeModule
  ],
})
export class AngularMaterialModule { }
