import { Injectable } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/internal/Observable';
import { WorkflowCondition } from '@app/models';

@Injectable({ providedIn: 'root' })
export class WorkflowConditionService {
    private API_URL = '/api/workflow-conditions/';
    private headerOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    private currentBeingViewed;

    public conditionOptions: any[] = [
        {label: 'When a field in the target module changes to or from a specified value', value: 1},
        {label: 'When the target module changes', value: 2},
        {label: 'When a field on the target module changes', value: 3},
        // {label: 'When a field in the target module contains a specified value', value: 4},
        {label: 'When the target module changes and a field in a related module contains a specified value', value: 5}
    ];


    constructor(
        private formBuilder: UntypedFormBuilder,
        private httpClient: HttpClient
    ) { }


    setCurrentBeingViewed(_workflow: WorkflowCondition): void {
        this.currentBeingViewed = _workflow;
    }
    
    
    getCurrentBeingViewed(): WorkflowCondition {
        return this.currentBeingViewed;
    }


    get(_id: string): Observable<WorkflowCondition> {
        return this.httpClient.get<WorkflowCondition>(this.API_URL + _id);
    }


    getAll(): Observable<WorkflowCondition[]> {
        return this.httpClient.get<WorkflowCondition[]>(this.API_URL);
    }


    create(_workflow): Observable<WorkflowCondition> {
        return this.httpClient.post<WorkflowCondition>(this.API_URL, JSON.stringify(_workflow), this.headerOptions);
    }


    update(_workflow: WorkflowCondition): Observable<WorkflowCondition> {
        return this.httpClient.put<WorkflowCondition>(`${this.API_URL}/${_workflow._id}`, JSON.stringify(_workflow), this.headerOptions);
    }


    delete(id: string): Observable<WorkflowCondition> {
        return this.httpClient.delete<WorkflowCondition>(this.API_URL + id);
    }


    deleteByWorkflow(workflowId: string): Observable<WorkflowCondition> {
        return this.httpClient.delete<WorkflowCondition>(this.API_URL + 'by-workflow/' + workflowId);
    }


    search(searchTerms): Observable<WorkflowCondition[]> {
        return this.httpClient.post<WorkflowCondition[]>(this.API_URL + 'search', JSON.stringify(searchTerms), this.headerOptions);
    }


    selectAllSearch(searchTerms: WorkflowCondition): Observable<WorkflowCondition[]> {
        searchTerms['selectAll'] = true;
        return this.httpClient.post<WorkflowCondition[]>(this.API_URL + 'search', JSON.stringify(searchTerms), this.headerOptions);
    }

    workflowConditionFormGroup(formData: WorkflowCondition) {
        return this.formBuilder.group({
          workflow: [formData.workflow, Validators.required],
          relatedModule: [formData.relatedModule]
        });
    }
}