import { Component, OnInit, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { GameInstance } from '@app/models';

@Component({
  selector: 'game-rewards-viewer-dialog',
  templateUrl: './game-rewards-viewer-dialog.component.html',
  styleUrls: ['./game-rewards-viewer-dialog.component.scss']
})
export class GameRewardsViewerDialogComponent implements OnInit {
  @Input() gameInstancePassedIn: GameInstance;

  constructor(public dialogRef: MatDialogRef<GameRewardsViewerDialogComponent>) { }

  ngOnInit() {
    // console.log('Instance passed in: ', this.gameInstancePassedIn);
  }

  closeDialog() {
    return this.dialogRef.close();
  }
}